import { Stack } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'
import { CustomerSelector } from '@/features/customer/components/CustomerSelector'
import { ExportCsvButton } from '@/features/customer/components/revenue/ExportCsvButton'
import type { RevenueDataRow } from '@/features/customer/types/revenue'

type CustomerRevenueAppBarProps = {
  availableYears: string[]
  yearSelection: string
  setYearSelection: Dispatch<SetStateAction<string>>
  selectedMarketProgramName: string
  revenueData: RevenueDataRow[]
  customerName: string
}

function CustomerRevenueAppBar({
  availableYears,
  yearSelection,
  setYearSelection,
  selectedMarketProgramName,
  revenueData,
  customerName,
}: Readonly<CustomerRevenueAppBarProps>) {
  const { t } = useTranslation()
  const { customerUuid } = useParams()
  const { permissions } = usePermissions()

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={1} sx={{ width: '100%' }}>
      <Stack direction="row" spacing={1} sx={{ minWidth: '15%' }}>
        {permissions.has('customerDetails') && <CustomerSelector customerUuid={customerUuid} />}
        {availableYears.length > 0 && (
          <CustomSelectField
            fullWidth={true}
            label={t('common.time.year')}
            options={availableYears.map((year, index) => ({
              id: index.toString(),
              label: year.toString(),
              value: year.toString(),
            }))}
            size="medium"
            value={yearSelection}
            variant="outlinedWhite"
            onChange={(event) => {
              setYearSelection(event.target.value)
            }}
          />
        )}
      </Stack>
      <ExportCsvButton
        customerName={customerName}
        data={revenueData}
        marketProgramName={selectedMarketProgramName}
        year={yearSelection}
      />
    </Stack>
  )
}

export default CustomerRevenueAppBar

import { useTranslation } from 'react-i18next'

import Page from '@/components/layouts/Page'
import { CustomerActivationsView } from '@/features/activation/components/CustomerActivationsView'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useCustomerQuery } from '@/features/customer/hooks/useCustomerQuery'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'

export const CustomerActivations = () => {
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()

  const customerUuid = loggedInUser?.allowedRoIds?.[0] ?? ''

  const { customer, isFetching: isFetchingCustomer } = useCustomerQuery(
    { uuid: customerUuid },
    { enabled: Boolean(customerUuid) },
  )

  const { customerSettings, isFetching: isFetchingCustomerSettings } = useCustomerSettingsQuery(
    { uuid: customerUuid, location: customer?.location },
    { enabled: !isFetchingCustomer && Boolean(customerUuid) },
  )

  if (!customer || !customerSettings || isFetchingCustomer || isFetchingCustomerSettings) return null

  return (
    <Page
      pageHeaderProps={{
        pageTitle: t('component.page_header.activations'),
        pageTag: 'customer-activations',
      }}
    >
      <CustomerActivationsView
        activationDetailsPath={'activations/:activationId'}
        customer={customer}
        timeZone={customerSettings.localization.timeZone}
      />
    </Page>
  )
}

import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { approveSchedule } from '@/features/resource/endpoints/scheduleBuilder'
import type { ApiApproveSchedule, Schedule } from '@/features/resource/types/spotOnForEboilers'
import type { UseMutationOptions } from '@/types/queries'

export type UseApproveSpotOnScheduleMutationResult = Omit<
  UseMutationResult<Schedule, Error, ApiApproveSchedule>,
  'mutateAsync'
> & {
  approveSchedule: UseMutationResult<Schedule, Error, ApiApproveSchedule>['mutateAsync']
}

export function useApproveSpotOnScheduleMutation(
  options?: UseMutationOptions<Schedule, ApiApproveSchedule>,
): UseApproveSpotOnScheduleMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: ApiApproveSchedule) => approveSchedule(variables),
  })

  return { approveSchedule: mutateAsync, ...mutationResult }
}

import { useRef } from 'react'
import ReactGA from 'react-ga4'

import { useAuth } from '@/features/authentication/contexts/AuthContext'
import type { AnalyticsEvent } from '@/features/googleAnalytics/event'

export type useAnalyticsResult = {
  sendAnalyticsEvent: (event: AnalyticsEvent) => void
  sendAnalyticsPageTagView: (pageTag?: string) => void
  sendAnalyticsPageView: (pathname: string) => void
}

export function useAnalytics(): useAnalyticsResult {
  const { loggedInUser } = useAuth()
  const prevPageTagRef = useRef<string | undefined>()

  if (!ReactGA.isInitialized || !loggedInUser) {
    return {
      sendAnalyticsEvent: () => {},
      sendAnalyticsPageTagView: () => {},
      sendAnalyticsPageView: () => {},
    }
  }

  return {
    sendAnalyticsEvent: (event: AnalyticsEvent) => {
      ReactGA.event(event.name, {
        ...event.dimensions,
      })
    },
    sendAnalyticsPageTagView: (pageTag?: string) => {
      if (!pageTag || pageTag === prevPageTagRef.current) return
      prevPageTagRef.current = pageTag
      ReactGA.send({
        hitType: 'pageview',
        page_tag: pageTag.replace(/\s+/g, '-').toLowerCase(),
        page_title: null,
      })
    },
    sendAnalyticsPageView: (pathname: string) => {
      ReactGA.send({
        hitType: 'pageview',
        page_path: pathname,
      })
    },
  }
}

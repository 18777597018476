import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { updateNetworkCharges } from '@/features/resource/endpoints/scheduleBuilder'
import type { ApiUpdateNetworkCharges, ExtraCriteria } from '@/features/resource/types/spotOnForEboilers'
import type { UseMutationOptions } from '@/types/queries'

export type UseUpdateSpotOnNetworkChargesMutationResult = Omit<
  UseMutationResult<ExtraCriteria, Error, ApiUpdateNetworkCharges>,
  'mutateAsync'
> & {
  updateNetworkCharges: UseMutationResult<ExtraCriteria, Error, ApiUpdateNetworkCharges>['mutateAsync']
}

export function useUpdateSpotOnNetworkChargesMutation(
  options?: UseMutationOptions<ExtraCriteria, ApiUpdateNetworkCharges>,
): UseUpdateSpotOnNetworkChargesMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: ApiUpdateNetworkCharges) => updateNetworkCharges(variables),
  })

  return { updateNetworkCharges: mutateAsync, ...mutationResult }
}

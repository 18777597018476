import axios from 'axios'

import environment from '@/environment'
import type { Site } from '@/features/customer/types/site'

export const ERROR_NAMES = {
  VALIDATION_FAILED: 'validation-failed',
}

export async function saveSite(site: Site) {
  const response = await axios.post<Site>(`${environment.services.sitesManagerApiUrl}`, site)
  return response.data
}

import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { GET_CUSTOMER_SETTINGS_API_ID, getCustomerSettings } from '@/features/customer/endpoints/customerSettings'
import type { CustomerSettingsPayload } from '@/features/customer/types/customer'
import type { UseQueryOptions } from '@/types/queries'

type CustomerSettingsQueryParams = {
  uuid: string
  location?: string
}
export type UseCustomerSettingsQueryResult = Omit<UseQueryResult, 'data'> & {
  customerSettings: CustomerSettingsPayload | null
}

export function useCustomerSettingsQuery(
  params: CustomerSettingsQueryParams,
  options?: UseQueryOptions<CustomerSettingsPayload>,
): UseCustomerSettingsQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_CUSTOMER_SETTINGS_API_ID, params],
    queryFn: () => getCustomerSettings(params.uuid, params.location),
  })

  return { customerSettings: data, ...queryResult }
}

export function useInvalidateCustomerSettingsQuery() {
  const queryClient = useQueryClient()

  return (params: CustomerSettingsQueryParams) =>
    queryClient.invalidateQueries({ queryKey: [GET_CUSTOMER_SETTINGS_API_ID, params] })
}

import { Box, CircularProgress, Stack } from '@mui/material'
import type { GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import DataGridEditableTextField from '@/components/dataDisplay/DataGridEditableTextField'
import CustomButton from '@/components/inputs/CustomButton'
import { useAlertContext } from '@/contexts/AlertContext'
import SpotOnEboilerDisplayedTimezone from '@/features/resource/components/SpotOnEboilerDisplayedTimezone'
import { DAY_NAMES, DEFAULT_EXTRA_CRITERIA_WEEKDAYS } from '@/features/resource/constants/spotOnForBoilers'
import { useUpdateSpotOnNetworkChargesMutation } from '@/features/resource/hooks/useUpdateSpotOnNetworkChargesMutation'
import type { ExtraCriteria, ExtraCriteriaWeekday } from '@/features/resource/types/spotOnForEboilers'
import { transformDataForDataGrid } from '@/features/resource/utils/spotOnForEboilers'
import { errorHandler } from '@/utils/errorHandler'

type SpotOnEboilerNetworkChargesFormEditProps = {
  networkChargesExtraCriteria: ExtraCriteria
  customerId: string
  scheduleProgramId: string
}

function SpotOnEboilerNetworkChargesFormEdit({
  networkChargesExtraCriteria,
  customerId,
  scheduleProgramId,
}: Readonly<SpotOnEboilerNetworkChargesFormEditProps>) {
  const { t } = useTranslation()
  const { updateNetworkCharges, isPending } = useUpdateSpotOnNetworkChargesMutation()

  const { pushAlert } = useAlertContext()

  const form = useForm({
    defaultValues: {
      extraCriteria: DEFAULT_EXTRA_CRITERIA_WEEKDAYS,
    },
  })

  useEffect(() => {
    if (networkChargesExtraCriteria?.weekdays) {
      form.setValue('extraCriteria', networkChargesExtraCriteria.weekdays)
    }
  }, [networkChargesExtraCriteria])

  const handleExtraCriteriaWeekdayChange =
    (params: GridRenderEditCellParams<ExtraCriteriaWeekday>) => (value: string) => {
      // An integer or a float number with up to two decimals
      const isValidPrice = /^\d+\.?\d{0,2}$/.test(value)
      if (value !== '' && !isValidPrice) return

      const { id, field } = params
      const currentData = form.getValues('extraCriteria')

      // Update the specific cell value
      const updatedData = currentData.map((row) => {
        const idxAsInt = parseInt(field)
        if (row.dayNumber === idxAsInt) {
          row.periods[id].value = value
        }

        return row
      })

      // Update form state
      form.setValue('extraCriteria', updatedData, { shouldDirty: true, shouldValidate: true })

      // Inform DataGrid of the change
      params.api.setEditCellValue({ id, field, value })
    }

  const extraCriteria = form.watch('extraCriteria')

  const rows = transformDataForDataGrid(extraCriteria)

  const columns: GridColDef[] = [
    { field: 'ptu', headerName: 'PTU', flex: 1 },
    ...extraCriteria.map((nc) => ({
      field: `${nc.dayNumber}`,
      headerName: DAY_NAMES[nc.dayNumber - 1], // The array of DAY_NAMES is 0-based, so -1 because dayOfWeek starts at 1 (Monday)
      flex: 1,
      editable: true,
      renderCell: (params) => <DataGridEditableTextField readonlyField value={params.formattedValue} />,
      renderEditCell: (params) => (
        <DataGridEditableTextField
          selectValueOnFocus
          value={params.value}
          onBlur={() => {
            params.api.stopCellEditMode({ id: params.id, field: params.field })
          }}
          onChange={handleExtraCriteriaWeekdayChange(params)}
        />
      ),
    })),
  ]

  async function handleSubmit(data) {
    try {
      await updateNetworkCharges({
        customerId,
        scheduleProgramId,
        extraCriteria: {
          ...networkChargesExtraCriteria!,
          weekdays: data.extraCriteria,
        },
      })

      pushAlert({
        message: t('spot_on_for_eboilers.network_charges_form.updated_success_message'),
        severity: 'success',
      })
    } catch (err: unknown) {
      const error = errorHandler(err, t('spot_on_for_eboilers.network_charges_form.updated_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  if (!networkChargesExtraCriteria) {
    return (
      <Box alignItems="center" display="flex" height="200px" justifyContent="center">
        <CircularProgress title="Loading" />
      </Box>
    )
  }

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack gap={1} marginBottom={2} marginTop={5}>
          <Stack flexDirection="row" justifyContent="space-between">
            <SpotOnEboilerDisplayedTimezone />
          </Stack>

          <CustomDataGrid
            disableColumnMenu
            disableColumnSorting
            hideFooter
            hideFooterPagination
            hideFooterRowCount
            aria-label={t('spot_on_for_eboilers.network_charges_form.table_title')}
            columns={columns}
            getRowHeight={() => 52}
            includeWrapper={false}
            rows={rows}
          />
        </Stack>
        <CustomButton disabled={isPending} type="submit" variant="contained">
          {t('common.button.save')}
        </CustomButton>
      </form>
    </FormProvider>
  )
}

export default SpotOnEboilerNetworkChargesFormEdit

import { MonetizationOnOutlined } from '@mui/icons-material'
import BlockIcon from '@mui/icons-material/Block'
import BoltOutlinedIcon from '@mui/icons-material/Bolt'
import CampaignOutlinedIcon from '@mui/icons-material/Campaign'
import ContactMailIcon from '@mui/icons-material/ContactMail'
import DateRangeIcon from '@mui/icons-material/DateRange'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import type { CustomerDetailsPageConfig } from '@/features/customer/pages/CustomerDetails'
import type { Customer, CustomerSettingsPayload } from '@/features/customer/types/customer'
import { hasRevenuePermissions } from '@/features/customer/utils/revenueUtils/revenuePermissions'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'

const RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_CUSTOMERS_TOGGLE_NAME = 'RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_CUSTOMERS'

const RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_COUNTRIES_TOGGLE_NAME = 'RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_COUNTRIES'

const commonProps = {
  component: Link,
  disableRipple: true,
  iconPosition: 'start',
  style: { minHeight: 0 },
} as const

type CustomerTabsProps = {
  customer: Customer
  customerSettings: CustomerSettingsPayload
  pageConfig: CustomerDetailsPageConfig
}

export const CustomerTabs = ({ customer, customerSettings, pageConfig }: CustomerTabsProps) => {
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()

  const { useFeatureWithContext } = useFeatureToggle()

  const { isFeatureEnabled: isNewRestrictionsEnabledForCountry } = useFeatureWithContext(
    RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_COUNTRIES_TOGGLE_NAME,
    { countryCode: customer.countryCode?.toLowerCase() || '' },
  )

  const { isFeatureEnabled: isNewRestrictionsEnabledForCustomer } = useFeatureWithContext(
    RESOURCE_FLEX_PORTAL_NEW_RESTRICTIONS_CUSTOMERS_TOGGLE_NAME,
    { userId: customer.uuid || '' },
  )

  const IS_CUSTOMER_ON_NEW_PLATFORM = isNewRestrictionsEnabledForCountry || isNewRestrictionsEnabledForCustomer

  return (
    <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
      <Tabs aria-label="customer-tabs" value={pageConfig.activeTab}>
        <Tab
          {...commonProps}
          icon={<ContactMailIcon />}
          label={t('customer_details.tabs.contact_info.title')}
          to={`/customers/${customer.uuid}/contact-info`}
          value={CustomerDetailsRouteInformation.CONTACT_INFO.navigationPath}
        />
        {IS_CUSTOMER_ON_NEW_PLATFORM ? (
          <Tab
            {...commonProps}
            icon={<BlockIcon />}
            label={t('customer_details.tabs.res_level_unavailability.title')}
            to={`/customers/${customer.uuid}/res-level-unavailability`}
            value={CustomerDetailsRouteInformation.RES_LEVEL_UNAVAILABILITY.navigationPath}
          />
        ) : (
          <Tab
            {...commonProps}
            icon={<DateRangeIcon />}
            label={t('customer_details.tabs.availability.title')}
            to={`/customers/${customer.uuid}/availability`}
            value={CustomerDetailsRouteInformation.AVAILABILITY.navigationPath}
          />
        )}
        <Tab
          {...commonProps}
          icon={<CampaignOutlinedIcon />}
          label={t('customer_details.tabs.activations')}
          to={`/customers/${customer.uuid}/activations`}
          value={CustomerDetailsRouteInformation.ACTIVATIONS.navigationPath}
        />
        {['FI', 'SE', 'NO'].includes(customer.countryCode ?? '') && (
          <Tab
            {...commonProps}
            icon={<BoltOutlinedIcon />}
            label={t('customer_details.tabs.power_measurements.title')}
            to={`/customers/${customer.uuid}/power-measurements`}
            value={CustomerDetailsRouteInformation.POWER_MEASUREMENTS.navigationPath}
          />
        )}
        {hasRevenuePermissions(loggedInUser, customer, customerSettings) && (
          <Tab
            {...commonProps}
            icon={<MonetizationOnOutlined />}
            label={t('customer_details.tabs.revenue.title')}
            to={`/customers/${customer.uuid}/revenue`}
            value={CustomerDetailsRouteInformation.REVENUE.navigationPath}
          />
        )}
        <Tab
          {...commonProps}
          icon={<SettingsIcon />}
          label={t('customer_details.tabs.settings.title')}
          to={`/customers/${customer.uuid}/settings`}
          value={CustomerDetailsRouteInformation.SETTINGS.navigationPath}
        />
      </Tabs>
    </Box>
  )
}

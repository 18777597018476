import ReactGA from 'react-ga4'

import environment from '@/environment'
import type { User } from '@/features/user/types/user'

export function initGoogleAnalytics(loggedInUser: User | null) {
  const trackId = environment.googleAnalyticsTrackId

  if (trackId && loggedInUser) {
    setUserProperties(loggedInUser)
    ReactGA.initialize(trackId)
  }
}

export function resetGoogleAnalytics() {
  ReactGA.reset()
}

function setUserProperties(loggedInUser: User | null) {
  ReactGA.set({
    user_properties: {
      user_id: loggedInUser?.id,
      role: loggedInUser?.role,
      environment: environment.environmentName,
    },
  })
}

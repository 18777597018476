import { FormControl, FormControlLabel, FormHelperText, Switch } from '@mui/material'
import type { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

type MFASwitchProps = {
  isChecked: boolean
  disabled: boolean
  displayHelperText: boolean
  name?: string
  error: boolean
  helperText?: string
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

/**
 * Multi-factor authentication switch

 */
const MFASwitch = ({ displayHelperText, isChecked, name, disabled, onChange, helperText, error }: MFASwitchProps) => {
  const { t } = useTranslation()

  return (
    <FormControl>
      <FormControlLabel
        control={<Switch checked={isChecked} disabled={disabled} name={name} onChange={onChange} />}
        label={t('user_form.form.mfa_label')}
      />

      {error && <FormHelperText error>{helperText}</FormHelperText>}

      {!error && displayHelperText && <FormHelperText>{t('user_form.form.mfa_phone_required_hint')}</FormHelperText>}
    </FormControl>
  )
}

export default MFASwitch

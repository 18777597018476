import type { Dispatch, ReactNode, SetStateAction } from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'

import Page from '@/components/layouts/Page'
import { useCustomerQuery } from '@/features/customer/hooks/useCustomerQuery'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'
import { CustomerTabs } from '@/features/customer/pages/components/CustomerTabs'
import type { Customer, CustomerSettingsPayload } from '@/features/customer/types/customer'
import type { Breadcrumb } from '@/types/breadcrumb'

export type CustomerDetailsPageConfig = {
  title: string
  breadcrumbs: Breadcrumb[]
  pageTag?: string
  appBarContent?: ReactNode
  activeTab: string
}

type ContextType = {
  customer: Customer
  customerSettings: CustomerSettingsPayload
  customerDetailsCommonBreadcrumbs: Breadcrumb[]
  isFetchingCustomer: boolean
  pageConfig: CustomerDetailsPageConfig | null
  setPageConfig: Dispatch<SetStateAction<CustomerDetailsPageConfig>>
}

function CustomerDetails() {
  const { t } = useTranslation()
  const { customerUuid } = useParams()
  const [pageConfig, setPageConfig] = useState<CustomerDetailsPageConfig>({
    title: '',
    breadcrumbs: [],
    appBarContent: null,
    activeTab: '',
  })
  const { customer, isFetching: isFetchingCustomer } = useCustomerQuery(
    { uuid: customerUuid! },
    { enabled: Boolean(customerUuid) },
  )

  const { customerSettings, isFetching: isFetchingCustomerSettings } = useCustomerSettingsQuery(
    { uuid: customerUuid!, location: customer?.location },
    { enabled: !isFetchingCustomer && Boolean(customer?.location) },
  )

  if (!customerUuid || !customer || isFetchingCustomer || !customerSettings || isFetchingCustomerSettings) return null

  const customerDetailsCommonBreadcrumbs = [
    {
      text: t('component.page_header.customers'),
      to: '/customers',
    },
  ]

  return (
    <Page
      pageHeaderProps={{
        pageTitle: pageConfig.title,
        breadcrumbItems: pageConfig.breadcrumbs,
        appBarContent: pageConfig.appBarContent,
        pageTag: pageConfig.pageTag,
      }}
    >
      {pageConfig.activeTab !== '' && (
        <CustomerTabs customer={customer} customerSettings={customerSettings} pageConfig={pageConfig} />
      )}

      <Outlet
        context={
          {
            customer,
            customerSettings,
            customerDetailsCommonBreadcrumbs,
            isFetchingCustomer: isFetchingCustomer || isFetchingCustomerSettings,
            pageConfig,
            setPageConfig,
          } satisfies ContextType
        }
      />
    </Page>
  )
}

export function useCustomerDetails() {
  return useOutletContext<ContextType>()
}

export default CustomerDetails

import type { UseQueryResult } from '@tanstack/react-query'
import { useQueries } from '@tanstack/react-query'

import type { ResourcesResponse } from '@/features/resource/endpoints/resources'
import { GET_RESOURCES_API_ID, getResources } from '@/features/resource/endpoints/resources'
import type { Resource, ResourceMetadata } from '@/features/resource/types'
import type { UseQueryOptions } from '@/types/queries'

type UseSpotOnResourcesQueryParams = {
  customerIds: string[]
}

export type UseSpotOnResourcesQueryResult = Omit<UseQueryResult, 'data'> & {
  resources: Resource[]
  meta?: ResourceMetadata
}

export function useSpotOnResourcesQuery(
  params: UseSpotOnResourcesQueryParams,
  options?: UseQueryOptions<ResourcesResponse>,
): UseSpotOnResourcesQueryResult {
  const queryResult = useQueries({
    queries: params.customerIds.map((customerId) => ({
      ...options,
      queryKey: [GET_RESOURCES_API_ID, customerId],
      queryFn: () => getResources({ filters: { customerId, resourceType: 'ELECTRIC_BOILER', lifecycleStage: 'LIVE' } }),
    })),
  })

  return {
    ...queryResult[0],
    resources:
      (queryResult.flatMap((result) => result.data?.data).filter((resource) => Boolean(resource)) as Resource[]) ?? [],
  }
}

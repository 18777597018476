import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Autocomplete, Grid2 as Grid, TextField } from '@mui/material'
import { useJsApiLoader } from '@react-google-maps/api'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import environment from '@/environment'
import CustomerMap from '@/features/customer/components/companyInfo/CustomerMap'
import { fromGoogleMapsCoordinates } from '@/features/customer/components/companyInfo/GpsLocationConverter'
import type { GpsLocation } from '@/types/gpsLocation'

type CustomerAddressInputProps = {
  address: string | undefined
  gpsLocation: GpsLocation | undefined
  onChangeAddress: any
  hiddenMap?: boolean
}

const geocoder = { current: null }

export function CustomerAddressInput({
  address,
  gpsLocation,
  onChangeAddress,
  hiddenMap,
}: Readonly<CustomerAddressInputProps>) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: environment.googleMapsApiKey ?? '',
  })

  const { t } = useTranslation()
  const [selectedGeoCoderResult, setSelectedGeoCoderResult] = useState<google.maps.GeocoderResult | null>(null)
  const [inputAddressValue, setInputAddressValue] = useState(address)
  const [inputGpsLocation, setInputGpsLocation] = useState<GpsLocation | undefined>(gpsLocation)
  const [geocodeResults, setGeocodeResults] = useState<google.maps.GeocoderResult[]>([])

  function geocode(address) {
    const params = { address }
    if (geocoder.current) {
      ;(geocoder.current as any)
        .geocode(params)
        .then(({ results }) => {
          setGeocodeResults(results ?? [])
        })
        .catch(() => {
          setInputGpsLocation(undefined)
          setGeocodeResults([])
        })
    }
  }

  function confirmGeolocation(geolocation) {
    if (geolocation) {
      gpsLocation = fromGoogleMapsCoordinates(geolocation.geometry.location)
      onChangeAddress(geolocation.formatted_address, gpsLocation)
      setGeocodeResults([])
      setInputGpsLocation(gpsLocation)
    } else {
      setInputGpsLocation(undefined)
      onChangeAddress(inputAddressValue, undefined)
    }
  }

  useEffect(() => {
    if (isLoaded && !geocoder.current && (window as any).google.maps) {
      geocoder.current = new (window as any).google.maps.Geocoder()
    }
    if (!geocoder.current) {
      return undefined
    }

    if (inputAddressValue === '') {
      setInputGpsLocation(undefined)
      setGeocodeResults([])
      onChangeAddress(inputAddressValue, undefined)
      return undefined
    }
    geocode(inputAddressValue)
    onChangeAddress(inputAddressValue, inputGpsLocation)
  }, [isLoaded, selectedGeoCoderResult, inputAddressValue])

  return (
    <>
      <Autocomplete
        freeSolo
        filterOptions={(x) => x}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.formatted_address)}
        id="addressWithGpsLocation"
        options={geocodeResults}
        renderInput={(params) => (
          <TextField {...params} label={t('customer_details.tabs.contact_info.company_info.address')} />
        )}
        renderOption={(props, option) => {
          const geocoderResultOption = option as google.maps.GeocoderResult
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                  {geocoderResultOption.formatted_address}
                </Grid>
              </Grid>
            </li>
          )
        }}
        value={address}
        onChange={(event: any, newValue: string | google.maps.GeocoderResult | null) => {
          const newGeocoderResultValue = newValue as google.maps.GeocoderResult
          setGeocodeResults(newValue ? [newGeocoderResultValue, ...geocodeResults] : geocodeResults)
          setSelectedGeoCoderResult(newGeocoderResultValue)
          confirmGeolocation(newGeocoderResultValue)
        }}
        onInputChange={(event, newInputAddressValue) => {
          setInputAddressValue(newInputAddressValue)
          setInputGpsLocation(undefined)
          onChangeAddress(newInputAddressValue, undefined)
        }}
      />
      {!hiddenMap && <CustomerMap location={inputGpsLocation} />}
    </>
  )
}

import type { BidPtu } from '@/features/bidding/types/bid'
import { getBidTotalAccepted, getBidTotalOffered } from '@/features/bidding/utils/calculations/getBidTotal'

export function getBidAverageOffered(bidPtus: BidPtu[]): number {
  return getBidTotalOffered(bidPtus) / bidPtus.length
}

export function getBidAverageAccepted(bidPtus: BidPtu[]): number {
  return getBidTotalAccepted(bidPtus) / bidPtus.length
}

import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Box, capitalize, Card, CardHeader, Divider, Stack, Table, TableBody, TableCell, TableRow } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import CustomCardContent from '@/components/layouts/CustomCardContent'
import EditResourceModal from '@/features/resource/components/EditResourceModal'
import ResourceEstimatedPower from '@/features/resource/components/ResourceEstimatedPower'
import ResourceSteeringTarget from '@/features/resource/components/ResourceSteeringTarget'
import { RESOURCE_TYPES } from '@/features/resource/constants'
import { useResourceData } from '@/features/resource/contexts/ResourceDataContext'
import type { Resource } from '@/features/resource/types'
import { convertEnergyPower } from '@/utils/powerEnergyTransformations'

const LABEL_COLOR = '#9e9e9e'

type ResourceCardProps = {
  content?: React.ReactNode
  showGeneralInfo?: boolean
}

type EditDialogState = {
  resource: Resource | null
  open: boolean
}

type ResourceTitlePanelProps = {
  resource: Resource
  handleEditResourceSettled: () => void
  handleEditResourceSubmit: () => void
}

export const ResourceTitlePanel = ({
  resource,
  handleEditResourceSettled,
  handleEditResourceSubmit,
}: ResourceTitlePanelProps) => {
  const { t } = useTranslation()
  const [editDialog, setEditDialog] = useState<EditDialogState>({ open: false, resource: null })

  function handleCloseDialog() {
    setEditDialog({ resource: null, open: false })
  }

  function handleEditResourceSuccess() {
    handleCloseDialog()
  }

  function handleOpenDialog(resource: Resource) {
    setEditDialog({ resource, open: true })
  }

  return (
    <CardHeader
      action={
        <>
          <CustomIconButton
            Icon={EditOutlinedIcon}
            aria-label={t('resources.table.edit_button_label')}
            color={'primary'}
            onClick={() => handleOpenDialog(resource)}
          />
          <EditResourceModal
            open={editDialog.open && editDialog.resource?.resourceID !== undefined}
            // We can assume that resource will always have an id, otherwise we should not show
            // the modal
            resourceID={editDialog.resource?.resourceID ?? ''}
            resourceName={editDialog.resource?.resourceName}
            resourceType={editDialog.resource?.resourceType}
            resourceTypeOptions={RESOURCE_TYPES}
            onClose={handleCloseDialog}
            onSettled={handleEditResourceSettled}
            onSubmit={handleEditResourceSubmit}
            onSuccess={handleEditResourceSuccess}
          />
        </>
      }
      subheader={resource.resourceName ?? t('common.unknown')}
      sx={{ paddingX: 0 }}
      title={resource.customerName ?? t('common.unknown')}
    ></CardHeader>
  )
}

const StyledResourceDetailRow = styled(TableRow)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const StyledResourceDetailHeaderColumn = styled(TableCell)(() => ({
  '.MuiTableCell-root': {
    border: 'none',
  },
}))

const ResourceDetailTable = ({ resource }: { resource: Resource }) => {
  const { t } = useTranslation()
  const rows = useMemo(() => {
    return [
      {
        label: t('resources.detail_panel.uuid'),
        value: resource.resourceID,
        column: 1,
        position: 1,
      },
      {
        label: t('resources.detail_panel.markets'),
        value: resource.marketPrograms.map((marketProgram) => t(`common.market_program.${marketProgram}`)).join(', '),
        column: 1,
        position: 2,
      },
      {
        label: t('resources.detail_panel.verified_capacity'),
        value: resource.verifiedCapacity
          ? convertEnergyPower(resource.verifiedCapacity, 'kilowatts')
          : t('common.not_set'),
        column: 1,
        position: 3,
      },
      {
        label: t('resources.detail_panel.estimated_power'),
        value: <ResourceEstimatedPower resource={resource} typographyVariant={'inherit'} />,
        column: 1,
        position: 4,
      },
      {
        label: t('resources.detail_panel.lifecycle_stage'),
        value:
          resource.lifecycleStage !== null ? capitalize(resource.lifecycleStage.toLowerCase()) : t('common.not_set'),
        column: 1,
        position: 5,
      },
      {
        label: t('resources.detail_panel.resource_type'),
        value: resource.resourceType !== null ? capitalize(resource.resourceType.toLowerCase()) : t('common.not_set'),
        column: 1,
        position: 6,
      },
      {
        label: t('resources.detail_panel.steerability_status'),
        value:
          (resource.steerabilityStatus ?? resource.status)
            ? capitalize((resource.steerabilityStatus ?? resource.status)!.toLowerCase())
            : t('common.not_set'),
        column: 2,
        position: 1,
      },
      {
        label: t('resources.detail_panel.steering_status'),
        value: resource.steeringStatus ? capitalize(resource.steeringStatus.toLowerCase()) : t('common.not_set'),
        column: 2,
        position: 2,
      },
      {
        label: t('resources.detail_panel.steering_target'),
        value: <ResourceSteeringTarget currentSteeringTarget={resource.steeringData?.currentSteeringTarget} />,
        column: 2,
        position: 3,
      },
      {
        label: t('resources.detail_panel.resource_can_consume'),
        value:
          resource.canConsume !== undefined && resource.canConsume !== null
            ? capitalize(resource.canConsume.toString())
            : t('common.not_set'),
        column: 2,
        position: 4,
      },
      {
        label: t('resources.detail_panel.resource_can_produce'),
        value:
          resource.canProduce !== undefined && resource.canProduce !== null
            ? capitalize(resource.canProduce.toString())
            : t('common.not_set'),
        column: 2,
        position: 5,
      },
      {
        label: t('resources.detail_panel.resource_last_update'),
        value: resource.lastUpdate ? DateTime.fromISO(resource.lastUpdate).toFormat('dd MMMM, HH:mm') : '-',
        column: 2,
        position: 6,
      },
    ]
  }, [resource, t])

  // Determine the number of columns by finding the maximum column number in the rows
  const columns = Math.max(...rows.map((row) => row.column))

  // Group rows by their column number and sort them by their position within the column
  const columnsData = Array.from({ length: columns }, (_, i) =>
    rows.filter((row) => row.column === i + 1).sort((a, b) => a.position - b.position),
  )

  return (
    <>
      <CardHeader sx={{ paddingX: 0 }} title={t('resources.detail_panel.general_information')}></CardHeader>
      <Box display="flex">
        {columnsData.map((columnRows) => (
          <Table key={columnRows[0].column} aria-label={t('resources.detail_panel.general_information')} size={'small'}>
            <TableBody>
              {columnRows.map((row) => (
                <StyledResourceDetailRow key={row.label}>
                  <StyledResourceDetailHeaderColumn>
                    <CustomTypography color={LABEL_COLOR} variant="caption">
                      {row.label}
                    </CustomTypography>
                  </StyledResourceDetailHeaderColumn>
                  <TableCell>
                    <CustomTypography sx={{ lineHeight: 'inherit' }} variant="inherit">
                      {row.value}
                    </CustomTypography>
                  </TableCell>
                </StyledResourceDetailRow>
              ))}
            </TableBody>
          </Table>
        ))}
      </Box>
    </>
  )
}

const ResourceCard = ({ content, showGeneralInfo = true }: ResourceCardProps) => {
  const { resource, stopPolling, restartPolling } = useResourceData()

  if (resource === undefined || resource === null) {
    return null
  }

  return (
    <Card sx={{ marginY: 3 }}>
      <CustomCardContent title={resource.resourceID}>
        <Stack sx={{ flexDirection: 'column', gap: 2 }}>
          <ResourceTitlePanel
            /**
             * We restart polling when the update resource mutation is settled, which means that we restart the polling mechanism
             * independently of the mutation result (success or error)
             */
            handleEditResourceSettled={restartPolling}
            /**
             * We stop polling while the update resource mutation is running, so we wait until it finishes for activating the
             * polling again
             */
            handleEditResourceSubmit={stopPolling}
            resource={resource}
          />
          <Divider />
          {showGeneralInfo && (
            <>
              <ResourceDetailTable resource={resource} />
              {content && <Divider />}
            </>
          )}
          {content}
        </Stack>
      </CustomCardContent>
    </Card>
  )
}

export default ResourceCard

import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { updatePowerLimits } from '@/features/resource/endpoints/scheduleBuilder'
import type { ApiUpdatePowerLimits, ExtraCriteria } from '@/features/resource/types/spotOnForEboilers'
import type { UseMutationOptions } from '@/types/queries'

export type UseUpdateSpotOnPowerLimitsMutationResult = Omit<
  UseMutationResult<ExtraCriteria, Error, ApiUpdatePowerLimits>,
  'mutateAsync'
> & {
  updatePowerLimits: UseMutationResult<ExtraCriteria, Error, ApiUpdatePowerLimits>['mutateAsync']
}

export function useUpdateSpotOnPowerLimitsMutation(
  options?: UseMutationOptions<ExtraCriteria, ApiUpdatePowerLimits>,
): UseUpdateSpotOnPowerLimitsMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: ApiUpdatePowerLimits) => updatePowerLimits(variables),
  })

  return { updatePowerLimits: mutateAsync, ...mutationResult }
}

import { useTranslation } from 'react-i18next'

import CustomDialog from '@/components/feedback/CustomDialog'
import { useAlertContext } from '@/contexts/AlertContext'
import AssignResourceDialogForm from '@/features/activationGroup/components/AssignResourceDialogForm'
import { useInvalidateActivationGroupResourcesQuery } from '@/features/activationGroup/hooks/useActivationGroupResourcesQuery'
import { useUpdateActivationGroupMutation } from '@/features/activationGroup/hooks/useUpdateActivationGroupMutation'
import type {
  ActivationGroup,
  ApiActivationGroupResource,
  ApiUpdateActivationGroup,
  ApiVolumeRangeUnit,
  AssignResourceToActivationGroup,
} from '@/features/activationGroup/types'
import { errorHandler } from '@/utils/errorHandler'

type AssignResourceDialogProps = {
  activationGroup: ActivationGroup
  resources: ApiActivationGroupResource[]
  open: boolean
  onClose: () => void
}

function serializeAssignResourceToActivationGroup(
  assignedResource: AssignResourceToActivationGroup,
  activationGroup: ActivationGroup,
  activationGroupResources: ApiActivationGroupResource[],
): ApiUpdateActivationGroup {
  return {
    code: activationGroup.code,
    biddableVolumeRange: activationGroup.biddableVolumeRange
      ? {
          unit: activationGroup.biddableVolumeRange.unit.toUpperCase() as ApiVolumeRangeUnit,
          min: activationGroup.biddableVolumeRange.min,
          max: activationGroup.biddableVolumeRange.max,
        }
      : null,
    state: activationGroup.state ?? 'LIVE',
    resources: [
      ...activationGroupResources.map(({ uuid, activationPriority, isUsedForMinCapacityPrequalification }) => ({
        uuid,
        activationPriority,
        isUsedForMinCapacityPrequalification: isUsedForMinCapacityPrequalification,
      })),
      {
        uuid: assignedResource.resourceUuid,
        activationPriority: assignedResource.activationPriority,
        isUsedForMinCapacityPrequalification: assignedResource.isUsedForMinCapacityPrequalification,
      },
    ],
  }
}

const AssignResourceDialog = ({ open, activationGroup, resources, onClose }: AssignResourceDialogProps) => {
  const { t } = useTranslation()
  const { updateActivationGroup, isPending } = useUpdateActivationGroupMutation({
    activationGroupUuid: activationGroup.uuid,
  })
  const invalidateActivationGroupResourcesQuery = useInvalidateActivationGroupResourcesQuery({
    activationGroupUuid: activationGroup.uuid,
  })
  const { pushAlert } = useAlertContext()

  function handleClose() {
    onClose()
  }

  async function handleSubmit(data: AssignResourceToActivationGroup) {
    try {
      await updateActivationGroup(serializeAssignResourceToActivationGroup(data, activationGroup, resources))
      await invalidateActivationGroupResourcesQuery()

      pushAlert({
        message: t('activation_groups.assign_resource_success_message'),
        severity: 'success',
      })
      onClose()
    } catch (err) {
      const error = errorHandler(err, t('activation_groups.assign_resource_generic_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <CustomDialog open={open} title={t('activation_groups.assign_resource_dialog.title')} onClose={handleClose}>
      <AssignResourceDialogForm isLoading={isPending} onCancel={handleClose} onSubmit={handleSubmit} />
    </CustomDialog>
  )
}

export default AssignResourceDialog

import { t } from 'i18next'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { CustomerRevenueView } from '@/features/customer/pages/components/CustomerRevenueView'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { useCustomerDetails } from '@/features/customer/pages/CustomerDetails'

export const CustomerDetailsRevenue = () => {
  const navigate = useNavigate()
  const { customer, isFetchingCustomer, setPageConfig, customerSettings, customerDetailsCommonBreadcrumbs } =
    useCustomerDetails()

  useEffect(() => {
    if (customer.location !== 'fi') {
      navigate(`/customers/${customer.uuid}/contact-info`)
    }
  }, [customer.location])

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.revenue.title'),
      breadcrumbs: customerDetailsCommonBreadcrumbs,
      pageTag: 'customer-details-revenue',
      activeTab: CustomerDetailsRouteInformation.REVENUE.navigationPath,
    })
  }, [])

  if (!customer || isFetchingCustomer) {
    return null
  }

  return <CustomerRevenueView customer={customer} customerSettings={customerSettings} />
}

import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ResourceGeneralCard from '@/features/resource/components/ResourceGeneralCard'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceDetails } from '@/features/resource/pages/ResourceDetailsPage'

function ResourceGeneralPage() {
  const { resourceUuid } = useParams()

  const { setActivePage } = useResourceDetails()

  useEffect(() => {
    setActivePage(ResourceRouteInformation.RESOURCE_GENERAL.routePath)
  }, [])

  if (!resourceUuid) {
    return null
  }

  return <ResourceGeneralCard />
}

export default ResourceGeneralPage

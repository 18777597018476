import ReplayIcon from '@mui/icons-material/Replay'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import { useMutation } from '@tanstack/react-query'
import type { TFunction } from 'i18next'
import type { FC } from 'react'
import { useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import CustomButton from '@/components/inputs/CustomButton'
import { useAlertContext } from '@/contexts/AlertContext'
import { Status } from '@/features/bidding/constants'
import { saveBids } from '@/features/bidding/endpoints/bids'
import type { Bid } from '@/features/bidding/types/bid'
import type { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { getStateOfMarket, MarketState } from '@/features/bidding/utils/getStateOfMarket'

export enum BidFormStatus {
  CONFIRMING,
  SENDING,
  SEND_FAILED,
}

type Props = {
  bids: Bid[]
}

const BidConfirmSendButton: FC<Props> = ({ bids }) => {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()
  const navigate = useNavigate()

  const [bidFormStatus, setBidFormStatus] = useState<BidFormStatus>(BidFormStatus.CONFIRMING)

  const { mutate: mutateSaveBids } = useMutation({
    mutationFn: async () => {
      if (bids.length === 0) return

      setBidFormStatus(BidFormStatus.SENDING)
      const offeredBids = bids.map((bid) => ({ ...bid, status: Status.OFFERED }))
      await saveBids(offeredBids)
    },
    onError: () => {
      setBidFormStatus(BidFormStatus.SEND_FAILED)
      pushAlert({
        message: t('bidding.create_bid.sending_failed.text'),
        severity: 'error',
        title: t('bidding.create_bid.sending_failed.title'),
      })
    },
    onSuccess: async () => {
      navigate(`/bidding/offer/success/${bids[0].marketProgram}`, { replace: true })
    },
  })

  if (bids.length === 0) return <FullPageSpinner />

  switch (bidFormStatus) {
    case BidFormStatus.SENDING:
      return (
        <CustomButton
          key="sendButton"
          disabled
          data-testid="send-bid-button"
          startIcon={<CircularProgress color="inherit" size={16} />}
          sx={{ color: 'grey' }}
          variant={'contained'}
        >
          {t('bidding.create_bid.sending')}
        </CustomButton>
      )
    case BidFormStatus.SEND_FAILED:
      return (
        <CustomButton
          key="sendButton"
          data-testid="send-bid-button"
          startIcon={<ReplayIcon />}
          variant={'contained'}
          onClick={() => handleSubmit(bids[0].deliveryDay, mutateSaveBids, t)}
        >
          {t('bidding.create_bid.sending_failed.retry')}
        </CustomButton>
      )
    default:
      return (
        <CustomButton
          key="sendButton"
          data-testid="send-bid-button"
          startIcon={<SendOutlinedIcon />}
          variant={'contained'}
          onClick={() => handleSubmit(bids[0].deliveryDay, mutateSaveBids, t)}
        >
          {t('bidding.create_bid.send')}
        </CustomButton>
      )
  }
}

const handleSubmit = (deliveryDay: MarketDate, mutateSaveBids: () => void, t: TFunction) => {
  if (
    getStateOfMarket(deliveryDay) === MarketState.CLOSED &&
    !confirm(t('bidding.market_closed.confirmation_message'))
  ) {
    return
  }

  mutateSaveBids()
}

export default BidConfirmSendButton

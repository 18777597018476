// src/features/bidding/utils/helpers.ts
import type { MarketProgram } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'

export const isValueStackingSupported = (marketProgram: MarketProgram): boolean => {
  const { selectedCountry } = useBiddingContext()
  const supportedPrograms = [
    'NORDICS_FCRD_DOWN_STATIC',
    'NORDICS_FCRD_UP_STATIC',
    'NORDICS_FCRD_DOWN_DYNAMIC',
    'NORDICS_FCRD_UP_DYNAMIC',
    'NORDICS_FCRN',
  ]
  return supportedPrograms.includes(marketProgram) && selectedCountry.toLowerCase() === 'fi'
}

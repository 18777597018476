import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import MainContentContainer from '@/components/layouts/MainContentContainer'
import { USER_ROLES } from '@/constants/userRoles'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { ResourceLevelRestrictionsDataGrid } from '@/features/customer/components/availability/restriction/resourceLevelUnavailaibility/ResourceLevelRestrictionsDataGrid'
import { CustomerSelector } from '@/features/customer/components/CustomerSelector'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { useCustomerDetails } from '@/features/customer/pages/CustomerDetails'

export const ResourceLevelUnavailability = () => {
  const { customer, customerDetailsCommonBreadcrumbs, setPageConfig } = useCustomerDetails()
  const { t } = useTranslation()
  const { loggedInUser } = useAuth()
  const { customerSettings, isFetching: isFetchingCustomerSettings } = useCustomerSettingsQuery({
    uuid: customer.uuid!,
  })

  const viewOnly =
    loggedInUser?.role !== USER_ROLES.ADMINISTRATORS.value && loggedInUser?.role !== USER_ROLES.CUSTOMER_MANAGERS.value

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.res_level_unavailability.title'),
      pageTag: 'customer-details-resource-level-unavailability',
      breadcrumbs: customerDetailsCommonBreadcrumbs,
      appBarContent: <CustomerSelector customerUuid={customer.uuid} />,
      activeTab: CustomerDetailsRouteInformation.RES_LEVEL_UNAVAILABILITY.navigationPath,
    })
  }, [])

  if (!customerSettings || isFetchingCustomerSettings) return null

  return (
    <MainContentContainer>
      <ResourceLevelRestrictionsDataGrid
        customerLocation={customer.location ?? ''}
        customerTimeZone={customerSettings.localization.timeZone ?? ''}
        customerUuid={customer.uuid!}
        viewOnly={viewOnly}
      />
    </MainContentContainer>
  )
}

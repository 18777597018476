import { gridFilterModelSelector, useGridApiContext } from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import type { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import GridToolbar from '@/components/dataDisplay/GridToolbar'
import { MarketDate } from '@/features/bidding/utils/date/marketDate'
import { getFilterItemValue, updateDataGridFilter } from '@/utils/datagrid/filters'

/**
 * Module augmentation  is necessary to use slotProps without TypeScript errors.
 */
declare module '@mui/x-data-grid' {
  interface ToolbarPropsOverrides extends PastBidDateSelectorToolbarProps {}
}

type PastBidDateSelectorToolbarProps = { maxDate: DateTime }

const PAST_DELIVERY_DAY_FILTER_NAME = 'pastDeliveryDay'

function PastBidDateSelectorToolbar({ maxDate }: Readonly<PastBidDateSelectorToolbarProps>) {
  const { t, i18n } = useTranslation()
  const apiRef = useGridApiContext()
  const filterModel = gridFilterModelSelector(apiRef)
  const deliveryDayValue = getFilterItemValue<MarketDate>(filterModel, PAST_DELIVERY_DAY_FILTER_NAME)

  const handleDateChange = (date: DateTime | null) =>
    updateDataGridFilter(apiRef, PAST_DELIVERY_DAY_FILTER_NAME, {
      field: PAST_DELIVERY_DAY_FILTER_NAME,
      operator: 'is',
      value: date !== null ? new MarketDate(date) : null,
    })

  return (
    <GridToolbar
      filters={
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
          <DatePicker
            defaultValue={deliveryDayValue?.getStartOfDay()}
            format="DD"
            label={t('bidding.create_bid.bid_date')}
            maxDate={maxDate}
            sx={{ width: 180 }}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      }
    />
  )
}

export default PastBidDateSelectorToolbar

import { Box } from '@mui/material'
import type { ReactNode } from 'react'
import { useEffect } from 'react'

import BackNavigationButton from '@/components/layouts/BackNavigationButton'
import PageAppBar from '@/components/layouts/PageAppBar'
import { usePageMetadata } from '@/components/layouts/PageMetadataContext'
import PageTitle from '@/components/layouts/PageTitle'
import CustomBreadcrumbs from '@/components/navigation/CustomBreadcrumbs'
import { useAnalytics } from '@/features/googleAnalytics/hooks/useAnalytics'
import type { Breadcrumb } from '@/types/breadcrumb'

type CommonProps = {
  appBarContent?: ReactNode
  pageTitle: string
  pageTag?: string // used for Google Analytics
  hiddenBackButton?: boolean
  hiddenAppBar?: boolean
  hiddenTitle?: boolean
}

// When page is root, breadcrumbItems must be undefined as we do not show breadcrumbs
type RootProps = {
  isRoot?: true
  breadcrumbItems?: never
}

// When page is not a root page, breadcrumbItems can be defined
type BranchProps = {
  isRoot?: false
  breadcrumbItems?: Breadcrumb[]
}

type PageHeaderProps = CommonProps & (RootProps | BranchProps)

function PageHeader({
  breadcrumbItems = [],
  pageTitle,
  pageTag,
  appBarContent,
  hiddenBackButton,
  hiddenAppBar,
  hiddenTitle,
  isRoot,
}: Readonly<PageHeaderProps>) {
  const { setPageTitle } = usePageMetadata()
  const { sendAnalyticsPageTagView } = useAnalytics()

  useEffect(() => {
    setPageTitle(pageTitle)
  }, [pageTitle])

  useEffect(() => {
    sendAnalyticsPageTagView(pageTag)
  }, [pageTag])

  return (
    <>
      {!isRoot && !hiddenBackButton && <BackNavigationButton />}
      {!isRoot && (
        <Box sx={{ paddingBottom: 2 }}>
          <CustomBreadcrumbs items={breadcrumbItems} />
        </Box>
      )}
      <>
        {!hiddenTitle && <PageTitle pageTitle={pageTitle} />}
        {!hiddenAppBar && <PageAppBar appBarContent={appBarContent} />}
      </>
    </>
  )
}

export default PageHeader

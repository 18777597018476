import { useTranslation } from 'react-i18next'

import CustomButton from '@/components/inputs/CustomButton'
import { useAlertContext } from '@/contexts/AlertContext'
import { useCreateSpotOnPowerLimitsMutation } from '@/features/resource/hooks/useCreateSpotOnPowerLimitsMutation'
import { useInvalidateSpotOnPowerLimitsQuery } from '@/features/resource/hooks/useSpotOnPowerLimitsQuery'
import { errorHandler } from '@/utils/errorHandler'

type SpotOnEboilerPowerLimitsFormCreateProps = {
  resourceId: string
}

function SpotOnEboilerPowerLimitsFormCreate({ resourceId }: Readonly<SpotOnEboilerPowerLimitsFormCreateProps>) {
  const { t } = useTranslation()
  const { createPowerLimits, isPending } = useCreateSpotOnPowerLimitsMutation()

  const invalidateSpotOnPowerLimitsQuery = useInvalidateSpotOnPowerLimitsQuery()

  const { pushAlert } = useAlertContext()

  async function handleClick() {
    try {
      await createPowerLimits({
        resourceId,
        extraCriteria: {
          defaultValue: '1',
          type: 'max_target_consumption_fraction',
          periodLength: 'PT1H',
        },
      })

      pushAlert({
        message: t('spot_on_for_eboilers.power_limits_form.created_success_message'),
        severity: 'success',
      })

      invalidateSpotOnPowerLimitsQuery()
    } catch (err: unknown) {
      const error = errorHandler(err, t('spot_on_for_eboilers.power_limits_form.created_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <CustomButton disabled={isPending} sx={{ marginTop: 3 }} variant="contained" onClick={handleClick}>
      {t('spot_on_for_eboilers.power_limits_form.create_button')}
    </CustomButton>
  )
}

export default SpotOnEboilerPowerLimitsFormCreate

import { useTranslation } from 'react-i18next'

import CustomButton from '@/components/inputs/CustomButton'
import { useAlertContext } from '@/contexts/AlertContext'
import { useCreateSpotOnNetworkChargesMutation } from '@/features/resource/hooks/useCreateSpotOnNetworkChargesMutation'
import { useInvalidateSpotOnNetworkChargesQuery } from '@/features/resource/hooks/useSpotOnNetworkChargesQuery'
import { errorHandler } from '@/utils/errorHandler'

type SpotOnEboilerNetworkChargesFormCreateProps = {
  customerId: string
  scheduleProgramId: string
}

function SpotOnEboilerNetworkChargesFormCreate({
  customerId,
  scheduleProgramId,
}: Readonly<SpotOnEboilerNetworkChargesFormCreateProps>) {
  const { t } = useTranslation()
  const { createNetworkCharges, isPending } = useCreateSpotOnNetworkChargesMutation()

  const invalidateSpotOnNetworkChargesQuery = useInvalidateSpotOnNetworkChargesQuery()

  const { pushAlert } = useAlertContext()

  async function handleClick() {
    try {
      await createNetworkCharges({
        customerId,
        scheduleProgramId,
        extraCriteria: {
          type: 'cost',
          periodLength: 'PT1H',
        },
      })

      pushAlert({
        message: t('spot_on_for_eboilers.network_charges_form.created_success_message'),
        severity: 'success',
      })

      invalidateSpotOnNetworkChargesQuery()
    } catch (err: unknown) {
      const error = errorHandler(err, t('spot_on_for_eboilers.network_charges_form.created_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <CustomButton disabled={isPending} sx={{ marginTop: 3 }} variant="contained" onClick={handleClick}>
      {t('spot_on_for_eboilers.network_charges_form.create_button')}
    </CustomButton>
  )
}

export default SpotOnEboilerNetworkChargesFormCreate

import { DateTime } from 'luxon'

import type { MarketProgram } from '@/features/customer/types/marketProgram'
import type { RevenuePeriod } from '@/features/customer/types/revenue'
import { convertToTimeZoneDateTime } from '@/utils/time'

export function getYearsBetweenDates(calculatedSince: string, calculatedUntil: string, timeZone: string) {
  const startDate = convertToTimeZoneDateTime(timeZone, calculatedSince)
  const endDate = convertToTimeZoneDateTime(timeZone, calculatedUntil)
  const startYear = startDate.year
  const endYear = endDate.year
  const years: number[] = []

  for (let year = startYear; year <= endYear; year++) {
    years.push(year)
  }
  return years
}

export function getRevenueTimeSeriesStartTime(
  revenuePeriods: RevenuePeriod[] | null,
  timeZone: string | undefined,
  marketProgramSelection: MarketProgram | undefined,
  yearSelection: string | undefined,
) {
  if (revenuePeriods && timeZone && marketProgramSelection && yearSelection) {
    const firstDayOfYearForCustomer = DateTime.fromObject(
      { year: parseInt(yearSelection), month: 1, day: 1 },
      { zone: timeZone },
    )
    const firstDayOfYearForCustomerUtc = firstDayOfYearForCustomer.setZone(DateTime.utc().zone)
    const calculatedSince = DateTime.fromISO(
      revenuePeriods.find((period) => period.serviceId === marketProgramSelection.id)!.calculatedSince,
    )
    return calculatedSince > firstDayOfYearForCustomerUtc ? calculatedSince : firstDayOfYearForCustomerUtc
  }
}

export function getRevenueTimeSeriesEndTime(
  revenuePeriods: RevenuePeriod[] | null,
  timeZone: string | undefined,
  marketProgramSelection: MarketProgram | undefined,
  yearSelection: string | undefined,
) {
  if (revenuePeriods && timeZone && marketProgramSelection && yearSelection) {
    const firstDayOfNextYearForCustomer = DateTime.fromObject(
      { year: parseInt(yearSelection) + 1, month: 1, day: 1 },
      { zone: timeZone },
    )
    const firstDayOfNextYearForCustomerUtc = firstDayOfNextYearForCustomer.setZone(DateTime.utc().zone)
    const calculatedUntil = DateTime.fromISO(
      revenuePeriods.find((period) => period.serviceId === marketProgramSelection.id)!.calculatedUntil,
    )
    const calculatedUntilExclusive = calculatedUntil.plus({ hour: 1 })
    return calculatedUntilExclusive < firstDayOfNextYearForCustomerUtc
      ? calculatedUntilExclusive
      : firstDayOfNextYearForCustomerUtc
  }
}

export function getAvailableYearsForMarketProgram(
  revenuePeriods: RevenuePeriod[],
  timeZone: string,
  marketProgram?: MarketProgram,
): string[] {
  const years: string[] = []
  revenuePeriods
    .filter((period) => period.serviceId === marketProgram?.id)
    .forEach((period) => {
      years.push(...getYearsBetweenDates(period.calculatedSince, period.calculatedUntil, timeZone).map(String))
    })
  return years.sort((a, b) => parseInt(b) - parseInt(a))
}

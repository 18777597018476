import { TextAreaField } from '@aws-amplify/ui-react'
import { UploadFileOutlined } from '@mui/icons-material'
import { Alert, Box, Stack } from '@mui/material'
import { AxiosError } from 'axios'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PageHeader from '@/components/layouts/PageHeader'
import { useAlertContext } from '@/contexts/AlertContext'
import CountrySelector from '@/features/bidding/components/CountrySelector'
import SubmitButton from '@/features/bidding/components/SubmitButton'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import {
  useImportAcceptedBidMutation,
  useIsImportingAcceptedBid,
} from '@/features/bidding/hooks/useImportAcceptedBidMutation'

const ImportAcceptedBid = () => {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()
  const { selectedCountry } = useBiddingContext()
  const isImportingAcceptedBid = useIsImportingAcceptedBid()
  const [tsvRows, setTsvRows] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { importAcceptedBid } = useImportAcceptedBidMutation()

  const onSubmit = async () => {
    await importAcceptedBid(tsvRows)
      .then(() => {
        setTsvRows('')
        setErrorMessage('')
        pushAlert({
          message: t('bidding.import_accepted_bid.sending_successful.text'),
          severity: 'info',
          title: t('bidding.import_accepted_bid.sending_successful.title'),
        })
      })
      .catch((error) => {
        if (error instanceof AxiosError && error.response?.data != undefined) {
          setErrorMessage(error.response?.data)
        } else {
          setErrorMessage(error.message ?? t('bidding.import_accepted_bid.sending_failed.unknown_error'))
        }
      })
  }

  const dialogProps = {
    title: t('bidding.import_accepted_bid.dialog.title'),
    description: t('bidding.import_accepted_bid.dialog.description'),
  }

  return (
    <>
      <PageHeader
        appBarContent={
          <Box alignItems="center" display="flex" flexDirection="row" sx={{ justifyContent: 'left' }}>
            <CountrySelector />
          </Box>
        }
        breadcrumbItems={[{ text: t('component.page_header.bidding'), to: '/bidding' }]}
        pageTitle={t('component.page_header.import_accepted_bid')}
      />

      {selectedCountry === 'fi' && (
        <Stack direction="column" mt={2} spacing={2}>
          <Alert severity="warning">{t('bidding.import_accepted_bid.notice')}</Alert>

          {errorMessage != '' && (
            <Alert data-testid="server-error" severity="error">
              <p>{t('bidding.import_accepted_bid.sending_failed.error_message_from_server')}</p>
              <p>{errorMessage}</p>
            </Alert>
          )}

          <TextAreaField
            data-testid="tsv-rows"
            label={t('bidding.import_accepted_bid.tsv')}
            placeholder={t('bidding.import_accepted_bid.tsv_placeholder')}
            resize="vertical"
            rows={10}
            value={tsvRows}
            onChange={(e) => setTsvRows(e.target.value)}
          />

          <Box data-testid="submit-button">
            <SubmitButton
              buttonProps={{
                startIcon: <UploadFileOutlined />,
                disabled: isImportingAcceptedBid || tsvRows == '',
              }}
              dialogProps={dialogProps}
              shouldShowConfirmation={true}
              onSubmit={onSubmit}
            >
              {t('bidding.import_accepted_bid.submit')}
            </SubmitButton>
          </Box>
        </Stack>
      )}

      {selectedCountry !== 'fi' && (
        <Stack mt={2}>
          <Alert data-testid="unsupported" severity="info">
            {t('bidding.import_accepted_bid.not_supported')}
          </Alert>
        </Stack>
      )}
    </>
  )
}

export default ImportAcceptedBid

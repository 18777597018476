import { USER_ROLES } from '@/constants/userRoles'
import type { Customer, CustomerSettingsPayload } from '@/features/customer/types/customer'
import type { User } from '@/features/user/types/user'

export const hasRevenuePermissions = (
  loggedInUser: User | null,
  customer: Customer | null,
  customerSettings: CustomerSettingsPayload | null,
) => {
  const hasAllowedRole =
    loggedInUser?.role === USER_ROLES.ADMINISTRATORS.value ||
    loggedInUser?.role === USER_ROLES.CUSTOMER_MANAGERS.value ||
    loggedInUser?.role === USER_ROLES.RESOURCE_OWNERS.value

  const hasFinancialPermission = customerSettings?.isFinancialVisible ?? false
  const hasLocationPermission = customer?.location === 'fi'
  const isDirectCustomer = customer?.partner?.partnerCode === 'SYMPOWER'
  return hasAllowedRole && hasFinancialPermission && hasLocationPermission && isDirectCustomer
}

import type { TFunction } from 'i18next'
import { z } from 'zod'

import { USER_ROLES, UserRoleSchema } from '@/constants/userRoles'
import validatePhone from '@/utils/phone'

const givenName = z.string().optional()
const familyName = z.string().optional()
const getEmail = (t: TFunction) =>
  z
    .string()
    .min(1, { message: t('user_form.form.email_required.error') })
    .email({ message: t('user_form.form.email_invalid.error') })

const getPhone = (t: TFunction) =>
  z
    .string()
    .refine((value) => validatePhone(value), {
      message: t('user_form.form.phone_invalid.error'),
    })
    .optional()

const uiLanguage = z.string().optional()
const numeralLanguage = z.string().optional()

export const getUserFormSchema = (t: TFunction) =>
  z
    .object({
      username: z.string().trim().min(1, t('user_form.form.username_required.error')),
      phone: getPhone(t),
      role: UserRoleSchema,
      email: getEmail(t),
      givenName,
      familyName,
      uiLanguage,
      numeralLanguage,
      isActive: z.boolean().optional(),
      mfaSettings: z.object({
        isSmsEnabled: z.boolean(),
        isTotpEnabled: z.boolean(),
        preferredOption: z.enum(['sms', 'totp']).nullable(),
      }),
      isRestricted: z.boolean().optional(),
      allowedRoIds: z.array(z.string()).optional(),
      allowedCountries: z.array(z.string()).optional(),
      partnerCode: z.string().optional(),
    })
    .refine(
      (data) =>
        data.mfaSettings.preferredOption === null ||
        data.mfaSettings.preferredOption === 'totp' ||
        (data.mfaSettings.preferredOption === 'sms' && data.phone !== ''),
      {
        message: t('user_form.form.phone_required_when_mfa_on.error'),
        path: ['phone'],
      },
    )
    .refine(
      (data) =>
        data.mfaSettings.preferredOption === null ||
        data.mfaSettings.preferredOption === 'sms' ||
        (data.mfaSettings.preferredOption === 'totp' && data.mfaSettings.isTotpEnabled),
      {
        path: ['mfaSettings.preferredOption'],
      },
    )
    .refine(
      (data) => {
        const isElectrician = data.role === USER_ROLES.ELECTRICIANS.value
        const isNotElectrician = data.role !== USER_ROLES.ELECTRICIANS.value
        const { allowedCountries } = data

        return isNotElectrician || (isElectrician && allowedCountries && allowedCountries.length > 0)
      },
      {
        message: t('users.country_access_required'),
        path: ['allowedCountries'],
      },
    )
    .refine(
      (data) => {
        const isResourceOwnerOrCustomerManager =
          data.role === USER_ROLES.RESOURCE_OWNERS.value || data.role === USER_ROLES.CUSTOMER_MANAGERS.value
        const { allowedRoIds, isRestricted } = data

        return (
          !isResourceOwnerOrCustomerManager ||
          (isResourceOwnerOrCustomerManager && isRestricted && allowedRoIds && allowedRoIds.length > 0)
        )
      },
      { message: t('users.customer_required'), path: ['isRestricted'] },
    )
    .refine(
      (data) => {
        const isElectrician = data.role === USER_ROLES.ELECTRICIANS.value
        const isNotElectrician = data.role !== USER_ROLES.ELECTRICIANS.value
        const { allowedRoIds, isRestricted } = data

        return (
          isNotElectrician ||
          (isElectrician && isRestricted) ||
          (isElectrician && !isRestricted && allowedRoIds && allowedRoIds.length === 0)
        )
      },
      { message: t('users.electrician_missing_is_restricted'), path: ['isRestricted'] },
    )

export const getUserPersonalInfoFormSchema = (t: TFunction) =>
  z
    .object({
      phone: getPhone(t),
      email: getEmail(t),
      givenName,
      familyName,
      uiLanguage,
      numeralLanguage,
      mfaSettings: z.object({
        isSmsEnabled: z.boolean(),
        isTotpEnabled: z.boolean(),
        preferredOption: z.enum(['sms', 'totp']).nullable(),
      }),
    })
    .refine(
      (data) =>
        data.mfaSettings.preferredOption === null ||
        data.mfaSettings.preferredOption === 'totp' ||
        (data.mfaSettings.preferredOption === 'sms' && data.phone !== ''),
      {
        message: t('user_form.form.phone_required_when_mfa_on.error'),
        path: ['phone'],
      },
    )
    .refine(
      (data) =>
        data.mfaSettings.preferredOption === null ||
        data.mfaSettings.preferredOption === 'sms' ||
        (data.mfaSettings.preferredOption === 'totp' && data.mfaSettings.isTotpEnabled),
      {
        path: ['mfaSettings.preferredOption'],
      },
    )

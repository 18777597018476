import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import CustomSelectField from '@/components/inputs/CustomSelectField'
import type { ActivationGroup } from '@/features/activationGroup/types'

type Props = {
  selectedActivationGroup: ActivationGroup
  activationGroups: ActivationGroup[]
  setSelectedActivationGroup: (newActivationGroup: ActivationGroup) => void
}

const ActivationGroupSelector: FC<Props> = ({
  selectedActivationGroup,
  activationGroups,
  setSelectedActivationGroup,
}) => {
  const { t } = useTranslation()
  const sortedActivationGroups = activationGroups.toSorted((ag1, ag2) => {
    return ag1.code.localeCompare(ag2.code) || ag1.uuid.localeCompare(ag2.uuid)
  })

  const handleActivationGroupChange = (newActivationGroupUuid: string | undefined) => {
    if (!newActivationGroupUuid) return

    const newActivationGroup = activationGroups.find((ag) => ag.uuid === newActivationGroupUuid)

    if (newActivationGroup) {
      setSelectedActivationGroup(newActivationGroup)
    }
  }

  return (
    <CustomSelectField
      data-testid="activation-group-selector"
      id="activation-group-selector"
      label={t('bidding.results.activation_group')}
      options={sortedActivationGroups.map((group) => ({
        id: String(group.uuid),
        value: group.uuid,
        label: group.code,
      }))}
      size="medium"
      sx={{ minWidth: 160 }}
      value={selectedActivationGroup.uuid}
      onChange={(event) => handleActivationGroupChange(event.target.value)}
    />
  )
}

export default ActivationGroupSelector

import { Parser } from '@json2csv/plainjs'
import fileDownload from 'js-file-download'
import type { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import CsvIcon from '@/assets/icons/csv-download.svg'
import CustomButton from '@/components/inputs/CustomButton'
import type { Activation } from '@/features/activation/types/activation'
import { convertToTimeZoneDateTime } from '@/utils/time'

type ExportCsvButtonProps = {
  data: Activation[]
  timezone: string | undefined
}
const CsvDownloadIcon = () => <img alt="Csv Icon" src={CsvIcon} />

export function ExportCsvButton({ data, timezone }: Readonly<ExportCsvButtonProps>) {
  const { t } = useTranslation()

  const exportCsv = () => {
    const resourcesActivatedLabel = t('customer_details.activations.resources_activated')
    const resourcesDeactivatedLabel = t('customer_details.activations.resources_deactivated')
    const marketProgramLabel = t('common.market_program.label')
    const activatedResourcesLabel = t('customer_details.activations.activated_resources')

    let isActivatedResourcesEmpty = true

    const csvData = data.map((row) => ({
      [resourcesActivatedLabel]: formatDateTime(convertToTimeZoneDateTime(timezone ?? '', row.startedAt ?? '')),
      [resourcesDeactivatedLabel]: formatDateTime(convertToTimeZoneDateTime(timezone ?? '', row.endedAt ?? '')),
      [marketProgramLabel]: row.marketProgram,
      [activatedResourcesLabel]: filterUniqueResources(row),
    }))

    function filterUniqueResources(row: Activation) {
      const uniqueIds = Array.from(new Set(row.activatedResources?.map((resource) => resource.resourceId)))
      if (uniqueIds.length > 0) isActivatedResourcesEmpty = false

      return uniqueIds
        .map((id) => row.activatedResources?.find((resource) => resource.resourceId === id)?.resourceName)
        .sort((a, b) => (a ?? '').localeCompare(b ?? ''))
        .join(', ')
    }

    const columnLabels = [
      resourcesActivatedLabel,
      resourcesDeactivatedLabel,
      marketProgramLabel,
      activatedResourcesLabel,
    ]

    if (isActivatedResourcesEmpty) {
      columnLabels.splice(columnLabels.indexOf(activatedResourcesLabel), 1)
    }

    const parser = new Parser({
      fields: columnLabels,
    })
    const csv = parser.parse(csvData)
    const translatedActivationsTitle = t('component.page_header.activations')
    const fileName = `${translatedActivationsTitle}.csv`
    return fileDownload(csv, fileName)
  }

  return (
    <CustomButton startIcon={<CsvDownloadIcon />} variant="outlinedWhite" onClick={exportCsv}>
      {t('common.export')}
    </CustomButton>
  )
}

function formatDateTime(dateTime: DateTime) {
  return dateTime.toFormat('yyyy-MM-dd HH:mm:ss')
}

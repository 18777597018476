import { Grid2 as Grid, InputAdornment, Stack } from '@mui/material'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CheckboxController from '@/components/inputs/CheckboxController'
import CustomButton from '@/components/inputs/CustomButton'
import TextFieldController from '@/components/inputs/TextFieldController'
import { useAlertContext } from '@/contexts/AlertContext'
import { useCreateSpotOnScheduledResourceMutation } from '@/features/resource/hooks/useCreateSpotOnScheduledResourceMutation'
import { useInvalidateSpotOnScheduledResourceQuery } from '@/features/resource/hooks/useSpotOnScheduledResourceQuery'
import type { Resource } from '@/features/resource/types'
import type { ApiScheduledResource, ScheduledResource } from '@/features/resource/types/spotOnForEboilers'
import { errorHandler } from '@/utils/errorHandler'

type SpotOnEboilerConfigurationFormProps = {
  resource: Resource | null
  scheduledResource: ApiScheduledResource | null
}

function SpotOnEboilerConfigurationForm({
  resource,
  scheduledResource,
}: Readonly<SpotOnEboilerConfigurationFormProps>) {
  const { t } = useTranslation()
  const { createScheduledResource, isPending } = useCreateSpotOnScheduledResourceMutation()
  const invalidateSpotOnScheduledResourceQuery = useInvalidateSpotOnScheduledResourceQuery()

  const { pushAlert } = useAlertContext()

  const form = useForm<ScheduledResource>({
    defaultValues: {
      maximumHours: undefined,
      minimumCost: undefined,
      needsApprovalByResourceOwner: false,
    },
  })

  useEffect(() => {
    if (scheduledResource) {
      form.setValue('maximumHours', scheduledResource.maximumHours)
      form.setValue('minimumCost', parseFloat(scheduledResource.minimumCost).toFixed(2))
      form.setValue('needsApprovalByResourceOwner', scheduledResource.needsApprovalByResourceOwner)
    }
  }, [scheduledResource])

  async function handleSubmit(data: ScheduledResource) {
    try {
      await createScheduledResource({
        targetConsumptionAsFraction: 1,
        resourceId: resource!.resourceID,
        resourceOwnerId: resource!.customerId!,
        ...data,
      })

      pushAlert({
        message: t('spot_on_for_eboilers.configuration_form.created_success_message'),
        severity: 'success',
      })

      invalidateSpotOnScheduledResourceQuery()
    } catch (err: unknown) {
      const error = errorHandler(err, t('spot_on_for_eboilers.configuration_form.created_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={form.handleSubmit(handleSubmit)}>
        <Grid container paddingBottom={5} paddingTop={1} spacing={2}>
          <Grid size={{ md: 6, xs: 12 }}>
            <Stack gap={2}>
              <TextFieldController
                label={t('spot_on_for_eboilers.configuration_form.threshold_price.label')}
                name="minimumCost"
                slotProps={{
                  input: {
                    endAdornment: <InputAdornment position="end">€/MWh</InputAdornment>,
                  },
                }}
                type="text"
              />

              <TextFieldController
                label={t('spot_on_for_eboilers.configuration_form.hours_of_operation_per_day.label')}
                name="maximumHours"
                type="number"
              />

              <CheckboxController
                controllerProps={{ control: form.control }}
                label={t('spot_on_for_eboilers.configuration_form.needs_approval_by_resource_owner.label')}
                name="needsApprovalByResourceOwner"
              />
            </Stack>
          </Grid>
        </Grid>

        <CustomButton disabled={isPending} type="submit" variant="contained">
          {t('common.button.save')}
        </CustomButton>
      </form>
    </FormProvider>
  )
}

export default SpotOnEboilerConfigurationForm

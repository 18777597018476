import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { DateTime } from 'luxon'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppBarContent } from '@/components/layouts/Page'
import { RevenueContent } from '@/features/customer/components/revenue/RevenueContent'
import { RevenueMarketProgramTabs } from '@/features/customer/components/revenue/RevenueMarketProgramTabs'
import { useRevenuePeriodQuery } from '@/features/customer/hooks/useRevenuePeriodQuery'
import { useRevenueTimeSeriesQuery } from '@/features/customer/hooks/useRevenueTimeSeriesQuery'
import CustomerRevenueAppBar from '@/features/customer/pages/components/CustomerRevenueAppBar'
import type { Customer, CustomerSettingsPayload } from '@/features/customer/types/customer'
import type { MarketProgram } from '@/features/customer/types/marketProgram'
import type { RevenueDataRow } from '@/features/customer/types/revenue'
import {
  getAvailableYearsForMarketProgram,
  getRevenueTimeSeriesEndTime,
  getRevenueTimeSeriesStartTime,
} from '@/features/customer/utils/revenueUtils/dateUtils'
import { formatRevenueTimeSeries } from '@/features/customer/utils/revenueUtils/formatRevenueTimeSeries'
import { getMarketProgramsOptions } from '@/features/customer/utils/revenueUtils/marketProgramOptions'
import type { MarketProgramType } from '@/types/marketProgramType'

type CustomerRevenueProps = {
  customer: Customer
  customerSettings: CustomerSettingsPayload
}

export const CustomerRevenueView = ({ customer, customerSettings }: CustomerRevenueProps) => {
  const { t } = useTranslation()
  const { setAppBarContent } = useAppBarContent()

  const isReadyToFetchRevenueData = Boolean(customer.uuid) && customer.location === 'fi'

  const timeZone = customerSettings?.localization.timeZone ?? DateTime.utc().zoneName

  const { revenuePeriods, isFetching: isFetchingRevenuePeriod } = useRevenuePeriodQuery(
    { customerUuid: customer.uuid! },
    { enabled: isReadyToFetchRevenueData },
  )

  const relevantMarketPrograms = getMarketProgramsOptions(customer.services ?? [], revenuePeriods ?? [])
  const [marketProgramSelection, setMarketProgramSelection] = useState<MarketProgram>()

  const [yearOptions, setYearOptions] = useState<string[]>([])
  const [yearSelection, setYearSelection] = useState<string>('')

  const handleMarketProgramChange = (newValue: MarketProgramType) => {
    const newMarketProgram = relevantMarketPrograms.find((program) => program.type === newValue)!
    setMarketProgramSelection(newMarketProgram)
    const availableYears = getAvailableYearsForMarketProgram(revenuePeriods ?? [], timeZone, newMarketProgram)
    setYearOptions(availableYears)
    setYearSelection(availableYears[0])
  }

  const revenueTimeSeriesStartTime = getRevenueTimeSeriesStartTime(
    revenuePeriods,
    timeZone,
    marketProgramSelection,
    yearSelection,
  )

  const revenueTimeSeriesEndTime = getRevenueTimeSeriesEndTime(
    revenuePeriods,
    timeZone,
    marketProgramSelection,
    yearSelection,
  )

  useEffect(() => {
    if (!marketProgramSelection) {
      setMarketProgramSelection(relevantMarketPrograms.length > 0 ? relevantMarketPrograms[0] : undefined)
    }
  }, [revenuePeriods])

  useEffect(() => {
    const availableYears = getAvailableYearsForMarketProgram(revenuePeriods ?? [], timeZone, marketProgramSelection)
    setYearOptions(availableYears)
    if (availableYears.length > 0) {
      setYearSelection(
        yearSelection === '' || !availableYears.includes(yearSelection) ? availableYears[0] : yearSelection,
      )
    }
  }, [revenuePeriods, marketProgramSelection])

  const { revenueTimeSeries, isFetching: isLoadingRevenueTimeSeries } = useRevenueTimeSeriesQuery(
    {
      customerUuid: customer.uuid!,
      serviceId: marketProgramSelection?.id ?? 0,
      startTime: revenueTimeSeriesStartTime ?? DateTime.local({ zone: timeZone }),
      endTime: revenueTimeSeriesEndTime ?? DateTime.local({ zone: timeZone }),
    },
    {
      enabled: !isFetchingRevenuePeriod && Boolean(revenueTimeSeriesStartTime) && Boolean(revenueTimeSeriesEndTime),
    },
  )

  const revenueData: RevenueDataRow[] = useMemo(() => {
    return formatRevenueTimeSeries(revenueTimeSeries, timeZone)
  }, [revenueTimeSeries, timeZone])

  useEffect(() => {
    setAppBarContent(
      <CustomerRevenueAppBar
        availableYears={yearOptions}
        customerName={customer.name ?? ''}
        revenueData={revenueData}
        selectedMarketProgramName={marketProgramSelection?.name ?? ''}
        setYearSelection={setYearSelection}
        yearSelection={yearSelection}
      />,
    )
  }, [yearOptions, revenueData, customer.name, marketProgramSelection?.name, yearSelection, setAppBarContent])

  if (!isFetchingRevenuePeriod && (!revenuePeriods || revenuePeriods.length === 0))
    return (
      <Typography align={'center'} sx={{ m: 4 }}>
        {t('customer_revenue.no_revenue_data')}
      </Typography>
    )

  return (
    <Stack direction="column" mb={2} mt={2} spacing={3} sx={{ width: '100%' }}>
      <RevenueMarketProgramTabs
        handleMarketProgramChange={handleMarketProgramChange}
        marketProgramSelection={marketProgramSelection}
        marketProgramsOptions={relevantMarketPrograms}
      />
      <RevenueContent
        isLoadingRevenueTimeSeries={isLoadingRevenueTimeSeries}
        revenueData={revenueData}
        selectedYear={yearSelection}
      />
    </Stack>
  )
}

import { Stack } from '@mui/material'
import Button from '@mui/material/Button'
import { t } from 'i18next'
import { FormProvider, useForm, useWatch } from 'react-hook-form'

import type { UserRole } from '@/constants/userRoles'
import { USER_ROLES } from '@/constants/userRoles'
import { CustomerFinancialSettingsSection } from '@/features/customer/components/settings/CustomerFinancialSettingsSection'
import { CustomerLocalizationSettingsSection } from '@/features/customer/components/settings/CustomerLocalizationSettingsSection'
import { CustomerServicesSettingsSection } from '@/features/customer/components/settings/CustomerServicesSettingsSection'
import type { CustomerSettingsPayload } from '@/features/customer/types/customer'
import { formatIban } from '@/utils/iban'

interface CustomerSettingsFormProps {
  defaultValues?: CustomerSettingsPayload
  onSubmit: (data: CustomerSettingsPayload) => void
  userRole: UserRole
  isIbanNeeded: boolean
}

export interface CustomerSettingsFormValues
  extends Pick<CustomerSettingsPayload, 'location' | 'localization' | 'isFinancialVisible' | 'financialInfo'> {
  notificationPreferences: {
    isEmailEnabled: boolean
    isSmsEnabled: boolean
    email: string | null
    phoneNumbers: (string | null)[]
  }
}

export const CustomerSettingsForm = ({
  defaultValues,
  onSubmit,
  userRole,
  isIbanNeeded,
}: CustomerSettingsFormProps) => {
  const initialPhoneNumbers = [
    defaultValues?.notificationPreferences.phone ?? null,
    defaultValues?.notificationPreferences.phone2 ?? null,
    defaultValues?.notificationPreferences.phone3 ?? null,
    defaultValues?.notificationPreferences.phone4 ?? null,
    defaultValues?.notificationPreferences.phone5 ?? null,
    defaultValues?.notificationPreferences.phone6 ?? null,
    defaultValues?.notificationPreferences.phone7 ?? null,
    defaultValues?.notificationPreferences.phone8 ?? null,
    defaultValues?.notificationPreferences.phone9 ?? null,
    defaultValues?.notificationPreferences.phone10 ?? null,
  ].filter((phone) => !!phone)

  const hasCustomerUserRole = userRole === USER_ROLES.RESOURCE_OWNERS.value
  const hasCustomerManagerUserRole = userRole === USER_ROLES.CUSTOMER_MANAGERS.value
  const financialInfoExist = hasCustomerUserRole && Boolean(defaultValues?.financialInfo)
  const financialVisibilityExist = !hasCustomerUserRole && !hasCustomerManagerUserRole

  const defaultCommonSettingValues = {
    location: defaultValues?.location,
    localization: {
      uiLanguage: defaultValues?.localization.uiLanguage,
      numeralLanguage: defaultValues?.localization.numeralLanguage,
      timeZone: defaultValues?.localization.timeZone,
    },
    notificationPreferences: {
      isEmailEnabled: defaultValues?.notificationPreferences.isEmailEnabled,
      isSmsEnabled: defaultValues?.notificationPreferences.isSmsEnabled,
      email: defaultValues?.notificationPreferences.email ? defaultValues?.notificationPreferences.email : null,
      phoneNumbers: initialPhoneNumbers,
    },
  }

  function getDefaultValues() {
    if (financialVisibilityExist) {
      return {
        ...defaultCommonSettingValues,
        isFinancialVisible: defaultValues?.isFinancialVisible,
      }
    }

    if (financialInfoExist) {
      return {
        ...defaultCommonSettingValues,
        financialInfo: financialInfoExist && {
          iban: defaultValues?.financialInfo?.iban ?? '',
          ibanHolder: defaultValues?.financialInfo?.ibanHolder ?? '',
        },
      }
    }

    return defaultCommonSettingValues
  }

  const form = useForm<CustomerSettingsFormValues>({
    mode: 'onBlur',
    defaultValues: getDefaultValues(),
  })

  useWatch({
    control: form.control,
  })

  function handleFormSubmit() {
    const payload: CustomerSettingsPayload = {
      location: defaultValues?.location,
      localization: {
        uiLanguage: form.getValues('localization.uiLanguage'),
        numeralLanguage: form.getValues('localization.uiLanguage'),
        timeZone: form.getValues('localization.timeZone'),
      },
      notificationPreferences: {
        isEmailEnabled: form.getValues('notificationPreferences.isEmailEnabled'),
        isSmsEnabled: form.getValues('notificationPreferences.isSmsEnabled'),
        email: form.getValues('notificationPreferences.email') ?? '',
        phone: form.getValues('notificationPreferences.phoneNumbers')[0] ?? '',
        phone2: form.getValues('notificationPreferences.phoneNumbers')[1] ?? '',
        phone3: form.getValues('notificationPreferences.phoneNumbers')[2] ?? '',
        phone4: form.getValues('notificationPreferences.phoneNumbers')[3] ?? '',
        phone5: form.getValues('notificationPreferences.phoneNumbers')[4] ?? '',
        phone6: form.getValues('notificationPreferences.phoneNumbers')[5] ?? '',
        phone7: form.getValues('notificationPreferences.phoneNumbers')[6] ?? '',
        phone8: form.getValues('notificationPreferences.phoneNumbers')[7] ?? '',
        phone9: form.getValues('notificationPreferences.phoneNumbers')[8] ?? '',
        phone10: form.getValues('notificationPreferences.phoneNumbers')[9] ?? '',
      },
    }
    if (financialVisibilityExist) {
      payload.isFinancialVisible = form.getValues('isFinancialVisible')
    }
    if (financialInfoExist) {
      payload.financialInfo = {
        iban: formatIban(form.getValues('financialInfo.iban')),
        ibanHolder: form.getValues('financialInfo.ibanHolder'),
      }
    }

    onSubmit(payload)
  }

  return (
    <FormProvider {...form}>
      <Stack spacing={4}>
        <CustomerLocalizationSettingsSection />
        {financialInfoExist && <CustomerFinancialSettingsSection isFinancialInfoNeeded={isIbanNeeded} />}
        <CustomerServicesSettingsSection hasCustomerUserRole={hasCustomerUserRole || hasCustomerManagerUserRole} />
        <Stack direction={'row'} spacing={2} sx={{ mt: 2 }}>
          <Button variant={'contained'} onClick={form.handleSubmit(() => handleFormSubmit())}>
            {t('common.button.save')}
          </Button>
          <Button variant={'outlined'} onClick={() => form.reset()}>
            {t('common.button.cancel')}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  )
}

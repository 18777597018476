import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'

import type { BffActivation } from '@/features/activation/types/activation'
import { GET_BFF_ACTIVATIONS_API_ID, getActivations } from '@/features/customer/endpoints/bffActivations'
import type { UseQueryOptions } from '@/types/queries'

type ActivationsFromBffQueryParams = {
  siteId: string
}
export type UseActivationsFromBffQueryResult = Omit<UseQueryResult, 'data'> & {
  bffActivations: BffActivation[] | null
}

export function useActivationsFromBffQuery(
  params: ActivationsFromBffQueryParams,
  options?: UseQueryOptions<BffActivation[]>,
): UseActivationsFromBffQueryResult {
  const { data = null, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_BFF_ACTIVATIONS_API_ID, params],
    queryFn: () => {
      return getActivations(params.siteId)
    },
  })

  return { bffActivations: data, ...queryResult }
}

import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ResourceSteeringCard from '@/features/resource/components/steering/ResourceSteeringCard'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceDetails } from '@/features/resource/pages/ResourceDetailsPage'

function ResourceSteeringPage() {
  const { resourceUuid } = useParams()

  const { setActivePage } = useResourceDetails()

  useEffect(() => {
    setActivePage(ResourceRouteInformation.RESOURCE_STEERING.routePath)
  }, [])

  if (!resourceUuid) {
    return null
  }

  return <ResourceSteeringCard />
}

export default ResourceSteeringPage

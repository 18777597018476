import { zodResolver } from '@hookform/resolvers/zod'
import { SaveOutlined } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { Button, Divider, Stack } from '@mui/material'
import { DateTime, Interval } from 'luxon'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ALL_WEEKDAYS } from 'rrule/dist/esm/weekday'

import { ZONED_TODAY } from '@/constants/dateTime'
import { useAlertContext } from '@/contexts/AlertContext'
import { AVAILABILITY_STATUSES } from '@/features/availability/types/availabilityStatus'
import type {
  CreateServiceRuleRequest,
  EditServiceRuleRequest,
  ServiceRule,
} from '@/features/availability/types/serviceRule'
import { AvailabilityWireSelectionSection } from '@/features/customer/components/availability/form/AvailabilityWireSelectionSection'
import DeleteServiceRuleButton from '@/features/customer/components/availability/serviceRule/form/DeleteServiceRuleButton'
import { ServiceRuleConditionSection } from '@/features/customer/components/availability/serviceRule/form/ServiceRuleConditionSection'
import { ServiceRuleConfigurationInfoSection } from '@/features/customer/components/availability/serviceRule/form/ServiceRuleConfigurationInfoSection'
import { ServiceRuleDatesSection } from '@/features/customer/components/availability/serviceRule/form/ServiceRuleDatesSection'
import { ServiceRuleExceptionDatesSection } from '@/features/customer/components/availability/serviceRule/form/ServiceRuleExceptionDatesSection'
import { ServiceRuleRecurrenceSection } from '@/features/customer/components/availability/serviceRule/form/ServiceRuleRecurrenceSection'
import { ServiceRuleStatusSection } from '@/features/customer/components/availability/serviceRule/form/ServiceRuleStatusSection'
import { getSelectedDays } from '@/features/customer/components/availability/utils'
import { useCreateServiceRuleMutation } from '@/features/customer/hooks/useCreateServiceRuleMutation'
import { useEditServiceRuleMutation } from '@/features/customer/hooks/useEditServiceRuleMutation'
import type { MarketProgramContract } from '@/features/customer/types/marketProgramContract'
import type { ExceptionDate, ServiceRuleForm } from '@/features/customer/types/serviceRuleForm'
import { getServiceRuleFormSchema } from '@/features/customer/types/serviceRuleForm'
import { useAnalytics } from '@/features/googleAnalytics/hooks/useAnalytics'
import { convertToTimeZoneDateTime } from '@/utils/time'

interface ServiceRuleDetailsFormProps {
  onClose: () => void
  serviceRule?: ServiceRule
  marketProgram: MarketProgramContract
  customerTimeZone: string
  customerLocation: string
  customerUuid: string
}

const CustomDivider = () => <Divider style={{ marginBottom: '16px', marginTop: '16px' }} />
export const ServiceRuleDetailsForm = (props: ServiceRuleDetailsFormProps) => {
  const { t } = useTranslation()
  const { sendAnalyticsEvent } = useAnalytics()
  const { onClose } = props
  const { pushAlert } = useAlertContext()
  const isAFRR = props.marketProgram?.service.type === 'afrr-up' || props.marketProgram?.service.type === 'afrr-down'
  const isDeleteAllowed =
    props.serviceRule && isAFRR && props.serviceRule?.status !== AVAILABILITY_STATUSES.active.value

  const form = useForm<ServiceRuleForm>({
    mode: 'onBlur',
    defaultValues: {
      id: props.serviceRule?.id ?? undefined,
      startDate: props.serviceRule?.startDate
        ? convertToTimeZoneDateTime(props.customerTimeZone, props.serviceRule.startDate.toString())
        : ZONED_TODAY(props.customerTimeZone).plus({ days: 1 }),
      endDate: props.serviceRule?.endDate
        ? convertToTimeZoneDateTime(props.customerTimeZone, props.serviceRule.endDate.toString())
        : ZONED_TODAY(props.customerTimeZone).plus({ days: 2 }),
      startTime: props.serviceRule?.startTime
        ? DateTime.fromISO(props.serviceRule.startTime, { zone: props.customerTimeZone })
        : ZONED_TODAY(props.customerTimeZone).startOf('day').startOf('minute'),
      endTime: props.serviceRule?.endTime
        ? DateTime.fromISO(props.serviceRule.endTime, { zone: props.customerTimeZone })
        : ZONED_TODAY(props.customerTimeZone).endOf('day').startOf('minute'),
      exceptionDates: props.serviceRule?.exceptionDates
        ? convertExceptionDatesToForm(props.serviceRule.exceptionDates, props.customerTimeZone)
        : ([] as ExceptionDate[]),
      recurringDays: props.serviceRule?.recurrence ? [...getSelectedDays(props.serviceRule?.recurrence)] : [],
      selectedResources: props.serviceRule?.analogWires?.map((resource) => resource.id) ?? [],
      location: props.customerLocation,
      limitValue: props.serviceRule?.limitValue ?? 0,
      status: props.serviceRule?.status ?? undefined,
    },
    resolver: zodResolver(getServiceRuleFormSchema(t)),
  })

  useWatch({
    control: form.control,
  })

  const { createServiceRule } = useCreateServiceRuleMutation()
  const { editServiceRule } = useEditServiceRuleMutation()

  function convertToRequest(formValues: ServiceRuleForm): CreateServiceRuleRequest | EditServiceRuleRequest | null {
    const serviceId = props.marketProgram.service.id

    const startDateString = formValues.startDate.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false })
    const endDateString = formValues.endDate.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false })

    const exceptionDates = convertExceptionDatesToRequest(formValues.exceptionDates)

    let recurrence = 'FREQ=DAILY'
    if (formValues.recurringDays.length !== ALL_WEEKDAYS.length) {
      const recurringDays = formValues.recurringDays?.join(',')
      recurrence = 'FREQ=DAILY;BYDAY=' + recurringDays
    }

    return {
      id: formValues.id ?? undefined,
      serviceId: serviceId,
      startDate: startDateString ?? '',
      endDate: endDateString ?? '',
      startTime: formValues.startTime.toFormat('HH:mm'),
      endTime: formValues.endTime.toFormat('HH:mm'),
      exceptionDates: exceptionDates,
      recurrence: recurrence,
      limitValue: props.marketProgram.contractPrice,
      relayWires: !isAFRR ? formValues.selectedResources.map((id) => ({ id })) : [],
      analogWires: isAFRR ? formValues.selectedResources.map((id) => ({ id })) : [],
      location: props.customerLocation,
      status: formValues.status ?? undefined,
    }
  }

  const handleSubmit = async () => {
    const serviceRuleRequest = convertToRequest(form.getValues())

    if (!serviceRuleRequest) {
      form.trigger() // Trigger validation to show errors
      return
    }

    if (!props.serviceRule) {
      await createServiceRule({
        customerUuid: props.customerUuid,
        serviceId: props.marketProgram.service.id,
        serviceRule: serviceRuleRequest as CreateServiceRuleRequest,
      }).then(() => {
        sendAnalyticsEvent({
          name: 'availability_created',
          dimensions: {
            customer_uuid: props.customerUuid,
            availability_type: 'availability',
            market_program: props.marketProgram.service.type,
          },
        })
      })
      pushAlert({
        message: t('customer_details.tabs.availability.availability_details.dialog.success.create_message'),
        severity: 'success',
      })
    } else {
      await editServiceRule({
        customerUuid: props.customerUuid,
        serviceRule: serviceRuleRequest as EditServiceRuleRequest,
      })
      pushAlert({
        message: t('customer_details.tabs.availability.availability_details.dialog.success.edit_message'),
        severity: 'success',
      })
    }

    handleClose()
  }

  const handleClose = () => {
    onClose()
    form.reset()
  }

  return (
    <Stack spacing={2}>
      <FormProvider {...form}>
        <ServiceRuleRecurrenceSection />
        <CustomDivider />
        <ServiceRuleDatesSection timezone={props.customerTimeZone} />
        <CustomDivider />
        <ServiceRuleExceptionDatesSection timezone={props.customerTimeZone} />
        <CustomDivider />
        <ServiceRuleConditionSection marketProgramContract={props.marketProgram} />
        <CustomDivider />
        <AvailabilityWireSelectionSection
          customerUuid={props.customerUuid}
          marketProgram={props.marketProgram.service}
        />
        <CustomDivider />
        <ServiceRuleStatusSection />
        <ServiceRuleConfigurationInfoSection
          customerTimeZone={props.customerTimeZone}
          marketProgramContract={props.marketProgram}
        />
      </FormProvider>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        <Stack direction="row" spacing={1}>
          <Button startIcon={<SaveOutlined />} variant={'contained'} onClick={form.handleSubmit(handleSubmit)}>
            {t('common.button.save')}
          </Button>
          <Button startIcon={<CloseIcon />} variant={'outlined'} onClick={handleClose}>
            {t('common.button.cancel')}
          </Button>
        </Stack>
        {isDeleteAllowed && (
          <DeleteServiceRuleButton
            customerUuid={props.customerUuid}
            serviceId={props.marketProgram.service.id}
            serviceRuleId={props.serviceRule?.id}
            onDeleteSuccess={handleClose}
          />
        )}
      </Stack>
    </Stack>
  )
}

function convertExceptionDatesToRequest(formExceptionDates: ExceptionDate[]) {
  const exceptionDates = [] as string[]
  formExceptionDates.forEach((exceptionDate) => {
    if (exceptionDate.range) {
      const intervals = Interval.fromDateTimes(
        exceptionDate.startDate.startOf('day'),
        exceptionDate.endDate!.endOf('day'),
      )
        .splitBy({ day: 1 })
        .map((d) => d.start?.toISO({ suppressMilliseconds: true, includeOffset: false }))
      intervals.forEach((date) => {
        exceptionDates.push(date!)
      })
    } else {
      exceptionDates.push(exceptionDate.startDate.toISO({ suppressMilliseconds: true, includeOffset: false })!)
    }
  })
  return exceptionDates
}

function convertExceptionDatesToForm(exceptionDates: string[], customerTimezone: string): ExceptionDate[] {
  const formExceptionDates: ExceptionDate[] = []
  const sortedExceptionDates = [...exceptionDates].toSorted((a, b) => a.localeCompare(b))

  let temp: string[] = []
  for (let i = 0; i < sortedExceptionDates.length; i++) {
    temp.push(sortedExceptionDates[i])

    if (sortedExceptionDates[i + 1]) {
      const currentDate = DateTime.fromISO(sortedExceptionDates[i], { zone: customerTimezone })
      const nextDate = DateTime.fromISO(sortedExceptionDates[i + 1], { zone: customerTimezone })

      if (currentDate.plus({ days: 1 }).hasSame(nextDate, 'day')) {
        continue
      }
    }

    if (temp.length > 1) {
      formExceptionDates.push({
        range: true,
        startDate: DateTime.fromISO(temp[0], { zone: customerTimezone }),
        endDate: DateTime.fromISO(temp[temp.length - 1], { zone: customerTimezone }),
      })
    } else {
      formExceptionDates.push({
        range: false,
        startDate: DateTime.fromISO(temp[0], { zone: customerTimezone }),
      })
    }

    temp = []
  }

  return formExceptionDates
}

import type { FC } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import type { ActivationGroup } from '@/features/activationGroup/types'
import { BidVersionsView } from '@/features/bidding/components/bidView/BidVersionsView'
import type { BidType } from '@/features/bidding/constants'
import { ALL_RESULTS } from '@/features/bidding/constants'
import { useBiddingContext } from '@/features/bidding/contexts/BiddingContext'
import { useBidHistoriesPageQuery } from '@/features/bidding/hooks/useBidHistoriesPageQuery'
import getGroupedBidParamsFromUrl from '@/features/bidding/utils/groupedBidParams/getGroupedBidParamsFromUrl'

type Props = {
  activationGroups: ActivationGroup[]
  bidType: BidType
}

export const BidHistoriesByTypeView: FC<Props> = ({ activationGroups, bidType }) => {
  const { t } = useTranslation()
  const { selectedCountry } = useBiddingContext()
  const location = useLocation()
  const groupParams = getGroupedBidParamsFromUrl(location)
  const { bidHistoriesPage } = useBidHistoriesPageQuery(
    {
      pagination: { pageSize: ALL_RESULTS, page: 0 },
      sorting: [],
      filter: {
        items: groupParams
          ? [
              { field: 'portfolio', operator: 'equals', value: groupParams.portfolioCode },
              { field: 'marketProgram', operator: 'equals', value: groupParams.marketProgram },
              { field: 'deliveryDay', operator: 'equals', value: groupParams.deliveryDay },
              { field: 'bidType', operator: 'equals', value: bidType },
            ]
          : [],
      },
      countryFilter: selectedCountry,
    },
    { enabled: !!groupParams },
  )

  if (!bidHistoriesPage) {
    return <FullPageSpinner />
  }

  if (bidHistoriesPage.totalBidHistories === 0) {
    return <p>{t('bidding.results.no_bids')}</p>
  }

  return <BidVersionsView activationGroups={activationGroups} bidHistories={bidHistoriesPage.bidHistories} />
}

import { Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import { convertEnergyPower } from '@/utils/powerEnergyTransformations'

interface ResourceSteeringTargetProps {
  currentSteeringTarget: number | null | undefined
}

const ResourceSteeringTarget = ({ currentSteeringTarget }: ResourceSteeringTargetProps) => {
  const { t } = useTranslation()

  if (!currentSteeringTarget) {
    return (
      <Tooltip placement="top" title={t('resources.table.no_steering_target')}>
        <CustomTypography sx={{ lineHeight: 'inherit' }} variant="body1">
          N/A
        </CustomTypography>
      </Tooltip>
    )
  }

  return (
    <CustomTypography sx={{ lineHeight: 'inherit' }} variant="body1">
      {`${convertEnergyPower(currentSteeringTarget, 'kilowatts')}`}
    </CustomTypography>
  )
}

export default ResourceSteeringTarget

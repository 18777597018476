import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Page from '@/components/layouts/Page'
import { CustomerActivationDetailsView } from '@/features/activation/components/CustomerActivationDetailsView'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useCustomerQuery } from '@/features/customer/hooks/useCustomerQuery'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'

export const CustomerActivationDetails = () => {
  const { t } = useTranslation()
  const { activationId } = useParams()
  const { loggedInUser } = useAuth()
  const customerUuid = loggedInUser?.allowedRoIds?.[0] ?? ''

  const { customer, isFetching: isFetchingCustomer } = useCustomerQuery(
    { uuid: customerUuid },
    { enabled: Boolean(customerUuid) },
  )

  const { customerSettings, isFetching: isFetchingCustomerSettings } = useCustomerSettingsQuery(
    {
      location: customer?.location,
      uuid: customerUuid,
    },
    {
      enabled: !isFetchingCustomer && Boolean(customerUuid),
    },
  )

  if (!activationId || !customer || !customerSettings || isFetchingCustomer || isFetchingCustomerSettings) return null

  return (
    <Page
      pageHeaderProps={{
        pageTag: 'customer-activation-details',
        pageTitle: t('component.page_header.activation.details'),
      }}
    >
      <CustomerActivationDetailsView
        activationId={activationId}
        customer={customer}
        timeZone={customerSettings.localization.timeZone}
      />
    </Page>
  )
}

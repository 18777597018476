import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ResourceCard from '@/features/resource/components/ResourceCard'
import { ManualSteeringForm } from '@/features/resource/components/steering/ManualSteeringForm'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceData } from '@/features/resource/contexts/ResourceDataContext'
import { useResourceDetails } from '@/features/resource/pages/ResourceDetailsPage'

const ResourceManualSteeringPage = () => {
  const { resourceUuid } = useParams()
  const { setActivePage } = useResourceDetails()
  const { steeringConfig } = useResourceData()
  useEffect(() => {
    setActivePage(ResourceRouteInformation.RESOURCE_MANUAL_STEERING.routePath)
  }, [setActivePage])

  if (!steeringConfig) return null

  return (
    <ResourceCard
      content={
        <ManualSteeringForm
          isReleaseControlSupported={steeringConfig?.isReleaseControlSupported ?? false}
          resourceId={resourceUuid!}
          steeringDefaultNotDefined={steeringConfig?.steeringDefaultType === 'NONE'}
          onSteeringComplete={() => {}}
        />
      }
    />
  )
}

export default ResourceManualSteeringPage

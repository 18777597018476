import { Box } from '@mui/material'
import type { GridRowParams } from '@mui/x-data-grid'
import type { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import SpotOnEboilerApprovedByChip from '@/features/resource/components/SpotOnEboilerApprovedByChip'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useSpotOnEboilerScheduleProgram } from '@/features/resource/contexts/SpotOnEboilerScheduleProgramContext'
import { useSpotOnSchedulesQuery } from '@/features/resource/hooks/useSpotOnSchedulesQuery'
import type { ApiScheduledResource, Schedule } from '@/features/resource/types/spotOnForEboilers'

type SpotOnSchedulesGridProps = {
  scheduledResource: ApiScheduledResource | null
}

function SpotOnSchedulesGrid({ scheduledResource }: Readonly<SpotOnSchedulesGridProps>) {
  const { t } = useTranslation()

  const { spotOnScheduleProgram } = useSpotOnEboilerScheduleProgram()
  const { isLoading, schedules } = useSpotOnSchedulesQuery(
    {
      resourceId: scheduledResource?.resourceId ?? '',
    },
    { enabled: Boolean(scheduledResource) },
  )

  const timeZone = spotOnScheduleProgram?.timeZone ?? 'Europe/Helsinki'

  return (
    <CustomDataGrid
      clickableRows={{
        navigateTo: ({ row }: GridRowParams<Schedule>) =>
          ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_SCHEDULE_DETAILS.navigationPath(
            row.resourceId,
            row.activeDay,
          ),
      }}
      columns={[
        {
          field: 'activeDay',
          headerName: t('spot_on_for_eboilers.schedules.table.active_day'),
          flex: 1,
          valueFormatter: (value) =>
            DateTime.fromISO(value, { zone: timeZone }).startOf('day').toFormat('dd LLL, yyyy'),
        },
        {
          field: 'createdAt',
          headerName: t('spot_on_for_eboilers.schedules.table.created_at'),
          flex: 1,
          valueFormatter: (value) =>
            DateTime.fromISO(value, { zone: timeZone }).startOf('day').toFormat('dd LLL, yyyy'),
        },
        {
          field: 'updatedAt',
          headerName: t('spot_on_for_eboilers.schedules.table.updated_at'),
          flex: 1,
          valueFormatter: (value) =>
            DateTime.fromISO(value, { zone: timeZone }).startOf('day').toFormat('dd LLL, yyyy'),
        },
        {
          field: 'approvedBy',
          headerName: t('spot_on_for_eboilers.schedules.table.approved_by'),
          flex: 1,
          renderCell: (params: GridRenderCellParams<Schedule>) => {
            const {
              approvedByResourceOwnerAt,
              approvedByTraderAt,
              needsApprovalByResourceOwner,
              needsApprovalByTrader,
            } = params.row

            return (
              <Box alignItems="center" display="flex" flexDirection="row" gap={1} height="100%">
                {needsApprovalByResourceOwner ? (
                  <SpotOnEboilerApprovedByChip
                    approvedAt={approvedByResourceOwnerAt}
                    label={t('spot_on_for_eboilers.schedules.table.by_resource_owner')}
                    title={t('spot_on_for_eboilers.schedules.table.approved_at', {
                      datetime: DateTime.fromISO(approvedByResourceOwnerAt ?? '', { zone: timeZone }).toFormat(
                        'dd LLL, yyyy - TT',
                      ),
                    })}
                  />
                ) : null}
                {needsApprovalByTrader ? (
                  <SpotOnEboilerApprovedByChip
                    approvedAt={approvedByTraderAt}
                    label={t('spot_on_for_eboilers.schedules.table.by_trader')}
                    title={t('spot_on_for_eboilers.schedules.table.approved_at', {
                      datetime: DateTime.fromISO(approvedByTraderAt ?? '', { zone: timeZone }).toFormat(
                        'dd LLL, yyyy - TT',
                      ),
                    })}
                  />
                ) : null}
              </Box>
            )
          },
        },
      ]}
      getRowHeight={() => 52}
      includeWrapper={false}
      isLoading={isLoading}
      rows={schedules}
    />
  )
}

export default SpotOnSchedulesGrid

import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { createPowerLimits } from '@/features/resource/endpoints/scheduleBuilder'
import type { ApiCreatePowerLimits, ExtraCriteria } from '@/features/resource/types/spotOnForEboilers'
import type { UseMutationOptions } from '@/types/queries'

export type UseCreateSpotOnPowerLimitsMutationResult = Omit<
  UseMutationResult<ExtraCriteria, Error, ApiCreatePowerLimits>,
  'mutateAsync'
> & {
  createPowerLimits: UseMutationResult<ExtraCriteria, Error, ApiCreatePowerLimits>['mutateAsync']
}

export function useCreateSpotOnPowerLimitsMutation(
  options?: UseMutationOptions<ExtraCriteria, ApiCreatePowerLimits>,
): UseCreateSpotOnPowerLimitsMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: ApiCreatePowerLimits) => createPowerLimits(variables),
  })

  return { createPowerLimits: mutateAsync, ...mutationResult }
}

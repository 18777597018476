import { DateTime } from 'luxon'

import {
  DURATION_FORMAT,
  DURATION_WITH_PLUS_PREFIX_FORMAT,
  HOUR_h_MINUTE_m_SECONDS_s_FORMAT,
} from '@/constants/dateTimeFormats'
import getDuration, { convertToTimeZoneDateTime, formatDateTimeWithLocale } from '@/utils/time'

const DASH_VALUE = '-'

export function formatLocalizedDateTimeMedWithSeconds(
  customerTimeZone: string,
  language: string,
  dateTimeISO?: string,
) {
  if (!dateTimeISO) {
    return DASH_VALUE
  }
  const timeZoneDateTime = convertToTimeZoneDateTime(customerTimeZone, dateTimeISO)
  return formatDateTimeWithLocale(timeZoneDateTime, language, DateTime.DATETIME_MED_WITH_SECONDS)
}

export function formatDuration(startedAt?: string, endedAt?: string): string {
  if (!startedAt || !endedAt) {
    return '-'
  }
  return getDuration(startedAt, endedAt)
    .toFormat(DURATION_WITH_PLUS_PREFIX_FORMAT)
    .replace('00h', '')
    .replace('00m', '')
}

export function formatResourceActivationTime(customerTimeZone: string, iso?: string) {
  return iso ? convertToTimeZoneDateTime(customerTimeZone, iso).toFormat(HOUR_h_MINUTE_m_SECONDS_s_FORMAT) : DASH_VALUE
}

export function formatActivatedResourceDuration(startTime?: string, endTime?: string) {
  if (!startTime || !endTime) {
    return DASH_VALUE
  }
  return getDuration(startTime, endTime).toFormat(DURATION_FORMAT).replace('00h ', '')
}

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import { useAppBarContent } from '@/components/layouts/Page'
import { DEFAULT_DATA_GRID_PAGINATION_MODEL } from '@/constants/datagrid'
import { ActivationDetailsToolbar } from '@/features/activation/components/ActivationDetailsToolbar'
import { CUSTOMER_ACTIVATION_DETAILS_COLUMNS } from '@/features/activation/constants/customerActivationDetailsColumns'
import type { ActivatedResource } from '@/features/activation/types/activatedResource'
import {
  convertBffActivatedResourcesToStandard,
  convertMagActivatedResourcesToStandard,
  convertPresentActivationToStandard,
} from '@/features/activation/utils/converters'
import { useActivatedResourcesFromBffQuery } from '@/features/customer/hooks/useActivatedResourcesFromBffQuery'
import { useActivatedResourcesFromMagQuery } from '@/features/customer/hooks/useActivatedResourcesFromMagQuery'
import { useActivationFromBffQuery } from '@/features/customer/hooks/useActivationFromBffQuery'
import { useActivationFromMagQuery } from '@/features/customer/hooks/useActivationFromMagQuery'
import type { Customer } from '@/features/customer/types/customer'

type CustomerDetailsActivationDetailsProps = {
  customer: Customer
  activationId: string
  timeZone: string
}

function isNumeric(activationId: any) {
  return !isNaN(Number(activationId))
}

function getMarketPrograms(customer?: Customer) {
  return new Map(customer?.services?.map((mp) => [mp.id, mp.type]))
}

export const CustomerActivationDetailsView = ({
  customer,
  activationId,
  timeZone,
}: CustomerDetailsActivationDetailsProps) => {
  const { t } = useTranslation()
  const { setAppBarContent } = useAppBarContent()

  useEffect(() => {
    setAppBarContent(null)
  }, [])

  const { magActivation } = useActivationFromMagQuery(
    {
      customerUuid: customer.uuid!,
      activationId: Number(activationId),
      location: customer.location,
    },
    { enabled: isNumeric(activationId) },
  )

  const { bffActivation } = useActivationFromBffQuery(
    {
      activationId: activationId!,
    },
    { enabled: !isNumeric(activationId) },
  )

  const activation = convertPresentActivationToStandard(
    magActivation,
    bffActivation,
    getMarketPrograms(customer ?? undefined),
  )

  const { magActivatedResources, isFetching: isFetchingMagActivatedResources } = useActivatedResourcesFromMagQuery(
    {
      location: customer.location,
      activationId: magActivation?.id,
      serviceId: magActivation?.serviceId,
      customerUuid: customer.uuid,
    },
    { enabled: !!magActivation },
  )
  const { bffActivatedResources, isFetching: isFetchingBffActivatedResources } = useActivatedResourcesFromBffQuery(
    {
      siteId: customer.uuid,
      activationId: activationId!,
    },
    { enabled: !!bffActivation },
  )

  const activatedResources = magActivatedResources
    ? convertMagActivatedResourcesToStandard(magActivatedResources)
    : convertBffActivatedResourcesToStandard(bffActivatedResources ?? [])

  if (!customer || (!magActivation && !bffActivation)) return null

  return (
    <CustomDataGrid
      columns={CUSTOMER_ACTIVATION_DETAILS_COLUMNS(t, timeZone)}
      getRowId={(row: ActivatedResource) => `${row.resourceId}-${row.activatedAt}-${row.deactivatedAt}`}
      initialState={{
        pagination: {
          paginationModel: DEFAULT_DATA_GRID_PAGINATION_MODEL,
        },
        sorting: {
          sortModel: [],
        },
      }}
      isLoading={isFetchingMagActivatedResources || isFetchingBffActivatedResources}
      rows={activatedResources ?? []}
      slotProps={{
        toolbar: {
          activation: activation,
          customerTimeZone: timeZone,
        },
      }}
      slots={{
        toolbar: ActivationDetailsToolbar,
      }}
    />
  )
}

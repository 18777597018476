import { TextareaAutosize, useTheme } from '@mui/material'

type SpotOnEboilerScheduleTextareaProps = {
  value: string
  onChange: (event: { target: { value: string } }) => void
}

function SpotOnEboilerScheduleTextarea({ value, onChange }: Readonly<SpotOnEboilerScheduleTextareaProps>) {
  const theme = useTheme()

  return (
    <TextareaAutosize
      key={value}
      defaultValue={value}
      minRows={24}
      style={{
        border: `1px solid ${theme.palette.divider}`,
        lineHeight: '52px',
        paddingLeft: '12px',
        paddingTop: '4px',
        resize: 'none',
      }}
      onChange={onChange}
    />
  )
}

export default SpotOnEboilerScheduleTextarea

import { PendingActionsOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import CustomChip from '@/components/dataDisplay/CustomChip'

type SpotOnEboilerApprovedByChipProps = {
  approvedAt: string | null
  label: string
  title: string
}

function SpotOnEboilerApprovedByChip({ approvedAt, label, title }: Readonly<SpotOnEboilerApprovedByChipProps>) {
  const { t } = useTranslation()

  if (approvedAt !== null) {
    return <CustomChip color="success" label={label} title={title} variant="outlined" />
  }

  return (
    <CustomChip
      color="default"
      icon={<PendingActionsOutlined color="disabled" />}
      label={label}
      title={t('spot_on_for_eboilers.schedules.table.not_approved_yet')}
      variant="outlined"
    />
  )
}

export default SpotOnEboilerApprovedByChip

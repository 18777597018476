import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { useResourceWithPollingQuery } from '@/features/resource/hooks/useResourceWithPollingQuery'
import type { Resource, ResourceSteeringConfig } from '@/features/resource/types'

interface ResourceContextType {
  resource?: Resource
  steeringConfig?: ResourceSteeringConfig
  stopPolling: () => void
  restartPolling: () => void
}

const ResourceDataContext = createContext<ResourceContextType | null>(null)

export function ResourceDataProvider({ children }: { children: ReactNode }) {
  const { resourceUuid } = useParams()
  const { resource, steeringConfig, stopPolling, restartPolling } = useResourceWithPollingQuery({ id: resourceUuid! })

  const value = {
    resource,
    steeringConfig,
    stopPolling,
    restartPolling,
  }

  return <ResourceDataContext.Provider value={value}>{children}</ResourceDataContext.Provider>
}

export function useResourceData() {
  const context = useContext(ResourceDataContext)
  if (!context) {
    throw new Error('useResourceData must be used within ResourceDataProvider')
  }
  return context
}

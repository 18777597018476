import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { GET_SPOT_ON_POWER_LIMITS_API_ID, getPowerLimits } from '@/features/resource/endpoints/scheduleBuilder'
import type { ExtraCriteria } from '@/features/resource/types/spotOnForEboilers'
import type { UseQueryOptions } from '@/types/queries'

type UseSpotOnPowerLimitsQueryParams = {
  resourceId: string
}

export type UseSpotOnPowerLimitsQueryResult = Omit<UseQueryResult, 'data'> & {
  extraCriteria: ExtraCriteria[]
}

export function useSpotOnPowerLimitsQuery(
  params: UseSpotOnPowerLimitsQueryParams,
  options?: UseQueryOptions<ExtraCriteria[] | null>,
): UseSpotOnPowerLimitsQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_SPOT_ON_POWER_LIMITS_API_ID, params],
    queryFn: () => getPowerLimits(params.resourceId),
  })

  return {
    extraCriteria: data ?? [],
    ...queryResult,
  }
}

export function useInvalidateSpotOnPowerLimitsQuery() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_SPOT_ON_POWER_LIMITS_API_ID] })
}

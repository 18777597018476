import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ResourceCard from '@/features/resource/components/ResourceCard'
import ResourceSupportToolboxCard from '@/features/resource/components/ResourceSupportToolbox'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useResourceDetails } from '@/features/resource/pages/ResourceDetailsPage'

const ResourceSupportToolboxPage = () => {
  const { resourceUuid } = useParams()
  const { setActivePage } = useResourceDetails()

  useEffect(() => {
    setActivePage(ResourceRouteInformation.RESOURCE_SUPPORT_TOOLBOX.routePath)
  }, [setActivePage])

  return <ResourceCard content={<ResourceSupportToolboxCard resourceId={resourceUuid} />} />
}

export default ResourceSupportToolboxPage

import type { BidPtu } from '@/features/bidding/types/bid'

export function getBidTotalOffered(bidPtus: BidPtu[]): number {
  if (bidPtus.length === 0) return 0
  if (bidPtus[0].ptuChunks && bidPtus[0].ptuChunks.length > 0) {
    // calculate total based on PTU chunks
    return bidPtus.reduce(
      (total, ptu) => total + (ptu.ptuChunks?.reduce((total, chunk) => total + chunk.offeredVolume.quantity, 0) ?? 0),
      0,
    )
  }

  // calculate total based on PTUs
  return bidPtus.reduce((total, ptu) => total + ptu.volume.quantity, 0)
}

export function getBidTotalAccepted(bidPtus: BidPtu[]): number {
  if (bidPtus.length === 0) return 0
  if (bidPtus[0].ptuChunks && bidPtus[0].ptuChunks.length > 0) {
    // calculate total based on PTU chunks
    return bidPtus.reduce(
      (total, ptu) =>
        total + (ptu.ptuChunks?.reduce((total, chunk) => total + (chunk.acceptedVolume?.quantity ?? 0), 0) ?? 0),
      0,
    )
  }

  // calculate total based on PTUs
  return bidPtus.reduce((total, ptu) => total + ptu.volume.quantity, 0)
}

import { zodResolver } from '@hookform/resolvers/zod'
import WarningIcon from '@mui/icons-material/Warning'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CheckboxController from '@/components/inputs/CheckboxController'
import FloatTextField from '@/components/inputs/FloatTextField'
import { useAlertContext } from '@/contexts/AlertContext'
import { steerResource } from '@/features/resource/endpoints/resources'
import { ManualSteeringFormSchema } from '@/features/resource/schemas'
import type { ManualSteeringFormSchemaType } from '@/features/resource/types'
import { convertToWatts } from '@/features/resource/utils/convertToWatts'

type ManualSteeringFormProps = {
  resourceId: string
  isReleaseControlSupported: boolean
  steeringDefaultNotDefined: boolean
  onSteeringComplete: () => void
}

export const ManualSteeringForm: React.FC<ManualSteeringFormProps> = ({
  resourceId,
  isReleaseControlSupported,
  steeringDefaultNotDefined,
  onSteeringComplete,
}) => {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()
  const form = useForm<ManualSteeringFormSchemaType>({
    resolver: zodResolver(ManualSteeringFormSchema),
    defaultValues: {
      steeringCommandType: 'SET_TARGET_CONSUMPTION_LEVEL',
      targetConsumptionLevel: null,
      steerUntil: null,
      force: false,
    },
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = form

  const steeringCommandType = watch('steeringCommandType')

  const steeringCommandOptions = [
    {
      id: 'SET_TARGET_CONSUMPTION_LEVEL',
      value: 'SET_TARGET_CONSUMPTION_LEVEL',
      label: t('resources.steering.manual_steering.set_target_consumption_level'),
    },
    {
      id: 'RELEASE_CONTROL',
      value: 'RELEASE_CONTROL',
      label: t('resources.steering.manual_steering.release_control'),
      disabled: !isReleaseControlSupported,
    },
  ]

  const onSubmit = async (data: ManualSteeringFormSchemaType) => {
    try {
      const submitData = {
        ...data,
        targetConsumptionLevel:
          data.steeringCommandType === 'RELEASE_CONTROL' || data.targetConsumptionLevel === null
            ? null
            : convertToWatts(data.targetConsumptionLevel),
        steerUntil: data.steerUntil ? DateTime.fromJSDate(data.steerUntil).toISO() : null,
      }
      await steerResource(resourceId, submitData)
      pushAlert({
        message: t('resources.steering.manual_steering.success_update_power'),
        severity: 'success',
      })
      onSteeringComplete()
    } catch {
      pushAlert({
        message: t('resources.steering.manual_steering.errors_update_power'),
        severity: 'error',
      })
    }
  }

  const setSteerUntil = (minutes: number) => {
    const newDate = DateTime.local().plus({ minutes }).toJSDate()
    form.setValue('steerUntil', newDate)
  }

  return (
    <FormProvider {...form}>
      <Card>
        <CardHeader title={t('resources.steering.manual_steering.title')} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Controller
                control={control}
                name="steeringCommandType"
                render={({ field }) => (
                  <>
                    <FormLabel id="radiogroup-label-command-type">
                      {t('resources.steering.manual_steering.command_type')}
                    </FormLabel>
                    <RadioGroup aria-labelledby="radiogroup-label-command-type" {...field}>
                      {steeringCommandOptions.map((option) => (
                        <FormControlLabel
                          key={option.id}
                          control={<Radio />}
                          disabled={option.disabled}
                          label={option.label}
                          value={option.value}
                        />
                      ))}
                    </RadioGroup>
                  </>
                )}
              />

              {steeringCommandType === 'SET_TARGET_CONSUMPTION_LEVEL' && (
                <FloatTextField
                  numberOfDecimals={3}
                  textFieldProps={{
                    label: t('resources.steering.manual_steering.target_consumption_level'),
                    InputProps: {
                      endAdornment: <InputAdornment position="start">{'kW'}</InputAdornment>,
                      error: !!errors.targetConsumptionLevel,
                    },
                    helperText: errors.targetConsumptionLevel
                      ? t('resources.steering.manual_steering.invalid_consumption_level')
                      : '',
                  }}
                  onValueChange={(value) => {
                    form.setValue('targetConsumptionLevel', value ?? null)
                  }}
                />
              )}

              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <Controller
                  control={control}
                  name="steerUntil"
                  render={({ field, fieldState: { error } }) => (
                    <Tooltip
                      arrow
                      disableHoverListener={!steeringDefaultNotDefined}
                      placement="bottom"
                      title={
                        steeringDefaultNotDefined
                          ? t('resources.steering.manual_steering.tooltip.steer_until_disabled')
                          : ''
                      }
                    >
                      <div>
                        <Stack alignItems="center" direction="row" spacing={2}>
                          <DateTimePicker
                            ampm={false}
                            disabled={steeringDefaultNotDefined}
                            label={t('resources.steering.manual_steering.steer_until')}
                            minDateTime={DateTime.now()}
                            slotProps={{
                              textField: {
                                fullWidth: true,
                                error: !!error,
                                helperText: error ? t('resources.steering.manual_steering.future_date_required') : '',
                              },
                            }}
                            value={field.value ? DateTime.fromJSDate(field.value) : null}
                            onChange={(newValue) => field.onChange(newValue ? newValue.toJSDate() : null)}
                          />
                          <Button variant="outlined" onClick={() => setSteerUntil(1)}>
                            {t('resources.steering.manual_steering.set_minutes_from_now', { minutes: 1 })}
                          </Button>
                          <Button variant="outlined" onClick={() => setSteerUntil(5)}>
                            {t('resources.steering.manual_steering.set_minutes_from_now', { minutes: 5 })}
                          </Button>
                        </Stack>
                      </div>
                    </Tooltip>
                  )}
                />
              </LocalizationProvider>

              <Stack alignItems="center" direction="row" spacing={1}>
                <CheckboxController label={t('resources.steering.manual_steering.force')} name="force" />
                <Tooltip arrow placement="top" title={t('resources.steering.manual_steering.tooltip.force_option')}>
                  <IconButton aria-label="warning" color="warning" size="small">
                    <WarningIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>

              <Stack direction="row" spacing={2}>
                <Button type="submit" variant="contained">
                  {t('resources.steering.manual_steering.submit')}
                </Button>
                <Button type="button" variant="outlined" onClick={onSteeringComplete}>
                  {t('common.button.cancel')}
                </Button>
              </Stack>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </FormProvider>
  )
}

import type { FC } from 'react'
import { useState } from 'react'

import type { ActivationGroup } from '@/features/activationGroup/types'
import BidVersionPtusDataGrid from '@/features/bidding/components/BidVersionPtusDataGrid'
import ActivationGroupSelector from '@/features/bidding/components/bidView/ActivationGroupSelector'
import BidVersionSelector from '@/features/bidding/components/bidView/BidVersionSelector'
import type { Bid } from '@/features/bidding/types/bid'
import type { BidHistory } from '@/features/bidding/types/bidHistory'
import findAcceptedBidBeforeBuyback from '@/features/bidding/utils/findAcceptedBidBeforeBuyback'

type Props = {
  activationGroups: ActivationGroup[]
  bidHistories: BidHistory[]
}

export const BidVersionsView: FC<Props> = ({ activationGroups, bidHistories }) => {
  const [selectedHistory, setSelectedHistory] = useState<BidHistory>(bidHistories[0])
  const [selectedVersion, setSelectedVersion] = useState<Bid>(getLatestVersion(selectedHistory.bids))
  const uniqueActivationGroups = [
    ...new Set(
      bidHistories.map((bidHistory) => activationGroups.find((ag) => ag.uuid === bidHistory.activationGroupUuid)!),
    ),
  ]

  const handleActivationGroupChange = (activationGroup: ActivationGroup) => {
    const newSelectedBidHistory = bidHistories.find(
      (bidHistory) => bidHistory.activationGroupUuid === activationGroup.uuid,
    )!
    setSelectedHistory(newSelectedBidHistory)
    setSelectedVersion(getLatestVersion(newSelectedBidHistory.bids))
  }

  return (
    <BidVersionPtusDataGrid
      activationGroupSelectorComponent={
        <ActivationGroupSelector
          activationGroups={uniqueActivationGroups}
          selectedActivationGroup={
            uniqueActivationGroups.find((ag) => ag.uuid === selectedHistory.activationGroupUuid)!
          }
          setSelectedActivationGroup={handleActivationGroupChange}
        />
      }
      bidSelectorComponent={
        <BidVersionSelector
          bidVersions={selectedHistory.bids}
          selectedVersion={selectedVersion}
          setSelectedVersion={setSelectedVersion}
        />
      }
      bidVersion={selectedVersion}
      boughtBackVersion={findAcceptedBidBeforeBuyback(selectedVersion, selectedHistory.bids)}
      isLoading={false}
    />
  )
}

const getLatestVersion = (bidVersions: Bid[]): Bid =>
  [...bidVersions].sort((bid1, bid2) => bid2.createdAt.valueOf() - bid1.createdAt.valueOf())[0]

import type { UseQueryResult } from '@tanstack/react-query'
import { useIsFetching, useQuery, useQueryClient } from '@tanstack/react-query'

import type { BidOverviewPageQueryParams } from '@/features/bidding/endpoints/bidOverview'
import { getBidOverview } from '@/features/bidding/endpoints/bidOverview'
import { useErrorHandler } from '@/features/bidding/hooks/useErrorHandler'
import type { BidOverviewPage } from '@/features/bidding/types/bidOverview'
import type { UseQueryOptions } from '@/types/queries'

const GET_BID_OVERVIEW_API_ID = 'GET_BID_OVERVIEW'

export type UseBidOverviewPageQueryResult = Omit<UseQueryResult, 'data'> & {
  bidOverviewPage: BidOverviewPage | null
}

export const useBidOverviewPageQuery = (
  params: BidOverviewPageQueryParams,
  options?: UseQueryOptions<BidOverviewPage>,
): UseBidOverviewPageQueryResult => {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_BID_OVERVIEW_API_ID, params],
    queryFn: () => getBidOverview(params),
  })

  useErrorHandler(queryResult.isError)
  return { bidOverviewPage: data ?? null, ...queryResult }
}

export const useInvalidateBidOverviewPageQuery = () => {
  const queryClient = useQueryClient()
  return () => queryClient.invalidateQueries({ queryKey: [GET_BID_OVERVIEW_API_ID] })
}

export const useIsFetchingBidOverview = (): boolean => useIsFetching({ queryKey: [GET_BID_OVERVIEW_API_ID] }) > 0

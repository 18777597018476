import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import Page from '@/components/layouts/Page'
import { DataGridSyncUrlManagerProvider } from '@/contexts/DataGridSyncUrlManagerContext'
import { ProtectedRoute } from '@/features/authentication/components/ProtectedRoute'
import { Availability } from '@/features/customer/pages/Availability'
import { CompanyInfo } from '@/features/customer/pages/CompanyInfo'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { CustomerAvailability } from '@/features/customer/pages/CustomerAvailability'
import { CustomerContactInfo } from '@/features/customer/pages/CustomerContactInfo'
import CustomerDetails from '@/features/customer/pages/CustomerDetails'
import { CustomerDetailsActivationDetails } from '@/features/customer/pages/CustomerDetailsActivationDetails'
import { CustomerDetailsActivations } from '@/features/customer/pages/CustomerDetailsActivations'
import { CustomerDetailsRevenue } from '@/features/customer/pages/CustomerDetailsRevenue'
import { CustomerPowerMeasurements } from '@/features/customer/pages/CustomerPowerMeasurements'
import { CustomerRevenue } from '@/features/customer/pages/CustomerRevenue'
import Customers from '@/features/customer/pages/Customers'
import { CustomerSettings } from '@/features/customer/pages/CustomerSettings'
import { CustomerSettingsForCustomerUser } from '@/features/customer/pages/CustomerSettingsForCustomerUser'
import { PowerMeasurements } from '@/features/customer/pages/PowerMeasurements'
import { ResourceLevelUnavailability } from '@/features/customer/pages/ResourceLevelUnavailability'
import SiteCreate from '@/features/customer/pages/SiteCreate'
import NavigateToError from '@/features/error/pages/NavigateToError'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'

export default function CustomersRoutes() {
  const { t } = useTranslation()
  const { isEnabled } = useFeatureToggle()

  return (
    <Routes>
      <Route element={<ProtectedRoute permission="customers" />}>
        <Route element={<Availability />} path={'/availability'} />
        <Route element={<CompanyInfo />} path="/company-info" />
        <Route element={<CustomerSettingsForCustomerUser />} path="/settings" />
        <Route element={<ProtectedRoute permission="revenues" />}>
          <Route element={<CustomerRevenue />} path="/revenue" />
        </Route>
        <Route element={<ProtectedRoute permission="powerMeasurements" />}>
          <Route
            element={
              <Page
                pageHeaderProps={{
                  pageTag: 'power-measurements',
                  hiddenTitle: true,
                  hiddenAppBar: true,
                  pageTitle: t('customer_measurements.power_measurements.title'),
                }}
              >
                <PowerMeasurements />
              </Page>
            }
            path="/power-measurements"
          />
        </Route>
      </Route>
      {isEnabled('FLEXPORTAL_SITE_CREATE_ENABLED') && (
        <Route element={<ProtectedRoute permission="siteCreation" />}>
          <Route
            element={
              <Page
                pageHeaderProps={{
                  pageTag: 'site-create',
                  hiddenBackButton: true,
                  hiddenAppBar: true,
                  pageTitle: t('component.page_header.site.create'),
                }}
              >
                <SiteCreate />
              </Page>
            }
            path="/create"
          />
        </Route>
      )}
      <Route element={<ProtectedRoute permission="customerDetails" />}>
        <Route
          element={
            <DataGridSyncUrlManagerProvider>
              <Customers />
            </DataGridSyncUrlManagerProvider>
          }
          path="/"
        />
        <Route element={<CustomerDetails />} path="/:customerUuid">
          <Route index element={<CustomerContactInfo />} />
          <Route element={<CustomerContactInfo />} path={CustomerDetailsRouteInformation.CONTACT_INFO.routePath} />
          <Route element={<CustomerAvailability />} path={CustomerDetailsRouteInformation.AVAILABILITY.routePath} />
          <Route
            element={<ResourceLevelUnavailability />}
            path={CustomerDetailsRouteInformation.RES_LEVEL_UNAVAILABILITY.routePath}
          />
          <Route
            element={<CustomerDetailsActivations />}
            path={CustomerDetailsRouteInformation.ACTIVATIONS.routePath}
          />
          <Route
            element={<CustomerPowerMeasurements />}
            path={CustomerDetailsRouteInformation.POWER_MEASUREMENTS.routePath}
          />
          <Route
            element={<CustomerDetailsActivationDetails />}
            path={CustomerDetailsRouteInformation.ACTIVATION_DETAILS.routePath}
          />
          <Route element={<CustomerDetailsRevenue />} path={CustomerDetailsRouteInformation.REVENUE.routePath} />
          <Route element={<CustomerSettings />} path={CustomerDetailsRouteInformation.SETTINGS.routePath} />
        </Route>
      </Route>

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { Card, CardActionArea, CardContent, Stack } from '@mui/material'
import * as React from 'react'

import CustomTypography from '@/components/dataDisplay/CustomTypography'

const cardStyles = {
  height: '100%',
  display: 'flex',
  flex: 1,
}

interface NumericWidgetProps {
  title: string
  disabled?: boolean
  value?: number | string
  icon?: string | React.ReactElement
  onClick?: () => void
  valueFontSize?: number
}

export default function NumericWidget({
  title,
  disabled,
  value,
  icon,
  onClick,
  valueFontSize = 18,
}: Readonly<NumericWidgetProps>) {
  return (
    <Card sx={cardStyles} onClick={onClick}>
      <CardActionArea disabled={disabled}>
        <CardContent>
          <Stack alignItems={'center'} direction={'row'} justifyContent={'space-between'}>
            <CustomTypography variant={'h4'}>{title}</CustomTypography>
            {!disabled && <KeyboardArrowRightIcon color="primary" fontSize="small" />}
          </Stack>
          <Stack alignContent={'center'} alignItems={'center'} direction={'row'} spacing={1} sx={{ mt: 2, mb: 1 }}>
            {icon}
            <CustomTypography fontSize={valueFontSize} fontWeight={'bold'} letterSpacing={0} variant={'h4'}>
              {value}
            </CustomTypography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

import { Stack } from '@mui/material'
import type { DateTime } from 'luxon'
import type { Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'

import type { Period } from '@/constants/period'
import ActivationTimeRangeFilter from '@/features/activation/components/ActivationTimeRangeFilter'
import { ExportCsvButton } from '@/features/activation/components/ExportCsvButton'
import type { Activation } from '@/features/activation/types/activation'
import type { TimeRange } from '@/features/activation/types/timeRange'
import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'
import { CustomerSelector } from '@/features/customer/components/CustomerSelector'

type CustomerActivationsAppBarProps = {
  isLoading?: boolean
  dateOfFirstActivation: DateTime | undefined
  periodSelection: Period
  timeRange: TimeRange
  setTimeRange: Dispatch<SetStateAction<TimeRange>>
  setPeriodSelection: Dispatch<SetStateAction<Period>>
  activationsData: Activation[] | undefined
  timezone?: string
}

function CustomerActivationsAppBar({
  isLoading,
  dateOfFirstActivation,
  periodSelection,
  setPeriodSelection,
  timeRange,
  setTimeRange,
  activationsData,
  timezone,
}: Readonly<CustomerActivationsAppBarProps>) {
  const { customerUuid } = useParams()
  const { permissions } = usePermissions()

  return (
    <Stack flexDirection={'row'} justifyContent="space-between" sx={{ width: '100%' }}>
      <Stack alignItems="center" direction="row" spacing={1}>
        {permissions.has('customerDetails') && <CustomerSelector customerUuid={customerUuid} />}
        {!isLoading && (
          <ActivationTimeRangeFilter
            dateOfFirstActivation={dateOfFirstActivation}
            periodSelection={periodSelection}
            setPeriodSelection={setPeriodSelection}
            setTimeRange={setTimeRange}
            timeRange={timeRange}
            timezone={timezone}
          />
        )}
      </Stack>
      {!isLoading && <ExportCsvButton data={activationsData ?? []} timezone={timezone} />}
    </Stack>
  )
}

export default CustomerActivationsAppBar

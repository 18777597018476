import { useEffect } from 'react'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import SpotOnEboilerNetworkChargesFormCreate from '@/features/resource/components/SpotOnEboilerNetworkChargesFormCreate'
import SpotOnEboilerNetworkChargesFormEdit from '@/features/resource/components/SpotOnEboilerNetworkChargesFormEdit'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useSpotOnEboilerScheduleProgram } from '@/features/resource/contexts/SpotOnEboilerScheduleProgramContext'
import { useSpotOnNetworkChargesQuery } from '@/features/resource/hooks/useSpotOnNetworkChargesQuery'
import { useResourceSpotOnEboilerDetails } from '@/features/resource/pages/ResourceSpotOnEboilerDetailsPage'

function SpotOnEboilerNetworkChargesPage() {
  const { resource, setActivePage } = useResourceSpotOnEboilerDetails()
  const { spotOnScheduleProgram } = useSpotOnEboilerScheduleProgram()
  const { extraCriteria, isLoading } = useSpotOnNetworkChargesQuery(
    {
      scheduleProgramId: spotOnScheduleProgram?.id ?? '',
      customerId: resource?.customerId ?? '',
    },
    { enabled: Boolean(resource) },
  )

  useEffect(() => {
    setActivePage(ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_NETWORK_CHARGES.routePath)
  }, [])

  if (isLoading) return <FullPageSpinner />

  const networkChargesExtraCriteria = extraCriteria.find((criteria) => criteria.type === 'cost')

  return (
    <MainContentContainer sx={{ marginBottom: 3 }}>
      <CustomTypography gutterBottom variant="h3">
        {resource?.resourceName}
      </CustomTypography>
      {networkChargesExtraCriteria ? (
        <SpotOnEboilerNetworkChargesFormEdit
          customerId={resource?.customerId ?? ''}
          networkChargesExtraCriteria={networkChargesExtraCriteria}
          scheduleProgramId={spotOnScheduleProgram?.id ?? ''}
        />
      ) : (
        <SpotOnEboilerNetworkChargesFormCreate
          customerId={resource?.customerId ?? ''}
          scheduleProgramId={spotOnScheduleProgram?.id ?? ''}
        />
      )}
    </MainContentContainer>
  )
}

export default SpotOnEboilerNetworkChargesPage

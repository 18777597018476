import { Route, Routes } from 'react-router-dom'

import { CustomerActivationDetails } from '@/features/activation/pages/CustomerActivationDetails'
import { CustomerActivations } from '@/features/activation/pages/CustomerActivations'
import NavigateToError from '@/features/error/pages/NavigateToError'

export default function ActivationRoutes() {
  return (
    <Routes>
      <Route element={<CustomerActivations />} path="/" />
      <Route element={<CustomerActivationDetails />} path="/:activationId" />
      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}

import type { GridColDef } from '@mui/x-data-grid'
import type { TFunction } from 'i18next'

import type { Activation } from '@/features/activation/types/activation'
import { formatDuration, formatLocalizedDateTimeMedWithSeconds } from '@/features/activation/utils/formatDateTime'
import { getMarketProgramTypes } from '@/features/activation/utils/getMarketProgramTypes'
import type { MarketProgram } from '@/features/customer/types/marketProgram'
import type { MarketProgramType } from '@/types/marketProgramType'

export const CUSTOMER_ACTIVATION_COLUMNS = (
  t: TFunction,
  timeZone: string,
  marketPrograms: MarketProgram[] | null,
  language: string,
): GridColDef<Activation>[] => [
  {
    field: 'startedAt',
    headerName: t('customer_details.activations.resources_activated'),
    flex: 1,
    valueFormatter: (value: Activation['startedAt']) =>
      formatLocalizedDateTimeMedWithSeconds(timeZone ?? '', language, value),
  },
  {
    field: 'endedAt',
    headerName: t('customer_details.activations.resources_deactivated'),
    flex: 1,
    valueGetter: (value: Activation['endedAt'], activation: Activation) => {
      return value ? formatDuration(activation.startedAt, value) : '-'
    },
  },
  {
    field: 'marketProgram',
    headerName: t('common.market_program.label'),
    flex: 1,
    type: 'singleSelect',
    valueOptions: Array.from(getMarketProgramTypes(marketPrograms).values()).map((mp) => mp as string),
    getOptionLabel: (value) => (value ? t(`common.market_program.${value as MarketProgramType}`) : '-'),
  },
]

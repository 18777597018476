import { Box, Tab, Tabs } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { ResourceRouteInformation } from '@/features/resource/constants'

const commonProps = {
  component: Link,
  disableRipple: true,
  iconPosition: 'start',
  style: { minHeight: 0 },
} as const

type ResourceTabsProps = {
  activeTab: string
  hasScheduledResource: boolean
}

const ResourceSpotOnEboilerDetailsTabs = ({ activeTab, hasScheduledResource }: ResourceTabsProps) => {
  const { resourceUuid } = useParams()
  const { t } = useTranslation()

  return (
    <Box sx={{ mt: 2, borderBottom: 1, borderColor: 'divider' }}>
      <Tabs aria-label="resource-spot-on-eboiler-tabs" value={activeTab}>
        <Tab
          {...commonProps}
          label={t('spot_on_for_eboilers.tabs.configuration')}
          to={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_CONFIGURATION.navigationPath(resourceUuid!)}
          value={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_CONFIGURATION.routePath}
        />
        <Tab
          {...commonProps}
          label={t('spot_on_for_eboilers.tabs.power_limits')}
          to={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_POWER_LIMITS.navigationPath(resourceUuid!)}
          value={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_POWER_LIMITS.routePath}
        />
        <Tab
          {...commonProps}
          label={t('spot_on_for_eboilers.tabs.network_charges')}
          to={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_NETWORK_CHARGES.navigationPath(resourceUuid!)}
          value={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_NETWORK_CHARGES.routePath}
        />
        <Tab
          {...commonProps}
          disabled={!hasScheduledResource}
          label={t('spot_on_for_eboilers.tabs.schedules')}
          to={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_SCHEDULES.navigationPath(resourceUuid!)}
          value={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_SCHEDULES.routePath}
        />
      </Tabs>
    </Box>
  )
}

export default ResourceSpotOnEboilerDetailsTabs

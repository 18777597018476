import type { ActivatedResource, BffActivatedResource } from '@/features/activation/types/activatedResource'
import type { MarketProgramType } from '@/types/marketProgramType'

export interface MagActivation {
  id?: number
  serviceId?: number
  startedAt?: string
  endedAt?: string
  mostExtremeFrequency?: number
  serviceActivationTargetBasisPoint?: number
  serviceActivationTarget?: number
  actualServiceActivationBasisPoint?: number
  actualServiceActivation?: number
  activatableCapacity?: number
  limitValue?: number
  actualValue?: number
}

export enum ActivationType {
  DISTURBANCE = 'DISTURBANCE',
  PREQUALIFICATION = 'PREQUALIFICATION',
  UNKNOWN = 'UNKNOWN',
}

export interface BffActivation {
  id?: string
  startedAt?: string
  endedAt?: string
  marketProgram?: string
  activatedResources?: BffActivatedResource[]
  type?: ActivationType
}

export interface Activation {
  id?: number | string
  startedAt?: string
  endedAt?: string
  marketProgram?: MarketProgramType
  activatedResources?: ActivatedResource[]
  type?: ActivationType
}

import { Box, CircularProgress, Grid2 as Grid, Stack } from '@mui/material'
import type { GridColDef, GridRenderEditCellParams } from '@mui/x-data-grid'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import DataGridEditableTextField from '@/components/dataDisplay/DataGridEditableTextField'
import CustomButton from '@/components/inputs/CustomButton'
import { useAlertContext } from '@/contexts/AlertContext'
import SpotOnEboilerDisplayedTimezone from '@/features/resource/components/SpotOnEboilerDisplayedTimezone'
import { DEFAULT_EXTRA_CRITERIA_WEEKDAYS } from '@/features/resource/constants/spotOnForBoilers'
import { useUpdateSpotOnPowerLimitsMutation } from '@/features/resource/hooks/useUpdateSpotOnPowerLimitsMutation'
import type { ExtraCriteria, ExtraCriteriaWeekday } from '@/features/resource/types/spotOnForEboilers'
import { transformDataForDataGrid } from '@/features/resource/utils/spotOnForEboilers'
import { errorHandler } from '@/utils/errorHandler'

type SpotOnEboilerPowerLimitsFormEditProps = {
  powerLimitsExtraCriteria: ExtraCriteria
  resourceId: string
}

function SpotOnEboilerPowerLimitsFormEdit({
  powerLimitsExtraCriteria,
  resourceId,
}: Readonly<SpotOnEboilerPowerLimitsFormEditProps>) {
  const { t } = useTranslation()
  const { updatePowerLimits, isPending } = useUpdateSpotOnPowerLimitsMutation()

  const { pushAlert } = useAlertContext()

  const form = useForm({
    defaultValues: {
      extraCriteria: DEFAULT_EXTRA_CRITERIA_WEEKDAYS,
    },
  })

  useEffect(() => {
    if (powerLimitsExtraCriteria?.weekdays) {
      form.setValue('extraCriteria', powerLimitsExtraCriteria.weekdays)
    }
  }, [powerLimitsExtraCriteria])

  const handleExtraCriteriaChange = (params: GridRenderEditCellParams<ExtraCriteriaWeekday>) => (value: string) => {
    // An integer or a float number with up to two decimals
    const isValidPrice = /^\d+\.?\d{0,2}$/.test(value)
    if (value !== '' && !isValidPrice) return

    // We just accept values between 0 and 1
    const valueAsNumber = Number(value)
    if (valueAsNumber < 0 || valueAsNumber > 1) return

    const { id, field } = params
    const currentData = form.getValues('extraCriteria')

    // The API expects a value for every day of the week, but the power limit is the same for all days in a specific PTU.
    // Check the network charges update logic to see the difference. There, we update a specific day.
    const updatedData = currentData.map((row) => {
      row.periods[id].value = value

      return row
    })

    // Update form state
    form.setValue('extraCriteria', updatedData, { shouldDirty: true, shouldValidate: true })

    // Inform DataGrid of the change
    params.api.setEditCellValue({ id, field, value })
  }

  const extraCriteria = form.watch('extraCriteria')

  const rows = transformDataForDataGrid(extraCriteria)

  const columns: GridColDef[] = [
    { field: 'ptu', headerName: 'PTU', flex: 1 },
    {
      field: '1',
      headerName: t('common.value'),
      flex: 1,
      editable: true,
      renderCell: (params) => <DataGridEditableTextField readonlyField value={params.formattedValue} />,
      renderEditCell: (params) => (
        <DataGridEditableTextField
          selectValueOnFocus
          value={params.value}
          onBlur={() => {
            params.api.stopCellEditMode({ id: params.id, field: params.field })
          }}
          onChange={handleExtraCriteriaChange(params)}
        />
      ),
    },
  ]

  async function handleSubmit(data) {
    try {
      await updatePowerLimits({
        resourceId,
        extraCriteria: {
          ...powerLimitsExtraCriteria!,
          weekdays: data.extraCriteria,
        },
      })

      pushAlert({
        message: t('spot_on_for_eboilers.power_limits_form.updated_success_message'),
        severity: 'success',
      })
    } catch (err: unknown) {
      const error = errorHandler(err, t('spot_on_for_eboilers.power_limits_form.updated_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  if (!powerLimitsExtraCriteria) {
    return (
      <Box alignItems="center" display="flex" height="200px" justifyContent="center">
        <CircularProgress title="Loading" />
      </Box>
    )
  }

  return (
    <Grid container marginTop={5}>
      <Grid size={{ md: 6, sm: 12 }}>
        <FormProvider {...form}>
          <form noValidate onSubmit={form.handleSubmit(handleSubmit)}>
            <Stack gap={1} marginBottom={2}>
              <Stack flexDirection="row" justifyContent="space-between">
                <SpotOnEboilerDisplayedTimezone />
              </Stack>

              <CustomDataGrid
                disableColumnMenu
                disableColumnSorting
                hideFooter
                hideFooterPagination
                hideFooterRowCount
                aria-label={t('spot_on_for_eboilers.power_limits_form.table_title')}
                columns={columns}
                getRowHeight={() => 52}
                includeWrapper={false}
                rows={rows}
              />
            </Stack>
            <CustomButton disabled={isPending} type="submit" variant="contained">
              {t('common.button.save')}
            </CustomButton>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  )
}

export default SpotOnEboilerPowerLimitsFormEdit

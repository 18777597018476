import { useTranslation } from 'react-i18next'

import Page from '@/components/layouts/Page'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { useCustomerQuery } from '@/features/customer/hooks/useCustomerQuery'
import { useCustomerSettingsQuery } from '@/features/customer/hooks/useCustomerSettingsQuery'
import { CustomerRevenueView } from '@/features/customer/pages/components/CustomerRevenueView'

export const CustomerRevenue = () => {
  const { t } = useTranslation()

  const { loggedInUser } = useAuth()
  const customerUuid = loggedInUser?.allowedRoIds?.[0] ?? ''

  const { customer, isFetching: isFetchingCustomer } = useCustomerQuery(
    { uuid: customerUuid },
    {
      enabled: Boolean(customerUuid),
    },
  )

  const { customerSettings, isFetching: isFetchingCustomerSettings } = useCustomerSettingsQuery(
    { uuid: customerUuid!, location: customer?.location },
    { enabled: Boolean(customer?.uuid) && !isFetchingCustomer },
  )

  if (!customer || isFetchingCustomer || !customerSettings || isFetchingCustomerSettings) {
    return null
  }

  return (
    <Page
      pageHeaderProps={{
        pageTitle: t('customer_details.tabs.revenue.title'),
        pageTag: 'customer-revenue',
      }}
    >
      <CustomerRevenueView customer={customer} customerSettings={customerSettings} />
    </Page>
  )
}

import type { Bid, BidMetaData, OfferedAndForecastAndSuggestedPtu, PtuVolumes } from '@/features/bidding/types/bid'

export function bidFromOfferedAndForecastPtus(
  metaData: BidMetaData,
  offeredAndForecastPtus: OfferedAndForecastAndSuggestedPtu[],
): Bid {
  return {
    ...metaData,
    offeredBid: offeredAndForecastPtus.map((ptu) => ({
      ptu: ptu.ptu,
      volume: ptu.offeredVolume,
      ptuChunks: ptu.offeredPtuChunks,
    })),
  }
}

export function bidFromPtuVolumes(metaData: BidMetaData, ptus: PtuVolumes[]): Bid {
  return {
    ...metaData,
    offeredBid: ptus.map((ptu) => ({
      ptu: ptu.ptu,
      volume: ptu.offeredVolume,
    })),
    acceptedBid: ptus
      .filter((ptu) => !!ptu.acceptedVolume)
      .map((ptu) => ({
        ptu: ptu.ptu,
        volume: ptu.acceptedVolume!,
      })),
  }
}

import { Route, Routes } from 'react-router-dom'

import { DataGridSyncUrlManagerProvider } from '@/contexts/DataGridSyncUrlManagerContext'
import { BiddingContextProvider } from '@/features/bidding/contexts/BiddingContext'
import BiddingOverview from '@/features/bidding/pages/BiddingOverview'
import { BiddingTodoList } from '@/features/bidding/pages/BiddingTodoList'
import BidExports from '@/features/bidding/pages/BidExports'
import BidHistoriesView from '@/features/bidding/pages/BidHistoriesView'
import BidManualAccept from '@/features/bidding/pages/BidManualAccept'
import { BidManualAcceptSuccess } from '@/features/bidding/pages/BidManualAcceptSuccess'
import BidOfferCapacity from '@/features/bidding/pages/BidOfferCapacity'
import BidOfferConfirm from '@/features/bidding/pages/BidOfferConfirm'
import BidOfferConfirmPrice from '@/features/bidding/pages/BidOfferPriceConfirm'
import BidOfferPriceWrapper from '@/features/bidding/pages/BidOfferPriceWrapper'
import { BidOfferSuccess } from '@/features/bidding/pages/BidOfferSuccess'
import ImportAcceptedBid from '@/features/bidding/pages/ImportAcceptedBid'
import NavigateToError from '@/features/error/pages/NavigateToError'

import { deserializeGlobalFilterSearchParamsObject } from './utils/globalFilters'

export default function BiddingRoutes() {
  return (
    <BiddingContextProvider>
      <Routes>
        <Route
          index
          element={
            <DataGridSyncUrlManagerProvider
              deserializeGlobalFilterSearchParamsObject={deserializeGlobalFilterSearchParamsObject}
            >
              <BiddingOverview />
            </DataGridSyncUrlManagerProvider>
          }
          path="/"
        />
        <Route element={<BiddingTodoList />} path="/todo-list" />
        <Route element={<BidOfferCapacity />} path="/offer/create/capacity" />
        <Route element={<BidOfferPriceWrapper />} path="/offer/create/price" />
        <Route element={<BidOfferConfirm />} path="/offer/confirm/capacity" />
        <Route element={<BidOfferConfirmPrice />} path="/offer/confirm/price" />
        <Route element={<BidOfferSuccess />} path="/offer/success/:marketProgram"></Route>
        <Route element={<BidHistoriesView />} path="/view"></Route>
        <Route element={<BidManualAccept />} path="/manual-accept/:manualAcceptFlow"></Route>
        <Route element={<BidManualAcceptSuccess />} path="/manual-accept/:manualAcceptFlow/success"></Route>
        <Route element={<BidExports />} path="/exports"></Route>
        <Route element={<ImportAcceptedBid />} path="/import-accepted-bid"></Route>

        <Route element={<NavigateToError statusCode={404} />} path="*" />
      </Routes>
    </BiddingContextProvider>
  )
}

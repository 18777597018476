import type { ReactNode } from 'react'
import { useEffect } from 'react'

import PageAppBar from '@/components/layouts/PageAppBar'
import { usePageMetadata } from '@/components/layouts/PageMetadataContext'
import PageTitle from '@/components/layouts/PageTitle'
import { useAnalytics } from '@/features/googleAnalytics/hooks/useAnalytics'

type PageHeaderWithoutBreadcrumbsProps = {
  appBarContent?: ReactNode
  pageTitle: string
  pageTag?: string // used for Google Analytics
}

function PageHeaderWithoutBreadcrumbs({
  pageTitle,
  pageTag,
  appBarContent,
}: Readonly<PageHeaderWithoutBreadcrumbsProps>) {
  const { setPageTitle } = usePageMetadata()
  const { sendAnalyticsPageTagView } = useAnalytics()

  useEffect(() => {
    setPageTitle(pageTitle)
    sendAnalyticsPageTagView(pageTag)
  }, [])

  return (
    <>
      <PageTitle pageTitle={pageTitle} />
      <PageAppBar appBarContent={appBarContent} />
    </>
  )
}

export default PageHeaderWithoutBreadcrumbs

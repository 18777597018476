import AddIcon from '@mui/icons-material/Add'
import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

import SubmitButton from '@/features/bidding/components/SubmitButton'
import { useIsFetchingAnyBid } from '@/features/bidding/hooks/useIsFetchingAnyBid'
import { useIsSavingBids } from '@/features/bidding/hooks/useSaveBidsMutation'

type Props = {
  disabled?: boolean
  shouldShowConfirmation?: boolean
  onSubmit: () => void
  dialogProps: {
    title: string
    description: string
  }
}

const CreateBidButton: FC<Props> = ({ disabled, shouldShowConfirmation, onSubmit, dialogProps }) => {
  const { t } = useTranslation()
  const isFetchingAnyBid = useIsFetchingAnyBid()
  const isSavingAnyBids = useIsSavingBids()

  return (
    <SubmitButton
      buttonProps={{
        disabled: isFetchingAnyBid || isSavingAnyBids || disabled,
        startIcon: <AddIcon />,
        'data-testid': 'create-bid-button',
      }}
      dialogProps={dialogProps}
      shouldShowConfirmation={shouldShowConfirmation}
      onSubmit={onSubmit}
    >
      {t('bidding.create_bid.create')}
    </SubmitButton>
  )
}

export default CreateBidButton

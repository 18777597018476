import { FormControlLabel, FormGroup, Grid2 as Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CustomAutocomplete from '@/components/inputs/CustomAutocomplete'
import CustomSelectField from '@/components/inputs/CustomSelectField'
import TextFieldController from '@/components/inputs/TextFieldController'
import environment from '@/environment'
import { CustomerAddressInput } from '@/features/customer/components/companyInfo/CustomerAddressInput'
import type { Site } from '@/features/customer/types/site'
import determineTimezone from '@/features/customer/utils/determineTimezone'
import type { Partner } from '@/features/partner/types/partner'
import type { GpsLocation } from '@/types/gpsLocation'

type BasicInformationStepProps = {
  partners: Partner[]
}

function BasicInformationStep({ partners }: BasicInformationStepProps) {
  const { t } = useTranslation()

  const locationInfos = environment.getLocationsList()

  const { control, getValues, setValue } = useFormContext<Site>()

  const [availablePartners, setAvailablePartners] = useState<Partner[]>(partners)

  const [timezone, setTimezone] = useState<string>('')

  function handleOnLocationChange(event) {
    const selectedLocation = event.target.value
    setValue('location', selectedLocation)

    const selectedLocationCountryCode =
      locationInfos.find((locationInfo) => locationInfo.location === event.target.value)?.country ?? ''

    const timezone = determineTimezone(selectedLocationCountryCode)
    setTimezone(timezone)
    setValue('timezone', timezone)

    const selectedPartner = partners.find((partner) => partner.partnerCode === getValues().partnerCode)

    if (!selectedPartner || areDifferentCountries(selectedPartner?.countryCode, selectedLocationCountryCode)) {
      setValue('partnerCode', undefined)
      setAvailablePartners(partners.filter((partner) => partner.countryCode === selectedLocationCountryCode))
    }
  }

  function areDifferentCountries(partnerCountryCode?: string, locationCountryCode?: string) {
    return partnerCountryCode && locationCountryCode && partnerCountryCode !== locationCountryCode
  }

  function onChangeAddress(address: string, gpsLocation: GpsLocation) {
    setValue('address', address, { shouldTouch: true })
    if (gpsLocation) {
      setValue('coordinates', { latitude: gpsLocation.lat!, longitude: gpsLocation.lon! }, { shouldTouch: true })
    }
  }

  return (
    <Grid container gap={3}>
      <Grid size={{ md: 3, sm: 6 }}>
        <TextFieldController required label={t('sites.add_new.form.name.label')} name="name" />
      </Grid>
      <Grid size={{ md: 6, sm: 6 }} />
      <Grid size={{ md: 3, sm: 6 }}>
        <Controller
          control={control}
          name="partnerCode"
          render={({ field: { value, ref: fieldRef, ...field }, fieldState: { error, invalid } }) => (
            <CustomAutocomplete
              {...field}
              ref={fieldRef}
              fullWidth
              error={invalid}
              helperText={error?.message}
              label={t('common.partner') + ' *'}
              options={
                availablePartners?.map((partner) => ({
                  id: partner.partnerCode,
                  value: partner.partnerCode,
                  label: partner.name,
                })) ?? []
              }
              value={value ?? ''}
              onChange={(event, newValue) => {
                setValue('partnerCode', newValue)
              }}
            />
          )}
        />
      </Grid>
      <Grid size={{ md: 6, sm: 6 }} />
      <Grid size={{ md: 3, sm: 6 }}>
        <TextFieldController required label={t('sites.add_new.form.symbolic_name.label')} name="symbolicName" />
      </Grid>{' '}
      <Grid size={{ md: 6, sm: 6 }} />
      <Grid size={{ md: 3, sm: 6 }}>
        <CustomerAddressInput
          hiddenMap
          address={getValues('address')}
          gpsLocation={undefined}
          onChangeAddress={onChangeAddress}
        />
      </Grid>
      <Grid size={{ md: 6, sm: 6 }} />
      <Grid size={{ md: 3, sm: 6 }}>
        <Controller
          control={control}
          name="location"
          render={({ field: { value, ref: fieldRef, ...field }, fieldState: { error, invalid } }) => (
            <CustomSelectField
              {...field}
              ref={fieldRef}
              fullWidth
              required
              error={invalid}
              helperText={error?.message}
              label={t('common.location')}
              name="location"
              options={locationInfos.map((info) => ({
                id: info.location,
                value: info.location,
                label: info.location,
              }))}
              value={value ?? ''}
              onChange={handleOnLocationChange}
            />
          )}
        />
      </Grid>
      <Grid alignContent={'center'} size={{ md: 6, sm: 6 }}>
        <Typography> {timezone} </Typography>
      </Grid>
      <Grid alignContent={'center'} size={{ md: 4, sm: 6 }}>
        <Typography> {t('common.type')} </Typography>
        <FormGroup>
          <RadioGroup
            aria-label="siteType"
            name="siteType"
            sx={{ ml: 2 }}
            value={getValues().siteType ?? ''}
            onChange={(event) => {
              setValue('siteType', event.target.value)
            }}
          >
            <FormControlLabel control={<Radio />} label={t('sites.types.sawmill')} value="sawmill" />
            <FormControlLabel control={<Radio />} label={t('sites.types.greenhouse')} value="greenhouse" />
            <FormControlLabel control={<Radio />} label={t('sites.types.other')} value="other" />
          </RadioGroup>
        </FormGroup>
      </Grid>
    </Grid>
  )
}

export default BasicInformationStep

import { Stack } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import type { ComponentProps, ReactNode } from 'react'
import { createContext, useContext, useEffect, useState } from 'react'

import PageAppBar from '@/components/layouts/PageAppBar'

import PageHeader from './PageHeader'

type PageHeaderProps = ComponentProps<typeof PageHeader>

type PageProps = {
  pageHeaderProps?: PageHeaderProps
  children: ReactNode
  isLoading?: boolean
}

type PageContextType = {
  setAppBarContent: (content: ReactNode) => void
}

export const PageContext = createContext<PageContextType | undefined>(undefined)

//TODO: set GA4 page tag here instead of pageHeader. But for now alot of pages dont use this.
const Page = ({ pageHeaderProps, children, isLoading = false }: PageProps) => {
  const [appBarContent, setAppBarContent] = useState<ReactNode>(pageHeaderProps?.appBarContent)

  useEffect(() => {
    setAppBarContent(pageHeaderProps?.appBarContent || null)
  }, [pageHeaderProps?.appBarContent])

  if (isLoading) {
    return (
      <Stack sx={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flex: 1 }}>
        <CircularProgress title="Full page spinner" />
      </Stack>
    )
  }
  return (
    <PageContext.Provider value={{ setAppBarContent }}>
      {/*TODO: setting appBarContent to null is for backward compatibility. Remove this in the future.*/}
      {pageHeaderProps && <PageHeader {...pageHeaderProps} appBarContent={null} hiddenAppBar={true} />}
      {!pageHeaderProps?.hiddenAppBar && <PageAppBar appBarContent={appBarContent} />}
      {children}
    </PageContext.Provider>
  )
}

export const useAppBarContent = () => {
  const context = useContext(PageContext)
  if (!context) {
    throw new Error('useAppBarContent must be used within a PageContext')
  }
  return { setAppBarContent: context.setAppBarContent }
}

export default Page

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Stack } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import { CustomerSelector } from '@/features/customer/components/CustomerSelector'
import { AggregatedPowerMeasurement } from '@/features/customer/components/power_measurements/AggregatedPowerMeasurement'
import GreyAndWhiteCloud from '@/features/customer/components/power_measurements/GreyAndWhiteCloud'
import WhiteCloud from '@/features/customer/components/power_measurements/WhiteCloud'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { useCustomerDetails } from '@/features/customer/pages/CustomerDetails'

export const CustomerPowerMeasurements = () => {
  const { t, i18n } = useTranslation()

  const { customer, customerDetailsCommonBreadcrumbs, setPageConfig } = useCustomerDetails()
  const { customerUuid } = useParams()

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.power_measurements.title'),
      pageTag: 'customer-details-power-measurements',
      breadcrumbs: customerDetailsCommonBreadcrumbs,
      appBarContent: (
        <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <CustomerSelector customerUuid={customerUuid} />
          </Stack>
        </LocalizationProvider>
      ),
      activeTab: CustomerDetailsRouteInformation.POWER_MEASUREMENTS.navigationPath,
    })
  }, [])

  return (
    <MainContentContainer>
      <CustomTypography fontSize={20} variant="h4">
        {t('customer_measurements.power_measurements.title')}
      </CustomTypography>

      <Stack alignItems="center" direction="column" justifyContent="center" sx={{ width: '100%', height: '100%' }}>
        <WhiteCloud style={{ position: 'absolute', top: 450, left: 900 }} />
        <GreyAndWhiteCloud style={{ position: 'absolute', top: 400, right: 600 }} />

        <AggregatedPowerMeasurement customerUuid={customer.uuid!} location={customer.location!} />
        <Stack direction="row" spacing={1} sx={{ my: 2 }}>
          <InfoOutlinedIcon color={'info'} width={'20px'} />
          <CustomTypography variant="body1">
            {t('customer_measurements.power_measurements.information')}
          </CustomTypography>
        </Stack>
      </Stack>
    </MainContentContainer>
  )
}

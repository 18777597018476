import { DateTime } from 'luxon'

import { MARKET_TIMEZONE } from '@/features/bidding/constants'

export class MarketDate {
  private readonly internalDate: DateTime

  constructor(initialDate?: DateTime | string) {
    this.internalDate = this.initializeDateTime(initialDate).startOf('day')
  }

  getStartOfDay(): DateTime {
    return this.internalDate
  }

  getEndOfDay(): DateTime {
    return this.internalDate.endOf('day')
  }

  equals(marketDate: MarketDate) {
    return this.internalDate.equals(marketDate.internalDate)
  }

  valueOf() {
    return this.internalDate.valueOf()
  }

  toString(): string {
    return this.toISODate()
  }

  toISODate(): string {
    const date = this.internalDate.toISODate()
    if (date === null) {
      throw new Error('Invalid DateTime object')
    }
    return date
  }

  plus(duration: { days: number }): MarketDate {
    return new MarketDate(this.internalDate.plus(duration))
  }

  minus(duration: { days: number }): MarketDate {
    return new MarketDate(this.internalDate.minus(duration))
  }

  private initializeDateTime(initialDate?: DateTime | string): DateTime {
    if (!initialDate) {
      return DateTime.now().setZone(MARKET_TIMEZONE)
    }
    if (typeof initialDate === 'string') {
      return DateTime.fromISO(initialDate, { zone: MARKET_TIMEZONE })
    }
    return initialDate.setZone(MARKET_TIMEZONE)
  }
}

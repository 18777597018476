import { Box, CircularProgress, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material'
import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import CustomTypography from '@/components/dataDisplay/CustomTypography'
import CustomButton from '@/components/inputs/CustomButton'
import { TOMORROW } from '@/constants/dateTime'
import { useAlertContext } from '@/contexts/AlertContext'
import SpotOnEboilerApprovedByChip from '@/features/resource/components/SpotOnEboilerApprovedByChip'
import SpotOnEboilerDisplayedTimezone from '@/features/resource/components/SpotOnEboilerDisplayedTimezone'
import SpotOnEboilerScheduleTextarea from '@/features/resource/components/SpotOnEboilerScheduleTextarea'
import { useSpotOnEboilerScheduleProgram } from '@/features/resource/contexts/SpotOnEboilerScheduleProgramContext'
import { useApproveSpotOnScheduleMutation } from '@/features/resource/hooks/useApproveSpotOnScheduleMutation'
import {
  useInvalidateSpotOnScheduleQuery,
  useSpotOnScheduleQuery,
} from '@/features/resource/hooks/useSpotOnScheduleQuery'
import { useUpdateSpotOnScheduleMutation } from '@/features/resource/hooks/useUpdateSpotOnScheduleMutation'
import type {
  ApiScheduledResource,
  ResourceSteeringSchedulePeriod,
  Schedule,
} from '@/features/resource/types/spotOnForEboilers'
import { errorHandler } from '@/utils/errorHandler'

type SpotOnEboilerScheduleFormProps = {
  scheduledResource: ApiScheduledResource | null
}

function SpotOnEboilerScheduleForm({ scheduledResource }: Readonly<SpotOnEboilerScheduleFormProps>) {
  const { i18n, t } = useTranslation()
  const { pushAlert } = useAlertContext()

  const resourceId = scheduledResource?.resourceId ?? ''
  const steeringRangeMax = scheduledResource?.steeringRangeMax
  const { activeDay } = useParams()
  const { spotOnScheduleProgram } = useSpotOnEboilerScheduleProgram()

  const { isLoading, schedule } = useSpotOnScheduleQuery(
    { activeDay: activeDay!, resourceId },
    { enabled: Boolean(scheduledResource) },
  )
  const { updateSchedule, isPending: isPendingSave } = useUpdateSpotOnScheduleMutation()
  const { approveSchedule, isPending: isPendingApprove } = useApproveSpotOnScheduleMutation()

  const invalidateSpotOnScheduleQuery = useInvalidateSpotOnScheduleQuery()

  const form = useForm<Schedule>()

  useEffect(() => {
    if (schedule) {
      const sortedPeriods = schedule.periods
        ? schedule.periods.toSorted(
            (a, b) =>
              DateTime.fromISO(a.startAt, { zone: timeZone }).toMillis() -
              DateTime.fromISO(b.startAt, { zone: timeZone }).toMillis(),
          )
        : []

      form.setValue('periods', sortedPeriods)
    }
  }, [schedule])

  const timeZone = spotOnScheduleProgram?.timeZone ?? 'Europe/Helsinki'

  async function handleApprove() {
    if (!schedule) return

    try {
      await approveSchedule({
        activeDay: schedule.activeDay,
        resourceId: schedule.resourceId,
        whoIsApproving: 'trader',
      })

      pushAlert({
        message: t('spot_on_for_eboilers.schedule_create.approved_success_message'),
        severity: 'success',
      })

      invalidateSpotOnScheduleQuery()
    } catch (err: unknown) {
      const error = errorHandler(err, t('spot_on_for_eboilers.schedule_create.approved_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  function updateTargetConsumptionInWattsForPtu(index, status) {
    if (status === 'ON') {
      form.setValue(`periods.${index}.targetConsumptionInWatts`, steeringRangeMax ?? null)
    }
    if (status === 'AUTO') {
      form.setValue(`periods.${index}.targetConsumptionInWatts`, null)
    }
  }

  async function handleSave({ periods }) {
    if (!schedule) return

    try {
      await updateSchedule({
        activeDay: schedule.activeDay,
        resourceId: schedule.resourceId,
        newSchedule: { ...schedule, periods },
      })

      pushAlert({
        message: t('spot_on_for_eboilers.schedule_create.updated_success_message'),
        severity: 'success',
      })
    } catch (err: unknown) {
      const error = errorHandler(err, t('spot_on_for_eboilers.schedule_create.updated_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  const rows = form.watch('periods')
  const gridRows = rows ? [...rows] : []

  const columns: GridColDef[] = [
    {
      field: 'startAt',
      headerName: 'PTU',
      flex: 1,
      valueFormatter: (value: string) => {
        const startAt = DateTime.fromISO(value, { zone: timeZone }).toFormat('HH:mm')
        const endAt = DateTime.fromISO(value, { zone: timeZone }).plus({ hours: 1 }).toFormat('HH:mm')

        return `${startAt} - ${endAt}`
      },
    },
    {
      field: 'cost',
      headerName: t('spot_on_for_eboilers.schedule_create.table.cost'),
      flex: 1,
      valueFormatter: (value: string) => (value ? parseFloat(value).toFixed(2) : ''),
    },
    {
      field: 'status',
      headerName: t('common.status'),
      flex: 1,
      valueGetter: (_, schedulePeriod: ResourceSteeringSchedulePeriod) => {
        return schedulePeriod.targetConsumptionInWatts
      },
      renderCell: (params: GridRenderCellParams) => (
        <ToggleButtonGroup color="primary" size="small" value={params.row.targetConsumptionInWatts ? 'on' : 'auto'}>
          <ToggleButton
            key="on"
            value="on"
            onClick={() => {
              const index = form.getValues('periods').findIndex((period) => period.startAt === params.row.startAt)
              updateTargetConsumptionInWattsForPtu(index, 'ON')
            }}
          >
            {t('common.status_on')}
          </ToggleButton>
          <ToggleButton
            key="auto"
            value="auto"
            onClick={() => {
              const index = form.getValues('periods').findIndex((period) => period.startAt === params.row.startAt)
              updateTargetConsumptionInWattsForPtu(index, 'AUTO')
            }}
          >
            {t('common.status_auto')}
          </ToggleButton>
        </ToggleButtonGroup>
      ),
    },
  ]

  if (!scheduledResource || isLoading) {
    return (
      <Box alignItems="center" display="flex" height="200px" justifyContent="center">
        <CircularProgress title="Loading" />
      </Box>
    )
  }

  const isPending = isPendingApprove || isPendingSave
  const formValues = form.getValues()
  const hasChanges = schedule?.periods.some((period) => {
    const periodInTheForm = formValues.periods?.find((formPeriod) => formPeriod.startAt === period.startAt)
    return period.targetConsumptionInWatts !== periodInTheForm?.targetConsumptionInWatts
  })

  const { approvedByResourceOwnerAt, approvedByTraderAt, needsApprovalByResourceOwner, needsApprovalByTrader } =
    schedule as Schedule

  const scheduleAsText = gridRows?.map((row) => (row.targetConsumptionInWatts === null ? 'AUTO' : 'ON')).join('\n')

  function handleTextareaChange(e) {
    const newText = e.target.value.split('\n').filter((value) => value !== '')

    if (newText.length !== gridRows.length) {
      return pushAlert({
        message: t('spot_on_for_eboilers.schedule_create.import_status_as_text.length_mismatch', {
          expected: gridRows.length,
          given: newText.length,
        }),
        severity: 'error',
      })
    }

    newText.forEach((line, index) => {
      const formattedLine = line.trim().toUpperCase()
      if (formattedLine === 'ON' || formattedLine === 'AUTO') {
        updateTargetConsumptionInWattsForPtu(index, formattedLine)
      }
    })
  }

  return (
    <Box paddingTop={2}>
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(handleSave)}>
          <Stack gap={1}>
            <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
              <LocalizationProvider adapterLocale={i18n.language} dateAdapter={AdapterLuxon}>
                <DatePicker
                  disabled={true}
                  format="DD"
                  label={t('spot_on_for_eboilers.schedule_create.active_day')}
                  value={activeDay ? DateTime.fromISO(activeDay) : TOMORROW}
                />
              </LocalizationProvider>
              <SpotOnEboilerDisplayedTimezone />
            </Stack>

            <Stack direction="row" gap={4}>
              <CustomDataGrid
                disableColumnMenu
                disableColumnSorting
                hideFooter
                hideFooterPagination
                hideFooterRowCount
                aria-label={t('spot_on_for_eboilers.schedule_create.table_title')}
                columns={columns}
                getRowHeight={() => 52}
                getRowId={(row) => row.startAt}
                includeWrapper={false}
                rows={gridRows ?? []}
              />
              <Box mt="22px">
                <CustomTypography gutterBottom variant="h5">
                  {t('spot_on_for_eboilers.schedule_create.import_status_as_text.title')}
                </CustomTypography>
                <SpotOnEboilerScheduleTextarea value={scheduleAsText} onChange={handleTextareaChange} />
              </Box>
            </Stack>
          </Stack>

          <Box alignItems="center" display="flex" flexDirection="row" gap={1} height="100%" marginTop={2}>
            {needsApprovalByResourceOwner ? (
              <SpotOnEboilerApprovedByChip
                approvedAt={approvedByResourceOwnerAt}
                label={t('spot_on_for_eboilers.schedules.table.by_resource_owner')}
                title={t('spot_on_for_eboilers.schedules.table.approved_at', {
                  datetime: DateTime.fromISO(approvedByResourceOwnerAt ?? '', { zone: timeZone }).toFormat(
                    'dd LLL, yyyy - TT',
                  ),
                })}
              />
            ) : null}
            {needsApprovalByTrader ? (
              <SpotOnEboilerApprovedByChip
                approvedAt={approvedByTraderAt}
                label={t('spot_on_for_eboilers.schedules.table.by_trader')}
                title={t('spot_on_for_eboilers.schedules.table.approved_at', {
                  datetime: DateTime.fromISO(approvedByTraderAt ?? '', { zone: timeZone }).toFormat(
                    'dd LLL, yyyy - TT',
                  ),
                })}
              />
            ) : null}
          </Box>

          <Stack direction="row" gap={2} marginTop={4}>
            <CustomButton disabled={isPending || !hasChanges} type="submit" variant="contained">
              {t('spot_on_for_eboilers.schedule_create.save_changes_button')}
            </CustomButton>
            <CustomButton disabled={isPending} type="button" variant="outlined" onClick={handleApprove}>
              {t('spot_on_for_eboilers.schedule_create.approve_button')}
            </CustomButton>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  )
}

export default SpotOnEboilerScheduleForm

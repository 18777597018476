import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { GET_SPOT_ON_SCHEDULE_API_ID, getSchedule } from '@/features/resource/endpoints/scheduleBuilder'
import type { Schedule } from '@/features/resource/types/spotOnForEboilers'
import type { UseQueryOptions } from '@/types/queries'

type UseSpotOnScheduleQueryParams = {
  activeDay: string
  resourceId: string
}

export type UseSpotOnScheduleQueryResult = Omit<UseQueryResult, 'data'> & {
  schedule: Schedule | null
}

export function useSpotOnScheduleQuery(
  params: UseSpotOnScheduleQueryParams,
  options?: UseQueryOptions<Schedule | null>,
): UseSpotOnScheduleQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_SPOT_ON_SCHEDULE_API_ID, params],
    queryFn: () => getSchedule(params.resourceId, params.activeDay),
  })

  return {
    schedule: data ?? null,
    ...queryResult,
  }
}

export function useInvalidateSpotOnScheduleQuery() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_SPOT_ON_SCHEDULE_API_ID] })
}

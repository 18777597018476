import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { formatCapacity, formatRevenue } from '@/features/customer/utils/revenueUtils/formatRevenueData'
import NumericWidget from '@/features/dashboard/components/NumericWidget'
import { NumericWidgetSkeleton } from '@/features/dashboard/components/NumericWidgetSkeleton'

type RevenuesSummaryCardsProps = {
  capacityForYear: number
  soldCapacityForYear: number
  revenuesForYear: number
  selectedYear: string
  isLoadingRevenueTimeSeries: boolean
}

export const RevenueSummaryCards = ({
  capacityForYear,
  soldCapacityForYear,
  revenuesForYear,
  selectedYear,
  isLoadingRevenueTimeSeries,
}: RevenuesSummaryCardsProps) => {
  const { t } = useTranslation()

  if (isLoadingRevenueTimeSeries) {
    return (
      <Stack direction="row" gap={2} justifyContent="space-between">
        <NumericWidgetSkeleton />
        <NumericWidgetSkeleton />
        <NumericWidgetSkeleton />
      </Stack>
    )
  }

  return (
    <Stack direction="row" gap={2} justifyContent="space-between">
      <NumericWidget
        disabled
        title={t('customer_revenue.revenue_in') + ` ${selectedYear}`}
        value={formatRevenue(t, revenuesForYear)}
        valueFontSize={24}
      />
      <NumericWidget
        disabled
        title={t('customer_revenue.capacity_in') + ` ${selectedYear}`}
        value={formatCapacity(t, capacityForYear)}
        valueFontSize={24}
      />
      <NumericWidget
        disabled
        title={t('customer_revenue.sold_in') + ` ${selectedYear}`}
        value={formatCapacity(t, soldCapacityForYear)}
        valueFontSize={24}
      />
    </Stack>
  )
}

import { BidType } from '@/features/bidding/constants'
import { useBidHistoriesPageQuery } from '@/features/bidding/hooks/useBidHistoriesPageQuery'
import type { GroupedBidParams } from '@/features/bidding/utils/groupedBidParams/buildBiddingLinks'

type Response = {
  isLoading: boolean
  hasBidHistories: boolean
}

export function useHasBidHistories(
  country: CountryIdentifier,
  bidType: BidType,
  groupParams?: GroupedBidParams | null,
): Response {
  if (!groupParams) return { isLoading: false, hasBidHistories: false }

  const { isLoading, bidHistoriesPage } = useBidHistoriesPageQuery(
    {
      pagination: { pageSize: 1, page: 0 }, // page size 0 would be even better but backend doesn't support it
      sorting: [],
      filter: {
        items: groupParams
          ? [
              { field: 'portfolio', operator: 'equals', value: groupParams.portfolioCode },
              { field: 'marketProgram', operator: 'equals', value: groupParams.marketProgram },
              { field: 'deliveryDay', operator: 'equals', value: groupParams.deliveryDay },
              { field: 'bidType', operator: 'equals', value: bidType },
            ]
          : [],
      },
      countryFilter: country,
    },
    { enabled: !!groupParams },
  )
  return {
    isLoading,
    hasBidHistories: (bidHistoriesPage?.totalBidHistories ?? 0) > 0,
  }
}

export function useHasCapacityBidHistories(
  country: CountryIdentifier,
  groupParams?: GroupedBidParams | null,
): Response {
  return useHasBidHistories(country, BidType.CAPACITY, groupParams)
}

export function useHasEnergyBidHistories(country: CountryIdentifier, groupParams?: GroupedBidParams | null): Response {
  return useHasBidHistories(country, BidType.ENERGY, groupParams)
}

import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { GET_SPOT_ON_NETWORK_CHARGES_API_ID, getNetworkCharges } from '@/features/resource/endpoints/scheduleBuilder'
import type { ExtraCriteria } from '@/features/resource/types/spotOnForEboilers'
import type { UseQueryOptions } from '@/types/queries'

type UseSpotOnNetworkChargesQueryParams = {
  scheduleProgramId: string
  customerId: string
}

export type UseSpotOnNetworkChargesQueryResult = Omit<UseQueryResult, 'data'> & {
  extraCriteria: ExtraCriteria[]
}

export function useSpotOnNetworkChargesQuery(
  params: UseSpotOnNetworkChargesQueryParams,
  options?: UseQueryOptions<ExtraCriteria[] | null>,
): UseSpotOnNetworkChargesQueryResult {
  const { data, ...queryResult } = useQuery({
    ...options,
    queryKey: [GET_SPOT_ON_NETWORK_CHARGES_API_ID, params],
    queryFn: () => getNetworkCharges(params.scheduleProgramId, params.customerId),
  })

  return {
    extraCriteria: data ?? [],
    ...queryResult,
  }
}

export function useInvalidateSpotOnNetworkChargesQuery() {
  const queryClient = useQueryClient()

  return () => queryClient.invalidateQueries({ queryKey: [GET_SPOT_ON_NETWORK_CHARGES_API_ID] })
}

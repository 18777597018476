import { Card, CardContent, Grid2 as Grid, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import CustomChip from '@/components/dataDisplay/CustomChip'
import CustomTypography from '@/components/dataDisplay/CustomTypography'
import { USER_ROLES } from '@/constants/userRoles'
import environment from '@/environment'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { usePermissions } from '@/features/authorization/contexts/PermissionsContext'
import CustomerCountWidget from '@/features/dashboard/components/CustomerCountWidget'
import GridFrequencyWidget from '@/features/dashboard/components/GridFrequencyWidget'
import UserCountWidget from '@/features/dashboard/components/UserCountWidget'
import { useLocationForDashboardData } from '@/features/dashboard/hooks/useLocationForDashboardData'
import { hasGridFrequency } from '@/features/dashboard/utils/gridFrequency'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'

const FEATURE_TOGGLES_TO_DISPLAY = [
  'TEST_TOGGLE_FOR_FRONTEND',
  'FLEXPORTAL_BIDDING_PAGE_ENABLED',
  'FLEXPORTAL_BIDDING_SUBMIT_ENABLED',
  'FLEXPORTAL_RESOURCES_PAGE_ENABLED',
]

function Dashboard() {
  const { isEnabled } = useFeatureToggle()
  const { t } = useTranslation()
  const { permissions } = usePermissions()

  const { loggedInUser } = useAuth()
  const hasCustomerUserRole = loggedInUser?.role === USER_ROLES.RESOURCE_OWNERS.value
  const location = useLocationForDashboardData()

  if (hasCustomerUserRole) {
    if (permissions.has('powerMeasurements')) {
      return <Navigate replace to={'/customers/power-measurements'} />
    }
    return <Navigate replace to={'/customers/availability'} />
  }

  return (
    <Stack direction={'column'} sx={{ mt: 2 }}>
      <CustomTypography gutterBottom variant="h5">
        {t('common.period.current')}
      </CustomTypography>
      <Grid container columnSpacing={2} direction="row" justifyContent="flex-start" rowGap={2}>
        {hasGridFrequency(location) && (
          <Grid size={{ xs: 2 }}>
            <GridFrequencyWidget location={location} />
          </Grid>
        )}
        <Grid size={{ xs: 2 }}>
          <CustomerCountWidget />
        </Grid>
        <Grid size={{ xs: 2 }}>
          <UserCountWidget />
        </Grid>
      </Grid>
      {environment.isDevelopmentMode && (
        <>
          <CustomTypography gutterBottom sx={{ mt: 4 }} variant="h5">
            Development
          </CustomTypography>
          <Grid container columnSpacing={2} direction="row" justifyContent="flex-start" rowGap={2}>
            {FEATURE_TOGGLES_TO_DISPLAY.map((feature) => (
              <Grid key={feature} size={{ xs: 3 }}>
                <Card>
                  <CardContent>
                    <CustomTypography variant={'h4'}>Toggle status</CustomTypography>
                    <Stack
                      alignContent={'center'}
                      alignItems={'center'}
                      direction={'column'}
                      spacing={1}
                      sx={{ mt: 2, mb: 1 }}
                    >
                      <CustomTypography
                        align={'center'}
                        fontFamily={'Quicksand, Roboto, sans-serif'}
                        fontSize={12}
                        letterSpacing={0}
                      >
                        {feature}
                      </CustomTypography>
                      <CustomChip
                        color={isEnabled(feature) ? 'success' : 'default'}
                        label={isEnabled(feature) ? 'Enabled' : 'Disabled'}
                        size={'small'}
                        variant="outlined"
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Stack>
  )
}

export default Dashboard

import { Outlet, Route, Routes } from 'react-router-dom'

import { USER_ROLES } from '@/constants/userRoles'
import { DataGridSyncUrlManagerProvider } from '@/contexts/DataGridSyncUrlManagerContext'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import NavigateToError from '@/features/error/pages/NavigateToError'
import { useFeatureToggle } from '@/features/featureToggle/contexts/FeatureToggleContext'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { SpotOnEboilerScheduleProgramProvider } from '@/features/resource/contexts/SpotOnEboilerScheduleProgramContext'
import BatterySteeringPage from '@/features/resource/pages/BatterySteeringPage'
import ResourceDetailsPage from '@/features/resource/pages/ResourceDetailsPage'
import ResourceGeneralPage from '@/features/resource/pages/ResourceGeneralPage'
import ResourceManualSteeringPage from '@/features/resource/pages/ResourceManualSteeringPage'
import ResourceSchedulerPage from '@/features/resource/pages/ResourceSchedulerPage'
import ResourcesIndex from '@/features/resource/pages/ResourcesIndex'
import ResourceSpotOnEboilerDetailsPage from '@/features/resource/pages/ResourceSpotOnEboilerDetailsPage'
import ResourceSteeringPage from '@/features/resource/pages/ResourceSteeringPage'
import ResourceSupportToolboxPage from '@/features/resource/pages/ResourceSupportToolboxPage'
import SpotOnEboilerConfigurationPage from '@/features/resource/pages/SpotOnEboilerConfigurationPage'
import SpotOnEboilerNetworkChargesPage from '@/features/resource/pages/SpotOnEboilerNetworkChargesPage'
import SpotOnEboilerPowerLimitsPage from '@/features/resource/pages/SpotOnEboilerPowerLimitsPage'
import SpotOnEboilerResourcesPage from '@/features/resource/pages/SpotOnEboilerResourcesPage'
import SpotOnEboilerScheduleDetailsPage from '@/features/resource/pages/SpotOnEboilerScheduleDetailsPage'
import SpotOnEboilerSchedulesPage from '@/features/resource/pages/SpotOnEboilerSchedulesPage'
import { deserializeGlobalFilterSearchParamsObject } from '@/features/resource/utils/globalFilters'

export default function ResourcesRoutes() {
  const { isEnabled } = useFeatureToggle()
  const { loggedInUser } = useAuth()
  return (
    <Routes>
      <Route
        element={
          <DataGridSyncUrlManagerProvider
            deserializeGlobalFilterSearchParamsObject={deserializeGlobalFilterSearchParamsObject}
          >
            <ResourcesIndex />
          </DataGridSyncUrlManagerProvider>
        }
        path={ResourceRouteInformation.INDEX.routePath}
      />

      <Route element={<ResourceDetailsPage />} path={ResourceRouteInformation.RESOURCE_DETAILS.routePath}>
        <Route index element={<ResourceGeneralPage />} />
        <Route element={<ResourceGeneralPage />} path={ResourceRouteInformation.RESOURCE_GENERAL.routePath} />
        <Route element={<ResourceSteeringPage />} path={ResourceRouteInformation.RESOURCE_STEERING.routePath} />
        <Route element={<BatterySteeringPage />} path={ResourceRouteInformation.RESOURCE_BATTERY_STEERING.routePath} />
        <Route
          element={<ResourceManualSteeringPage />}
          path={ResourceRouteInformation.RESOURCE_MANUAL_STEERING.routePath}
        />
        {loggedInUser?.role == USER_ROLES.ADMINISTRATORS.value && (
          <Route
            element={<ResourceSupportToolboxPage />}
            path={ResourceRouteInformation.RESOURCE_SUPPORT_TOOLBOX.routePath}
          />
        )}
        <Route element={<NavigateToError statusCode={404} />} path="*" />
      </Route>

      {isEnabled('FLEXPORTAL_RESOURCE_SCHEDULER_PAGE_ENABLED') && (
        <Route element={<ResourceSchedulerPage />} path={ResourceRouteInformation.RESOURCE_SCHEDULER.routePath} />
      )}

      {isEnabled('FLEXPORTAL_SPOT_ON_EBOILER_ENABLED') && (
        <Route
          element={
            <SpotOnEboilerScheduleProgramProvider>
              <Outlet />
            </SpotOnEboilerScheduleProgramProvider>
          }
        >
          <Route
            element={<SpotOnEboilerResourcesPage />}
            path={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCES.routePath}
          />

          <Route
            element={<ResourceSpotOnEboilerDetailsPage />}
            path={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS.routePath}
          >
            <Route index element={<SpotOnEboilerConfigurationPage />} />

            <Route
              element={<SpotOnEboilerConfigurationPage />}
              path={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_CONFIGURATION.routePath}
            />

            <Route
              element={<SpotOnEboilerPowerLimitsPage />}
              path={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_POWER_LIMITS.routePath}
            />

            <Route
              element={<SpotOnEboilerNetworkChargesPage />}
              path={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_NETWORK_CHARGES.routePath}
            />

            <Route
              element={<SpotOnEboilerSchedulesPage />}
              path={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_SCHEDULES.routePath}
            />

            <Route
              element={<SpotOnEboilerScheduleDetailsPage isCreateMode={true} />}
              path={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_SCHEDULE_CREATE.routePath}
            />

            <Route
              element={<SpotOnEboilerScheduleDetailsPage isCreateMode={false} />}
              path={ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_SCHEDULE_DETAILS.routePath}
            />

            <Route element={<NavigateToError statusCode={404} />} path="*" />
          </Route>
        </Route>
      )}

      <Route element={<NavigateToError statusCode={404} />} path="*" />
    </Routes>
  )
}

import { useQuery, useQueryClient } from '@tanstack/react-query'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useAuth } from '@/features/authentication/contexts/AuthContext'
import { GET_FEATURE_TOGGLES_API_ID, getFeatureToggles } from '@/features/featureToggle/endpoints/featureToggle'
import type { FeatureToggle } from '@/features/featureToggle/types/featureToggle'

export type State = {
  isEnabled: (feature: string) => boolean
  useFeatureWithContext: (
    feature: string,
    context: Record<string, string>,
  ) => {
    isFeatureEnabled: boolean
    isLoading: boolean
  }
  isLoading: boolean
  error: Error | null
}

export type FeatureToggleProviderProps = {
  children: ReactNode
  featureToggles?: FeatureToggle[]
}

const initialState: State = {
  isEnabled: () => false,
  useFeatureWithContext: () => ({ isFeatureEnabled: false, isLoading: false }),
  isLoading: false,
  error: null,
}

const FeatureToggleContext = createContext<State>(initialState)

function FeatureToggleProvider(props: Readonly<FeatureToggleProviderProps>) {
  const location = useLocation()
  const queryClient = useQueryClient()

  const { isLogged } = useAuth()
  const { data, isLoading, error } = useQuery({
    queryKey: [GET_FEATURE_TOGGLES_API_ID],
    queryFn: () => getFeatureToggles(),
    enabled: isLogged,
  })

  useEffect(() => {
    // We decided to call the endpoint to get feature toggles every time we have change the pathname
    // (every time we change the page). The feature toggles will not be updated when query parameters change, as it could
    // produce a lot of requests to the server.

    // TASK: https://sympower.atlassian.net/browse/TECH-1212
    queryClient.invalidateQueries({ queryKey: [GET_FEATURE_TOGGLES_API_ID] })
  }, [location.pathname])

  function isEnabled(featureToCheck: string, fallbackValue?: boolean): boolean {
    if (!data) return fallbackValue || false

    const found = data.toggles.find((feature) => feature.name === featureToCheck)

    return found ? found.enabled : false
  }

  const useFeatureWithContext = (feature: string, context: Record<string, string>) => {
    const { data = false, isLoading } = useQuery<boolean>({
      queryFn: async () => {
        const response = await getFeatureToggles(context)
        const found = response.toggles.find((f) => f.name === feature)
        return found?.enabled ?? false
      },
      queryKey: [GET_FEATURE_TOGGLES_API_ID, feature, JSON.stringify(context)],
    })

    return { isFeatureEnabled: data, isLoading }
  }

  const value = useMemo(
    () => ({
      error,
      isEnabled,
      isLoading,
      useFeatureWithContext,
    }),
    [error, isEnabled, isLoading, useFeatureWithContext],
  )

  return <FeatureToggleContext.Provider value={value} {...props} />
}

const useFeatureToggle = () => {
  return useContext(FeatureToggleContext)
}
export { FeatureToggleProvider, useFeatureToggle }

import { useIsMutating, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useAlertContext } from '@/contexts/AlertContext'
import { importAcceptedBid } from '@/features/bidding/endpoints/bids'

const IMPORT_ACCEPTED_BID_API_ID = 'IMPORT_ACCEPTED_BID'

export function useImportAcceptedBidMutation() {
  const { t } = useTranslation()
  const { pushAlert } = useAlertContext()

  const { mutateAsync, ...mutationResult } = useMutation({
    mutationFn: (tsvRows: string) => importAcceptedBid(tsvRows),
    mutationKey: [IMPORT_ACCEPTED_BID_API_ID],
    onError: () => {
      pushAlert({
        message: t('bidding.import_accepted_bid.sending_failed.text'),
        severity: 'error',
        title: t('bidding.import_accepted_bid.sending_failed.title'),
      })
    },
  })

  return {
    importAcceptedBid: mutateAsync,
    ...mutationResult,
  }
}

export const useIsImportingAcceptedBid = (): boolean => useIsMutating({ mutationKey: [IMPORT_ACCEPTED_BID_API_ID] }) > 0

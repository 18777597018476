import DeleteIcon from '@mui/icons-material/Delete'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ConfirmDialogActions from '@/components/feedback/ConfirmDialogActions'
import CustomDialog from '@/components/feedback/CustomDialog'
import CustomIconButton from '@/components/inputs/CustomIconButton'
import { useAlertContext } from '@/contexts/AlertContext'
import { useInvalidateActivationGroupResourcesQuery } from '@/features/activationGroup/hooks/useActivationGroupResourcesQuery'
import { useUpdateActivationGroupMutation } from '@/features/activationGroup/hooks/useUpdateActivationGroupMutation'
import type {
  ActivationGroup,
  ApiActivationGroupResource,
  ApiUpdateActivationGroup,
  ApiVolumeRangeUnit,
} from '@/features/activationGroup/types'
import { errorHandler } from '@/utils/errorHandler'

type UnassignResourceConfirmationButtonProps = {
  unassignedResource: ApiActivationGroupResource
  activationGroup: ActivationGroup
  activationGroupResources: ApiActivationGroupResource[]
}

function serializeUnAssignResourceFromActivationGroup(
  resourceUuid: string,
  activationGroup: ActivationGroup,
  activationGroupResources: ApiActivationGroupResource[],
): ApiUpdateActivationGroup {
  return {
    code: activationGroup.code,
    biddableVolumeRange: activationGroup.biddableVolumeRange
      ? {
          unit: activationGroup.biddableVolumeRange.unit.toUpperCase() as ApiVolumeRangeUnit,
          min: activationGroup.biddableVolumeRange.min,
          max: activationGroup.biddableVolumeRange.max,
        }
      : null,
    state: activationGroup.state ?? 'LIVE',
    resources: [
      ...activationGroupResources
        .filter(({ uuid }) => uuid !== resourceUuid)
        .map(({ uuid, activationPriority, isUsedForMinCapacityPrequalification }) => ({
          uuid,
          activationPriority,
          isUsedForMinCapacityPrequalification: isUsedForMinCapacityPrequalification,
        })),
    ],
  }
}

const UnassignResourceConfirmationButton = ({
  unassignedResource,
  activationGroupResources,
  activationGroup,
}: UnassignResourceConfirmationButtonProps) => {
  const { t } = useTranslation()
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const { updateActivationGroup, isPending } = useUpdateActivationGroupMutation({
    activationGroupUuid: activationGroup.uuid,
  })
  const invalidateActivationGroupResourcesQuery = useInvalidateActivationGroupResourcesQuery({
    activationGroupUuid: activationGroup.uuid,
  })
  const { pushAlert } = useAlertContext()

  const dialogTitle = t('activation_groups.delete_resource_dialog.title')
  const dialogDescription = t('activation_groups.delete_resource_dialog.description', {
    resourceName: unassignedResource.name,
    activationGroupName: activationGroup.code,
  })

  async function handleUnassignResource() {
    try {
      await updateActivationGroup(
        serializeUnAssignResourceFromActivationGroup(
          unassignedResource.uuid,
          activationGroup,
          activationGroupResources,
        ),
      )
      await invalidateActivationGroupResourcesQuery()

      pushAlert({
        message: t('activation_groups.unassign_resource_success_message'),
        severity: 'success',
      })
      setOpenConfirmationDialog(false)
    } catch (err) {
      const error = errorHandler(err, t('activation_groups.unassign_resource_generic_error_message'))

      pushAlert({
        message: error.message,
        severity: 'error',
      })
    }
  }

  function handleOpenConfirmationDialog() {
    setOpenConfirmationDialog(true)
  }

  function handleCloseConfirmationDDialog() {
    setOpenConfirmationDialog(false)
  }

  return (
    <>
      <CustomIconButton
        Icon={DeleteIcon}
        aria-label={t('activation_groups.resources.table.unassign_button_label', {
          resourceName: unassignedResource.name,
        })}
        onClick={handleOpenConfirmationDialog}
      />

      <CustomDialog
        aria-describedby={dialogDescription}
        aria-labelledby={dialogTitle}
        open={openConfirmationDialog}
        title={dialogTitle}
        onClose={handleCloseConfirmationDDialog}
      >
        <DialogContent>
          <DialogContentText>{dialogDescription}</DialogContentText>
        </DialogContent>

        <ConfirmDialogActions
          disabled={isPending}
          onCancel={handleCloseConfirmationDDialog}
          onConfirm={handleUnassignResource}
        />
      </CustomDialog>
    </>
  )
}

export default UnassignResourceConfirmationButton

import { useEffect } from 'react'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import FullPageSpinner from '@/components/feedback/FullPageSpinner'
import MainContentContainer from '@/components/layouts/MainContentContainer'
import SpotOnEboilerPowerLimitsFormCreate from '@/features/resource/components/SpotOnEboilerPowerLimitsFormCreate'
import SpotOnEboilerPowerLimitsFormEdit from '@/features/resource/components/SpotOnEboilerPowerLimitsFormEdit'
import { ResourceRouteInformation } from '@/features/resource/constants'
import { useSpotOnPowerLimitsQuery } from '@/features/resource/hooks/useSpotOnPowerLimitsQuery'
import { useResourceSpotOnEboilerDetails } from '@/features/resource/pages/ResourceSpotOnEboilerDetailsPage'

function SpotOnEboilerPowerLimitsPage() {
  const { resource, setActivePage } = useResourceSpotOnEboilerDetails()
  const { extraCriteria, isLoading } = useSpotOnPowerLimitsQuery(
    {
      resourceId: resource?.resourceID ?? '',
    },
    { enabled: Boolean(resource) },
  )

  useEffect(() => {
    setActivePage(ResourceRouteInformation.SPOT_ON_EBOILER_RESOURCE_DETAILS_POWER_LIMITS.routePath)
  }, [])

  if (isLoading) return <FullPageSpinner />

  const powerLimitsExtraCriteria = extraCriteria.find((criteria) => criteria.type === 'max_target_consumption_fraction')

  return (
    <MainContentContainer sx={{ marginBottom: 3 }}>
      <CustomTypography gutterBottom variant="h3">
        {resource?.resourceName}
      </CustomTypography>
      {powerLimitsExtraCriteria ? (
        <SpotOnEboilerPowerLimitsFormEdit
          powerLimitsExtraCriteria={powerLimitsExtraCriteria}
          resourceId={resource?.resourceID ?? ''}
        />
      ) : (
        <SpotOnEboilerPowerLimitsFormCreate resourceId={resource?.resourceID ?? ''} />
      )}
    </MainContentContainer>
  )
}

export default SpotOnEboilerPowerLimitsPage

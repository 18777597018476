import { Stack } from '@mui/material'
import type { GridRenderCellParams, GridRowParams } from '@mui/x-data-grid'
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import CustomTypography from '@/components/dataDisplay/CustomTypography'
import DataGridDetailPanelToggle from '@/components/dataDisplay/DataGridDetailPanelToggle'
import LifecycleStageChip from '@/features/resource/components/LifecycleStageChip'
import ResourceEstimatedPower from '@/features/resource/components/ResourceEstimatedPower'
import ResourceGridToolbar from '@/features/resource/components/ResourceGridToolbar'
import ResourceSteeringTarget from '@/features/resource/components/ResourceSteeringTarget'
import ResourceTypeChip from '@/features/resource/components/ResourceTypeChip'
import SteerabilityStatusChip from '@/features/resource/components/SteerabilityStatusChip'
import SteeringStatusChip from '@/features/resource/components/SteeringStatusChip'
import { LIFECYCLE_STAGES, ResourceRouteInformation } from '@/features/resource/constants'
import type { Sort, SortDirection, SortProperty } from '@/features/resource/endpoints/resources'
import { useResourcesWithPollingQuery } from '@/features/resource/hooks/useResourcesWithPollingQuery'
import type {
  Resource,
  ResourceGlobalFilters,
  ResourceGridActions,
  ResourceGridState,
  ResourcesGridFilters,
} from '@/features/resource/types'

export type ResourceGridProps = {
  globalFilters: ResourceGlobalFilters
  gridState: ResourceGridState
  gridDispatch: React.Dispatch<ResourceGridActions>
  title: string
}

type ResourceRenderCell = GridRenderCellParams<Resource>

const ResourceGrid = ({ title, globalFilters, gridState, gridDispatch }: ResourceGridProps) => {
  const { t } = useTranslation()
  const resourceSort: Sort | null =
    gridState.sort.length > 0
      ? { property: gridState.sort[0].field as SortProperty, direction: gridState.sort[0].sort as SortDirection }
      : null

  const { resources, meta, isLoading } = useResourcesWithPollingQuery({
    filters: gridState.filters.items.reduce((acc, item) => {
      return { ...acc, [item.field]: item.value }
    }, {} as ResourcesGridFilters),
    searchKey: gridState.filters.quickFilterValues?.join(' '),
    pagination: gridState.pagination,
    sort: resourceSort || undefined,
    globalFilters,
  })

  return (
    <CustomDataGrid
      clickableRows={{
        navigateTo: ({ row }: GridRowParams<Resource>) =>
          ResourceRouteInformation.RESOURCE_DETAILS.navigationPath(row.resourceID),
      }}
      columns={[
        {
          field: 'resourceType',
          headerName: t('resources.table.header.type'),
          flex: 1,
          renderCell: ({ row }: ResourceRenderCell) => (
            <Stack
              sx={{
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <ResourceTypeChip resourceType={row.resourceType ?? 'UNKNOWN'} />
            </Stack>
          ),
          sortable: false,
        },
        {
          field: 'resourceName',
          headerName: t('resources.table.header.name'),
          flex: 2,
        },
        {
          field: 'customerName',
          headerName: t('resources.table.header.customer'),
          flex: 2,
        },
        {
          field: 'marketProgram',
          headerName: t('resources.table.header.market_program'),
          flex: 2,
          renderCell: ({ row }: ResourceRenderCell) => (
            <CustomTypography sx={{ lineHeight: 'inherit' }} variant="body1">
              {row.marketPrograms.map((marketProgram) => t(`common.market_program.${marketProgram}`)).join(', ')}
            </CustomTypography>
          ),
          sortable: false,
        },
        {
          field: 'estimatedPower',
          headerName: t('resources.table.header.estimated_power'),
          flex: 2,
          renderCell: ({ row }: ResourceRenderCell) => {
            return <ResourceEstimatedPower resource={row} />
          },
        },
        {
          field: 'lifecycleStage',
          headerName: t('resources.table.header.lifecycle_stage'),
          flex: 2,
          renderCell: ({ row: { lifecycleStage } }: ResourceRenderCell) => {
            return lifecycleStage ? <LifecycleStageChip lifecycleStage={lifecycleStage} /> : null
          },
          sortable: false,
        },
        {
          field: 'steerabilityStatus',
          headerName: t('resources.table.header.steerability_status'),
          flex: 2,
          renderCell: ({ row }: ResourceRenderCell) =>
            row.lifecycleStage !== 'DECOMMISSIONED' ? (
              <SteerabilityStatusChip isRestricted={row.isRestricted} status={row.steerabilityStatus ?? row.status} />
            ) : null,
          sortable: false,
        },
        {
          field: 'steeringStatus',
          headerName: t('resources.table.header.steering_status'),
          flex: 2,
          renderCell: ({ row: { steeringStatus, lifecycleStage } }: ResourceRenderCell) =>
            lifecycleStage !== 'DECOMMISSIONED' ? <SteeringStatusChip status={steeringStatus} /> : null,
          sortable: false,
        },
        {
          field: 'currentSteeringTarget',
          headerName: t('resources.table.header.steering_target'),
          flex: 2,
          renderCell: ({ row: { steeringData } }: ResourceRenderCell) => (
            <ResourceSteeringTarget currentSteeringTarget={steeringData?.currentSteeringTarget} />
          ),
        },
        {
          ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
          renderCell: (params) => <DataGridDetailPanelToggle params={params} />,
          sortable: false,
          flex: 0.75,
        },
      ]}
      filterMode="server"
      filterModel={gridState.filters}
      getRowId={(row: Resource) => row.resourceID}
      isLoading={isLoading}
      paginationMode="server"
      paginationModel={gridState.pagination}
      rowCount={meta?.totalResources ?? 0}
      rows={resources}
      slotProps={{
        toolbar: {
          lifecycleStages: LIFECYCLE_STAGES,
        },
      }}
      slots={{
        toolbar: ResourceGridToolbar,
      }}
      sortModel={gridState.sort}
      sortingMode="server"
      title={title}
      onFilterModelChange={(newFilters) => {
        gridDispatch({ type: 'setFilter', payload: newFilters })
      }}
      onPaginationModelChange={(newPagination) => {
        gridDispatch({ type: 'setPagination', payload: newPagination })
      }}
      onSortModelChange={(newSort) => {
        gridDispatch({ type: 'setSort', payload: newSort })
      }}
    />
  )
}

export default ResourceGrid

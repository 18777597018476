import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { Chip } from '@mui/material'
import type { ChipProps } from '@mui/material/Chip'
import { useTranslation } from 'react-i18next'

import type { ResourceSteeringStatus } from '@/features/resource/types'

interface SteeringStatusChipProps {
  status?: ResourceSteeringStatus | null
}

const SteeringStatusChip = ({ status }: SteeringStatusChipProps) => {
  const { t } = useTranslation()

  if (!status) {
    return (
      <Chip
        color="default"
        icon={<ErrorOutlineIcon titleAccess={t('component.resource_status_chip.unknown')} />}
        label={t('component.resource_status_chip.unknown')}
        sx={{ textTransform: 'uppercase' }}
        variant="outlined"
      />
    )
  }

  const getChipProps = (status: ResourceSteeringStatus): Partial<ChipProps> => {
    switch (status) {
      case 'STEERED':
        return {
          color: 'primary',
          icon: <PlayCircleOutlineIcon titleAccess={t('component.resource_status_chip.steered_icon')} />,
          label: t('component.resource_status_chip.steered'),
        }
      case 'STANDBY':
        return {
          color: 'secondary',
          icon: <PauseCircleOutlineIcon titleAccess={t('component.resource_status_chip.standby_icon')} />,
          label: t('component.resource_status_chip.standby'),
        }
      default:
        return {
          color: 'default',
          icon: <HelpOutlineIcon titleAccess={t('component.resource_status_chip.unknown')} />,
          label: t('component.resource_status_chip.unknown'),
        }
    }
  }

  const props = getChipProps(status)

  return <Chip sx={{ textTransform: 'uppercase' }} variant="outlined" {...props} />
}

export default SteeringStatusChip

import type { TFunction } from 'i18next'
import { z } from 'zod'

import validatePhone from '@/utils/phone'

export const getSiteFormSchema = (t: TFunction) =>
  z.object({
    name: z.string().trim().min(1, t('sites.add_new.form.name.error')),
    partnerCode: z
      .string()
      .trim()
      .min(1, t('sites.add_new.form.partner_code.error'))
      .nullable()
      .refine((val) => val !== null, t('sites.add_new.form.partner_code.error')),
    symbolicName: z.string().trim().min(1, t('sites.add_new.form.symbolic_name.error')),
    address: z.string().optional(),
    location: z.string().trim().min(2, t('sites.add_new.form.location.error')),
    siteType: z.string().optional(),
    contacts: z.array(
      z.object({
        name: z
          .string()
          .trim()
          .min(1, t('customer_details.tabs.contact_info.contacts.name_required.error'))
          .nullable()
          .refine((val) => val !== null, t('customer_details.tabs.contact_info.contacts.name_required.error')),
        phone: z
          .string()
          .refine((val) => validatePhone(val), t('customer_details.tabs.contact_info.phone_invalid.error'))
          .optional(),
        email: z
          .string()
          .trim()
          .email(t('customer_details.tabs.contact_info.email_invalid.error'))
          .or(z.literal(''))
          .optional(),
        comment: z.string().optional(),
      }),
    ),
  })

import type { GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid'
import type { TFunction } from 'i18next'
import type { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import CustomDataGrid from '@/components/dataDisplay/CustomDataGrid'
import DataGridEditableTextField from '@/components/dataDisplay/DataGridEditableTextField'
import { WATTS_IN_MW } from '@/constants/units'
import OfferedBidPtusDataGridToolbar from '@/features/bidding/components/capacity/offer/OfferedBidPtusDataGridToolbar'
import MinMaxBiddableVolumesTooltip from '@/features/bidding/components/MinMaxBiddableVolumesTooltip'
import { MAX_PTUS_PER_PAGE } from '@/features/bidding/constants'
import type { BidMetaData, BulkFillMode, OfferedAndForecastAndSuggestedPtu } from '@/features/bidding/types/bid'
import { convertToRoundedMw, round } from '@/features/bidding/utils/calculations/convertToRoundedMw'
import { isValueStackingSupported } from '@/features/bidding/utils/isValueStackingSupported'
import { isCapacityValid } from '@/features/bidding/utils/validation/isCapacityValid'

type OfferedBidPtusDataGridProps = {
  ptus: OfferedAndForecastAndSuggestedPtu[]
  processRowUpdate: (
    newPtu: OfferedAndForecastAndSuggestedPtu,
    oldPtu: OfferedAndForecastAndSuggestedPtu,
  ) => Promise<OfferedAndForecastAndSuggestedPtu>
  bidMetaData: BidMetaData
  loading?: boolean
  onFillAll: (bulkMode: BulkFillMode, bulkFillValue: number | undefined) => void
  saveDraftCurrentValues: () => void
}

const OfferedBidPtusDataGrid = ({
  ptus,
  processRowUpdate,
  loading,
  bidMetaData,
  onFillAll,
  saveDraftCurrentValues,
}: Readonly<OfferedBidPtusDataGridProps>) => {
  const { t } = useTranslation()

  return (
    <CustomDataGrid
      hideFooter
      columnVisibilityModel={{
        'ptu.suggestedBids': isValueStackingSupported(bidMetaData.marketProgram) || false,
      }}
      columns={getColumns(t)}
      getRowHeight={() => 52}
      getRowId={(row) => row.ptu.start.toString()}
      initialState={{ pagination: { paginationModel: { pageSize: MAX_PTUS_PER_PAGE, page: 0 } } }}
      isLoading={loading}
      processRowUpdate={processRowUpdate}
      rows={ptus ?? []}
      slotProps={{ toolbar: { onFillAll, saveDraftCurrentValues, deliveryDay: bidMetaData.deliveryDay } }}
      slots={{ toolbar: OfferedBidPtusDataGridToolbar }}
      title={t('bidding.create_bid.new_bid', { version: bidMetaData.version })}
      titleExtraInfo={
        bidMetaData.activationGroupUuid ? (
          <MinMaxBiddableVolumesTooltip activationGroupUuid={bidMetaData.activationGroupUuid} />
        ) : undefined
      }
    />
  )
}

const getColumns = (t: TFunction<'translation'>) => [
  {
    field: 'ptu.start',
    headerName: t('bidding.create_bid.header.ptu'),
    flex: 1,
    valueFormatter: (value: DateTime) => value.toFormat('HH:mm'),
    valueGetter: (_, offeredAndForecastAndSuggestedPtu: OfferedAndForecastAndSuggestedPtu) =>
      offeredAndForecastAndSuggestedPtu.ptu.start,
  },
  {
    field: 'ptu.forecastedWatts',
    headerName: t('bidding.create_bid.header.forecast'),
    valueFormatter: (value: number | null) => `${value ? convertToRoundedMw(value) : '-'} MW`,
    valueGetter: (_, offeredAndForecastAndSuggestedPtu: OfferedAndForecastAndSuggestedPtu) =>
      offeredAndForecastAndSuggestedPtu.forecastedVolume?.quantity ?? null,
    flex: 1,
  },
  {
    field: 'ptu.suggestedBids',
    headerName: t('bidding.create_bid.header.suggested'),
    editable: false,
    valueFormatter: (value: number | null) => `${value ? convertToRoundedMw(value) : '-'} MW`,
    valueGetter: (_, offeredAndForecastAndSuggestedPtu: OfferedAndForecastAndSuggestedPtu) =>
      offeredAndForecastAndSuggestedPtu.suggestedVolume?.quantity ?? null,
    flex: 1,
  },
  {
    field: 'ptu.offeredWatts',
    headerName: t('bidding.create_bid.header.offer'),
    editable: true,
    valueFormatter: (value: number) => `${value ?? '-'} MW`,
    valueGetter: (_, offeredAndForecastAndSuggestedPtu: OfferedAndForecastAndSuggestedPtu) =>
      convertToRoundedMw(offeredAndForecastAndSuggestedPtu.offeredVolume.quantity),
    valueSetter: (
      value: number,
      offeredAndForecastAndSuggestedPtu: OfferedAndForecastAndSuggestedPtu,
    ): OfferedAndForecastAndSuggestedPtu => ({
      ...offeredAndForecastAndSuggestedPtu,
      offeredVolume: { quantity: value ? round(value * WATTS_IN_MW) : 0, unit: 'WATTS' },
    }),
    flex: 1,
    renderCell: (params: GridRenderCellParams<OfferedAndForecastAndSuggestedPtu>) => (
      <DataGridEditableTextField readonlyField value={params.formattedValue} />
    ),
    renderEditCell: (params: GridRenderEditCellParams<OfferedAndForecastAndSuggestedPtu>) => (
      <DataGridEditableTextField
        value={params.value}
        onBlur={() => {
          params.api.stopCellEditMode({ id: params.id, field: params.field })
        }}
        onChange={onOfferChange(params)}
      />
    ),
  },
]

const onOfferChange =
  (params: GridRenderEditCellParams<OfferedAndForecastAndSuggestedPtu>) => (value: string | undefined) => {
    if (value && !isCapacityValid(value)) {
      return
    }

    params.api.setEditCellValue({ ...params, value })
  }

export default OfferedBidPtusDataGrid

import type { UseMutationResult } from '@tanstack/react-query'
import { useMutation } from '@tanstack/react-query'

import { createNetworkCharges } from '@/features/resource/endpoints/scheduleBuilder'
import type { ApiCreateNetworkCharges, ExtraCriteria } from '@/features/resource/types/spotOnForEboilers'
import type { UseMutationOptions } from '@/types/queries'

export type UseCreateSpotOnNetworkChargesMutationResult = Omit<
  UseMutationResult<ExtraCriteria, Error, ApiCreateNetworkCharges>,
  'mutateAsync'
> & {
  createNetworkCharges: UseMutationResult<ExtraCriteria, Error, ApiCreateNetworkCharges>['mutateAsync']
}

export function useCreateSpotOnNetworkChargesMutation(
  options?: UseMutationOptions<ExtraCriteria, ApiCreateNetworkCharges>,
): UseCreateSpotOnNetworkChargesMutationResult {
  const { mutateAsync, ...mutationResult } = useMutation({
    ...options,
    mutationFn: (variables: ApiCreateNetworkCharges) => createNetworkCharges(variables),
  })

  return { createNetworkCharges: mutateAsync, ...mutationResult }
}

import { zodResolver } from '@hookform/resolvers/zod'
import { Close, SaveOutlined } from '@mui/icons-material'
import { Box, Stack } from '@mui/material'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CustomButton from '@/components/inputs/CustomButton'
import CustomAccordion from '@/components/layouts/CustomAccordion'
import { DEFAULT_INTERFACE_LANGUAGE } from '@/constants/interfaceLanguages'
import { useAlertContext } from '@/contexts/AlertContext'
import { useAuth } from '@/features/authentication/contexts/AuthContext'
import UserSecurityAccordion from '@/features/user/components/form/UserSecurityAccordion'
import UserProfileAccountSection from '@/features/user/components/UserProfileAccountSection'
import UserProfileSecuritySection from '@/features/user/components/UserProfileSecuritySection'
import { DEFAULT_MFA_SETTINGS } from '@/features/user/constants/mfa'
import { updateMfa, updatePersonalInfo } from '@/features/user/endpoints/users'
import { useInvalidateUserQuery, useUserQuery } from '@/features/user/hooks/useUserQuery'
import { getUserPersonalInfoFormSchema } from '@/features/user/schemas'
import type { UserPersonalInfo } from '@/features/user/types/userPersonalInfo'
import { getNewPayloadToUpdateMfaSettings } from '@/features/user/utils/mfa'
import { errorHandler } from '@/utils/errorHandler'

export const UserProfile = () => {
  const { loggedInUser, refreshSession } = useAuth()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userId = loggedInUser?.id as string
  const { user } = useUserQuery({ id: userId }, { enabled: loggedInUser !== null })
  const invalidateUserQuery = useInvalidateUserQuery()

  const { pushAlert } = useAlertContext()

  const form = useForm<UserPersonalInfo>({
    mode: 'onBlur',
    defaultValues: {
      givenName: user?.givenName,
      familyName: user?.familyName,
      email: user?.email,
      phone: user?.phone ?? '',
      uiLanguage: user?.uiLanguage ?? DEFAULT_INTERFACE_LANGUAGE.value,
      mfaSettings: user?.mfaSettings ?? DEFAULT_MFA_SETTINGS,
    },
    resolver: zodResolver(getUserPersonalInfoFormSchema(t)),
  })

  useWatch({
    control: form.control,
  })

  if (!user) return null

  const onSubmit = async () => {
    if (!user.id) {
      return
    }

    form.setValue('numeralLanguage', form.getValues().uiLanguage) // FIXME: reconsider what to do with numeralLanguage, for now it is the same as uiLanguage
    try {
      const personalInfoPayload = form.getValues()
      const mfaSettingsPayload = personalInfoPayload.mfaSettings ?? DEFAULT_MFA_SETTINGS

      // We don't need the mfaSettings information to update the personal info. The mfaSettings update is a different API.
      delete personalInfoPayload.mfaSettings

      await updatePersonalInfo(user.id, personalInfoPayload)

      // In case the user changed its mfaSettings, we need to call the API to update this information.
      if (JSON.stringify(user.mfaSettings) !== JSON.stringify(mfaSettingsPayload)) {
        await updateMfa(user.id, getNewPayloadToUpdateMfaSettings(mfaSettingsPayload))
      }

      refreshSession()
      await invalidateUserQuery()

      if (loggedInUser) {
        loggedInUser.uiLanguage = personalInfoPayload.uiLanguage
      }

      navigate(-1)
    } catch (err) {
      const error = errorHandler(err, t('user_profile.errors.update_profile_error'))

      pushAlert({
        severity: 'error',
        message: error.message,
      })
    }
  }

  const onCancel = () => {
    navigate(-1)
  }

  return (
    <Box sx={{ marginY: 3 }}>
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <CustomAccordion defaultExpanded title={t('user_form.form.title_user_account')}>
            <UserProfileAccountSection user={user} />
          </CustomAccordion>

          <UserSecurityAccordion preferredOption={user?.mfaSettings?.preferredOption ?? null}>
            <UserProfileSecuritySection form={form} user={user} />
          </UserSecurityAccordion>
          <Stack direction="row" mt={2} spacing={2}>
            <CustomButton
              color="primary"
              startIcon={<SaveOutlined />}
              type="submit"
              variant="contained"
              onSubmit={onSubmit}
            >
              {t('common.button.save')}
            </CustomButton>
            <CustomButton color="primary" startIcon={<Close />} type="reset" variant="outlined" onClick={onCancel}>
              {t('common.button.cancel')}
            </CustomButton>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  )
}

import { useTranslation } from 'react-i18next'

import CustomTypography from '@/components/dataDisplay/CustomTypography'
import { useSpotOnEboilerScheduleProgram } from '@/features/resource/contexts/SpotOnEboilerScheduleProgramContext'

function SpotOnEboilerDisplayedTimezone() {
  const { t } = useTranslation()
  const { spotOnScheduleProgram } = useSpotOnEboilerScheduleProgram()

  const timeZone = spotOnScheduleProgram?.timeZone ?? 'Europe/Helsinki'

  return (
    <CustomTypography color="textSecondary" variant="body2">
      {t('spot_on_for_eboilers.components.displayed_timezone.text', { timezone: timeZone })}
    </CustomTypography>
  )
}

export default SpotOnEboilerDisplayedTimezone

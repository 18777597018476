import type { ActivationGroup, ActivationGroupUuid } from '@/features/activationGroup/types'
import { getActivationGroupByUuid } from '@/features/activationGroup/utils/activationGroups'
import type { BidOverviewRowItem } from '@/features/bidding/components/overview/BidsOverviewDataGrid'
import type { Status } from '@/features/bidding/constants'
import type { BidOverviewGroup } from '@/features/bidding/types/bidOverview'
import { getBidAverageAccepted, getBidAverageOffered } from '@/features/bidding/utils/calculations/getBidAverage'
import { getBidTotalAccepted, getBidTotalOffered } from '@/features/bidding/utils/calculations/getBidTotal'
import { getLatestBid } from '@/features/bidding/utils/getLatestBid'

export const buildBidOverviewRowItem = (
  bidOverviewGroup: BidOverviewGroup,
  activationGroups: ActivationGroup[],
): BidOverviewRowItem => {
  // using capacityBids only for now, energy bids will be supported later (PLANFLEX-174)
  const activationGroupUuids: ActivationGroupUuid[] = Object.keys(bidOverviewGroup.capacityBids)
  const bids = Object.values(bidOverviewGroup.capacityBids)

  const averageOfferedCapacity =
    bids.reduce((total, bid) => total + getBidAverageOffered(bid.offeredBid), 0) / bids.length
  const averageAcceptedCapacity =
    bids.reduce((total, bid) => total + (bid.acceptedBid ? getBidAverageAccepted(bid.acceptedBid) : 0), 0) / bids.length

  const totalOfferedCapacity = bids.reduce((total, bid) => total + getBidTotalOffered(bid.offeredBid), 0)
  const totalAcceptedCapacity = bids.reduce(
    (total, bid) => total + (bid.acceptedBid ? getBidTotalAccepted(bid.acceptedBid) : 0),
    0,
  )

  const uniqueStatuses: Status[] = [...new Set(bids.map((bid) => bid.status))]
  const latestBid = getLatestBid(bids)
  return {
    portfolio: bidOverviewGroup.portfolio,
    marketProgram: bidOverviewGroup.marketProgram,
    deliveryDay: bidOverviewGroup.deliveryDay,
    createdAt: latestBid.createdAt,
    uniqueStatuses: uniqueStatuses,
    activationGroupCodes: activationGroupUuids.map((uuid) => getActivationGroupByUuid(activationGroups, uuid)!.code),
    averageOfferedCapacity: averageOfferedCapacity,
    averageAcceptedCapacity: averageAcceptedCapacity,
    totalOfferedCapacity: totalOfferedCapacity,
    totalAcceptedCapacity: totalAcceptedCapacity,
  }
}

import { zodResolver } from '@hookform/resolvers/zod'
import { SaveOutlined } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { Button, Divider, Stack } from '@mui/material'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ZONED_TODAY } from '@/constants/dateTime'
import { useAlertContext } from '@/contexts/AlertContext'
import { AVAILABILITY_STATUSES } from '@/features/availability/types/availabilityStatus'
import type { Restriction } from '@/features/availability/types/restriction'
import { AvailabilityWireSelectionSection } from '@/features/customer/components/availability/form/AvailabilityWireSelectionSection'
import { DeleteRestrictionConfirmationDialog } from '@/features/customer/components/availability/restriction/DeleteRestrictionConfirmationDialog'
import { RestrictionConfigurationInfoSection } from '@/features/customer/components/availability/restriction/form/RestrictionConfigurationInfoSection'
import { RestrictionNonRepeatingDatesSection } from '@/features/customer/components/availability/restriction/form/RestrictionNonRepeatingDatesSection'
import { RestrictionRepeatingDatesSection } from '@/features/customer/components/availability/restriction/form/RestrictionRepeatingDatesSection'
import { RestrictionStatusSection } from '@/features/customer/components/availability/restriction/form/RestrictionStatusSection'
import { RestrictionTypeSection } from '@/features/customer/components/availability/restriction/form/RestrictionTypeSection'
import { useCreateRestrictionMutation } from '@/features/customer/hooks/useCreateRestrictionMutation'
import { useDeleteRestrictionMutation } from '@/features/customer/hooks/useDeleteRestrictionMutation'
import { useEditRestrictionMutation } from '@/features/customer/hooks/useEditRestrictionMutation'
import type { MarketProgram } from '@/features/customer/types/marketProgram'
import type { CreateRestrictionRequest, EditRestrictionRequest } from '@/features/customer/types/restriction'
import type { RestrictionForm } from '@/features/customer/types/restrictionForm'
import { getRestrictionFormSchema } from '@/features/customer/types/restrictionForm'
import { useAnalytics } from '@/features/googleAnalytics/hooks/useAnalytics'
import { convertToTimeZoneDateTime } from '@/utils/time'

interface RestrictionDetailsFormProps {
  onClose: () => void
  marketProgram: MarketProgram
  customerTimeZone: string
  customerLocation: string
  customerUuid: string
  restriction?: Restriction
}

export const RestrictionDetailsForm = (props: RestrictionDetailsFormProps) => {
  const { t } = useTranslation()
  const { onClose } = props
  const { pushAlert } = useAlertContext()
  const { sendAnalyticsEvent } = useAnalytics()

  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false)

  const isDeleteAllowed = props.restriction?.status === AVAILABILITY_STATUSES.waiting.value

  const form = useForm<RestrictionForm>({
    mode: 'onBlur',
    defaultValues: {
      id: props.restriction?.id ?? undefined,
      type: props.restriction?.type ?? 'non-repeating',
      startDate: props.restriction?.startDate
        ? convertToTimeZoneDateTime(props.customerTimeZone, props.restriction.startDate.toString())
        : ZONED_TODAY(props.customerTimeZone).plus({ days: 1 }),
      endDate: props.restriction?.endDate
        ? convertToTimeZoneDateTime(props.customerTimeZone, props.restriction.endDate.toString())
        : ZONED_TODAY(props.customerTimeZone).plus({ days: 2 }),
      startTime: props.restriction?.startTime
        ? DateTime.fromISO(props.restriction.startTime, { zone: props.customerTimeZone })
        : ZONED_TODAY(props.customerTimeZone).startOf('day').startOf('minute'),
      endTime: props.restriction?.endTime
        ? DateTime.fromISO(props.restriction.endTime, { zone: props.customerTimeZone })
        : ZONED_TODAY(props.customerTimeZone).endOf('day').startOf('minute'),
      allDay: false,
      selectedResources: props.restriction?.relayWires?.map((resource) => resource.id) ?? [],
      location: props.customerLocation,
      status: props.restriction?.status ?? undefined,
    },
    resolver: zodResolver(getRestrictionFormSchema(t)),
  })

  useWatch({
    control: form.control,
  })

  const { createRestriction } = useCreateRestrictionMutation()
  const { editRestriction } = useEditRestrictionMutation()
  const { deleteRestriction } = useDeleteRestrictionMutation()

  function convertToRequest(formValues: RestrictionForm): CreateRestrictionRequest | EditRestrictionRequest | null {
    const serviceId = props.marketProgram.id

    if (!serviceId) {
      return null
    }

    const startDateString = formValues.startDate.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false })
    const endDateString = formValues.endDate.toUTC().toISO({ suppressMilliseconds: true, includeOffset: false })

    if (!startDateString || !endDateString) {
      return null
    }

    return {
      id: formValues.id,
      type: formValues.type,
      serviceId: serviceId,
      startDate: startDateString,
      endDate: endDateString,
      startTime: formValues.startTime.toFormat('HH:mm'),
      endTime: formValues.endTime.toFormat('HH:mm'),
      relayWires: formValues.selectedResources.map((id) => ({ id })),
      location: props.customerLocation,
      status: formValues.status ?? undefined,
    }
  }

  const handleSubmit = async () => {
    const restrictionRequest = convertToRequest(form.getValues())

    if (!restrictionRequest) {
      form.trigger() // Trigger validation to show errors
      return
    }

    if (props.restriction) {
      await editRestriction({
        customerUuid: props.customerUuid,
        restriction: restrictionRequest as EditRestrictionRequest,
      })
      pushAlert({
        message: t('customer_details.tabs.availability.unavailability_form.edit_dialog.success.message'),
        severity: 'success',
      })
    } else {
      await createRestriction({
        customerUuid: props.customerUuid,
        restriction: restrictionRequest,
      }).then(() => {
        sendAnalyticsEvent({
          name: 'availability_created',
          dimensions: {
            customer_uuid: props.customerUuid,
            availability_type: 'unavailability',
            market_program: props.marketProgram.type,
          },
        })
      })
      pushAlert({
        message: t('customer_details.tabs.availability.unavailability_form.create_dialog.success.message'),
        severity: 'success',
      })
    }
    handleClose()
  }

  const handleClose = () => {
    onClose()
    form.reset()
  }

  const handleDelete = async () => {
    setDeleteConfirmationDialogOpen(false)
    if (props.restriction?.id && isDeleteAllowed) {
      await deleteRestriction({
        customerUuid: props.customerUuid,
        restrictionId: props.restriction.id,
        location: props.customerLocation,
      })
      pushAlert({
        message: t('customer_details.tabs.availability.unavailability_form.delete_dialog.success.message'),
        severity: 'success',
      })
    }
    handleClose()
  }

  const confirmDeleteOperation = () => {
    setDeleteConfirmationDialogOpen(true)
  }

  return (
    <>
      <Stack spacing={2}>
        <FormProvider {...form}>
          <RestrictionTypeSection />
          {form.watch('type') === 'non-repeating' ? (
            <RestrictionNonRepeatingDatesSection timezone={props.customerTimeZone} />
          ) : (
            <RestrictionRepeatingDatesSection timezone={props.customerTimeZone} />
          )}
          <AvailabilityWireSelectionSection customerUuid={props.customerUuid} marketProgram={props.marketProgram} />
          <Divider style={{ marginBottom: '16px', marginTop: '16px' }} />
          <RestrictionStatusSection />
          <RestrictionConfigurationInfoSection customerTimeZone={props.customerTimeZone} />
        </FormProvider>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Stack direction="row" spacing={1}>
            <Button startIcon={<SaveOutlined />} variant={'contained'} onClick={form.handleSubmit(handleSubmit)}>
              {t('common.button.save')}
            </Button>
            <Button startIcon={<CloseIcon />} variant={'outlined'} onClick={handleClose}>
              {t('common.button.cancel')}
            </Button>
          </Stack>
          {isDeleteAllowed && (
            <Button
              color="error"
              startIcon={<DeleteOutlinedIcon />}
              variant={'outlined'}
              onClick={confirmDeleteOperation}
            >
              {t('common.button.delete')}
            </Button>
          )}
        </Stack>
      </Stack>
      <DeleteRestrictionConfirmationDialog
        open={deleteConfirmationDialogOpen}
        onClose={() => setDeleteConfirmationDialogOpen(false)}
        onConfirm={handleDelete}
      />
    </>
  )
}

import { capitalize, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import CustomButton from '@/components/inputs/CustomButton'
import type { ControlPort, ResourceSteeringRange, ResourceSteeringRangeEntry } from '@/features/resource/types'
import { convertToKilo } from '@/features/resource/utils/convertToKilo'
import { UNIT_SYMBOLS } from '@/utils/powerEnergyTransformations'

export type ResourceSteeringRangeDetailsProps = {
  isMultistepResource: boolean
  controlPorts: ControlPort[]
  resourceSteeringRange: ResourceSteeringRange
  onEdit: () => void
}

export type SteeringRangeEntryType = 'max' | 'min' | 'step'

const SteeringRangeEntryRow = ({
  steeringRangeEntry,
  entryType,
}: {
  steeringRangeEntry: ResourceSteeringRangeEntry
  entryType: SteeringRangeEntryType
}) => {
  const { t } = useTranslation()

  return (
    <TableRow key={steeringRangeEntry.id}>
      <TableCell>{t(`resources.steering.steering_ranges.table.entryType.${entryType}`)}</TableCell>
      <TableCell>
        {steeringRangeEntry.valueType ? capitalize(steeringRangeEntry.valueType.toLowerCase()) : '-'}
      </TableCell>
      <TableCell>{`${convertToKilo(steeringRangeEntry.value) ?? '-'} ${UNIT_SYMBOLS.kilowatts}`}</TableCell>
      <TableCell>{steeringRangeEntry.minSecondsOnThisLevel ?? '-'}</TableCell>
      <TableCell>{steeringRangeEntry.maxSecondsOnThisLevel ?? '-'}</TableCell>
    </TableRow>
  )
}

const SteeringRangeDetails = ({
  isMultistepResource,
  controlPorts,
  resourceSteeringRange,
  onEdit,
}: ResourceSteeringRangeDetailsProps) => {
  const { t } = useTranslation()
  const columns = useMemo(() => {
    return [
      {
        field: 'set',
        headerName: t('resources.steering.steering_ranges.table.set'),
        flex: 1,
      },
      {
        field: 'valueType',
        headerName: t('resources.steering.steering_ranges.table.value_type'),
        flex: 1,
      },
      {
        field: 'value',
        headerName: t('resources.steering.steering_ranges.table.value'),
        flex: 1,
      },
      {
        field: 'minTimeLevel',
        headerName: t('resources.steering.steering_ranges.table.min_time_on_level'),
        flex: 1,
      },
      {
        field: 'maxTimeLevel',
        headerName: t('resources.steering.steering_ranges.table.max_time_on_level'),
        flex: 1,
      },
    ]
  }, [t])

  const multistepConfigurationColumns = useMemo(() => {
    return [
      {
        field: 'stepValue',
        headerName: t('resources.steering.steering_ranges.multisteps_table.step_value'),
        flex: 1,
      },
      {
        field: 'controlPortId',
        headerName: t('resources.steering.steering_ranges.multisteps_table.control_port'),
        flex: 1,
      },
    ]
  }, [t])

  const displayControlPortName = (controlPortUuid: string | null) => {
    const controlPort = controlPorts.find((cp) => cp.controlPortID === controlPortUuid)
    return controlPort ? `${controlPort.ports} | ${controlPort.controlPortID} | ${controlPort.controlBoxType}` : '-'
  }

  return (
    <Stack alignItems={'flex-start'} direction={'column'} sx={{ gap: 4, paddingX: 7, paddingY: 4 }}>
      <Table aria-label={t('resources.steering.steering_ranges.details')} size="small" sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column.field}>{column.headerName}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <SteeringRangeEntryRow
            entryType={'max'}
            steeringRangeEntry={resourceSteeringRange.max}
          ></SteeringRangeEntryRow>
          <SteeringRangeEntryRow
            entryType={'min'}
            steeringRangeEntry={resourceSteeringRange.min}
          ></SteeringRangeEntryRow>
          <SteeringRangeEntryRow
            entryType={'step'}
            steeringRangeEntry={resourceSteeringRange.step}
          ></SteeringRangeEntryRow>
        </TableBody>
      </Table>
      {isMultistepResource && (
        <Table aria-label={t('resources.steering.multistep.table.label')} size="small" sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              {multistepConfigurationColumns.map((column) => (
                <TableCell key={column.field}>{column.headerName}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {resourceSteeringRange.multistepMappings?.map((entry) => (
              <TableRow key={entry.stepValue}>
                <TableCell>{`${convertToKilo(entry.stepValue)} ${UNIT_SYMBOLS.kilowatts}`}</TableCell>
                <TableCell>{displayControlPortName(entry.controlPortId)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
      <CustomButton variant="text" onClick={onEdit}>
        {t('resources.steering.steering_ranges.edit')}
      </CustomButton>
    </Stack>
  )
}

export default SteeringRangeDetails

import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { CustomerActivationDetailsView } from '@/features/activation/components/CustomerActivationDetailsView'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { useCustomerDetails } from '@/features/customer/pages/CustomerDetails'
import NavigateToError from '@/features/error/pages/NavigateToError'

export const CustomerDetailsActivationDetails = () => {
  const { customer, customerSettings, customerDetailsCommonBreadcrumbs, setPageConfig } = useCustomerDetails()
  const { t } = useTranslation()
  const { activationId } = useParams()

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.activation_details'),
      pageTag: 'customer-details-activation-details',
      breadcrumbs: [
        ...customerDetailsCommonBreadcrumbs,
        {
          text: t('customer_details.tabs.activations'),
          to: `/customers/${customer.uuid}/activations`,
        },
      ],
      activeTab: CustomerDetailsRouteInformation.ACTIVATIONS.navigationPath,
    })
  }, [])

  if (!activationId) {
    return <NavigateToError statusCode={404} />
  }

  return (
    <CustomerActivationDetailsView
      activationId={activationId}
      customer={customer}
      timeZone={customerSettings.localization.timeZone}
    />
  )
}

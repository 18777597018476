import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomerActivationsView } from '@/features/activation/components/CustomerActivationsView'
import { CustomerDetailsRouteInformation } from '@/features/customer/pages/constants'
import { useCustomerDetails } from '@/features/customer/pages/CustomerDetails'

export const CustomerDetailsActivations = () => {
  const { customer, customerSettings, customerDetailsCommonBreadcrumbs, setPageConfig } = useCustomerDetails()
  const { t } = useTranslation()

  useEffect(() => {
    setPageConfig({
      title: t('customer_details.tabs.activations'),
      pageTag: 'customer-details-activations',
      breadcrumbs: customerDetailsCommonBreadcrumbs,
      activeTab: CustomerDetailsRouteInformation.ACTIVATIONS.navigationPath,
    })
  }, [])

  return (
    <CustomerActivationsView
      activationDetailsPath={CustomerDetailsRouteInformation.ACTIVATION_DETAILS.navigationPath.replace(
        ':customerUuid',
        customer.uuid as string,
      )}
      customer={customer}
      timeZone={customerSettings.localization.timeZone}
    />
  )
}
